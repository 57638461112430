.btn-primary-outline {
  border: 1px solid #47127d !important;
  background-color: transparent !important;
  color: #47127d !important;
  font-size: 16px !important;
  padding: 5px 33px !important;
}

.primary-btn-purple:hover {
  border: 1px solid #47127d !important;
  color: #47127d !important;
  background-color: #fff !important;
}

.primary-btn-purple {
  background-color: #47127d !important;
  border-radius: 4px !important;
  color: #fff !important;
  padding: 4px 33px !important;
  border: 1px solid #47127d !important;
}
.About-page .about-pf-btn{
  display: none !important;
}
.About-page .premfichrom{
  display: none !important;
}
.dashboard-btn {
  width: 150px;
  /* height: 35px; */
  /* border-bottom: 6px; */
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.w-59 {
  width: 59%;
}

.w-40 {
  width: 40%;
}

.font-weight-600 {
  font-weight: 600;
}

.mid-container-semsee {
  max-width: 85%;
  margin: 0 auto;
}

.text-purple {
  color: #47127d !important;
}

.text-end {
  text-align: end !important;
}

.btn-back-transperent {
  height: 64px;
  width: 64px;
  position: absolute;
  border-bottom-right-radius: 100%;
  border-top-left-radius: 0px;
  left: 0px;
  top: 64px;
  z-index: 2;
  background-color: #eee6f6;
  opacity: 0.8;
}

.btn-back-transperent.btn:focus {
  box-shadow: none;
}

.btn-primary-trans {
  padding: 3px;
}

.img-secondary {
  /* filter: brightness(0) saturate(100%) invert(7%) sepia(68%) saturate(5262%) hue-rotate(268deg) brightness(112%) contrast(100%); */
  height: 17px;
  padding-bottom: 2px;
}

.text-24 {
  font-size: 24px !important;
}

.primary-btn-transperent {
  /* background-color: #EEE6F6 !important; */
  font-size: 16px !important;
  /* color: #47127D !important; */
  border-radius: 8px !important;
  font-weight: 400 !important;
}

.primary-btn-transperent:hover {
  border: 1px solid #47127d;
  color: #47127d;
  background-color: transparent !important;
}

.primary-btn-transperent:focus {
  box-shadow: none;
}

.svg-primary {
  height: 18px;
  /* filter: brightness(0) saturate(100%) invert(17%) sepia(47%) saturate(3247%) hue-rotate(255deg) brightness(83%) contrast(114%); */
}

.svg-white {
  height: 18px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(25%)
    hue-rotate(70deg) brightness(108%) contrast(108%);
}

/***********Semsee************/
.semsee-top-margin {
  margin-top: 100px;
}

.card-semsee {
  margin-top: 30px;
}

.main-header {
  height: 64px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 5%);
  position: fixed;
  z-index: 2;
}

.user-image {
  height: 32px;
  width: 32px;
  border-radius: 8px;
}

.card-semsee {
  border: none !important;
  box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03),
    0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222),
    0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
  border-radius: 8px !important;
}

.v3-logo {
  height: 48px;
  width: 48px;
  background-color: #eee6f6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v3-logo img {
  height: 29px;
}

.semsee-title {
  padding-left: 15px;
}

/* .semsee-new-tabs .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #fff;
    background-color: #47127D;
    width: 177px;
    border-radius: 8px;
    text-align: center;
    border-bottom: none;
} */
.mymarkettab .p-tabview .p-tabview-nav {
  border: none !important;
  padding: 1rem;
}

.mymarkettab .p-tabview-nav-link {
  color: #fff;
  background-color: #47127d;
  width: 177px !important;
  border-radius: 8px !important;
  text-align: center;
  border-bottom: none;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 18px !important;
}

.mymarkettab .p-tabview-nav-link:active {
  border-bottom: 3px solid #47127d !important;
}

/* .mymarkettab .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover{
    border: none !important;
} */
.mymarkettab .p-tabview-nav-link:hover {
  background-color: #c5b0db !important;
  color: #47127d !important;
  border-radius: 8px !important;
}

.mymarkettab .p-unselectable-text a {
  border-bottom: 3px solid #c5b0db !important;
  border-radius: 0px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mymarkettab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-radius: 8px !important;
}

.mymarkettab
  li.p-unselectable-text.p-tabview-selected.p-highlight
  .p-tabview-nav-link {
  /* border-bottom: none !important;   */
  border-bottom: 3px solid #47127d !important;
}

.semsee-v3-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wizard-steps {
  padding: 0px;
}

.wizard-line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto 15px;
  border-bottom: 1px dashed #c5b0db;
  background-color: rgba(0, 0, 0, 0.12);
}

.wizard-line-completed {
  border-bottom: 1px dashed #2fad87 !important;
}

.wizard-steps .steps .step .semsee-wizard-count {
  border-radius: 20px;
  background: #fff;
  border: 1px solid #47127d;
  color: #47127d;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

.wizard-steps .steps .step.active .semsee-wizard-count {
  background-color: #47127d !important;
  color: white !important;
  border: 1px solid #47127d !important;
}

.wizard-steps .tabs {
  width: 100%;
  height: 100%;
}

.wizard-steps .tabs .tab {
  display: none;
}

.wizard-steps .tabs .tab.active {
  display: block !important;
}

.semsee-wizard-btns #nextBtn {
  border-radius: 4px !important;
  padding: 7px 0px;
  width: 162px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: white;
  background: #2fad87;
}

.semsee-wizard-btns #prevBtn {
  border-radius: 4px !important;
  padding: 7px 0px;
  width: 162px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  /* color: white;
    background: #47127D; */
}

.step-title {
  font-size: 16px;
  color: #47127d;
}

.wizard-steps .steps .step.step-completed .semsee-wizard-count {
  background: #2fad87;
  border: 1px solid #2fad87;
  color: #fff;
}

.wizard-steps .steps .step.step-completed .step-title {
  color: #2fad87;
}

form label {
  color: #2a2a2a;
  margin-bottom: 4px;
  font-size: 12px;
}

.form-control::placeholder {
  font-size: 12px !important;
  color: #bcbcbc !important;
}

.pre-footer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.footer {
  height: 64px;
  /* background: linear-gradient(180deg, #443B5C 0%, #47127D 100%); */
  font-size: 16px;
  color: #fff;
}

.semsee-new-table.p-datatable .p-datatable-thead > tr > th {
  background: #eee6f6 !important;
  color: #47127d !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-right: 1px solid #c5b0db !important;
}

.semsee-new-table.p-datatable .p-datatable-thead > tr > th:last-child {
  border-right: none !important;
}

.semsee-new-table.p-datatable .p-datatable-tbody > tr > td {
  border-right: 1.5px solid #dddddd !important;
}

.semsee-new-table.p-datatable .p-datatable-tbody > tr > td:last-child {
  border-right: none !important;
}

.semsee-new-table a {
  text-decoration: none !important;
}

.p-column-filter-overlay
  .p-dropdown.p-component.p-inputwrapper.p-column-filter-operator-dropdown.p-inputwrapper-filled {
  display: none !important;
}

button.p-button.p-component.p-column-filter-add-button.p-button-text.p-button-sm {
  display: none !important;
}

/* .p-component, .p-dropdown{
   padding: 10px !important;
} */
.semsee-new-table.p-datatable .p-datatable-header {
  background: none !important;
  border: none !important;
  padding: 10px !important;
}

.search-bar-new {
  border: 1px solid #bcbcbc;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  z-index: 1;
}

.search-bar-new .datatable-search {
  width: 100%;
}

.search-bar-new .fa-search {
  position: absolute;
  top: 9px;
  left: 27px;
}

.search-bar-new input {
  height: 32px;
  text-indent: 25px;
  border: none;
  border-radius: 8px !important;
  padding: 10px 10px 10px 15px;
}

.btn-semsee-search.ref-button {
  padding: 5px !important;
}

.ref-button img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(37%) saturate(5591%)
    hue-rotate(261deg) brightness(90%) contrast(108%);
  height: 18px;
}

.btn-semsee-search {
  border: 1px solid #47127d !important;
  background-color: transparent !important;
  color: #47127d !important;
  font-size: 16px !important;
  padding: 0px 32px !important;
  height: 33px !important;
}

.search-bar-new input:focus {
  outline: none;
  box-shadow: none;
}

/***********About Gigmarket*********/

.meet-again-main {
  background: rgb(252, 253, 255);
  /* min-height: 700px; */
  width: 100%;
  background-image: url(../images/about-bg-first.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  position: relative;
}

.meet-again-main::after {
  content: "";
  background: linear-gradient(
    107deg,
    rgba(252, 253, 255, 1) 0%,
    rgba(238, 237, 255, 1) 100%
  );
  position: absolute;
  top: 0px;
  opacity: 0.5;
  min-height: 700px;
  width: 100%;
}

.meet-again-width {
  width: 95%;
  margin: 0 auto;
  min-height: 700px;
  display: flex;
  align-items: center;
}

.header-fixed {
  background-color: #fff;
  position: fixed !important;
  /* margin-top: 30px; */
  /* background-color: #fff; */
  padding: 15px 12px;
  z-index: 2;
}

.meet-again-width h2 {
  line-height: 48px;
  color: #47127d;
  /* margin-bottom: 30px; */
}

.bg-purple {
  background-color: #47127d;
}

.meet-again-middle {
  padding: 85px 0px;
}

.meet-again-middle .container {
  width: 95%;
  margin: 0 auto;
}

.meet-again-main-third {
  background: rgb(252, 253, 255);
  min-height: 700px;
  width: 100%;
  background-image: url(../images/about-bg-img.png);
  background-repeat: no-repeat;
  background-position: top left;
  position: relative;
}

.meet-again-main-third:after {
  content: "";
  background: linear-gradient(
    107deg,
    rgba(252, 253, 255, 1) 0%,
    rgba(238, 237, 255, 1) 100%
  );
  position: absolute;
  top: 0px;
  opacity: 0.5;
 min-height: 700px;
  width: 100%;
}
.meet-again-main-third1{
  position: relative;
  background: linear-gradient(
    107deg,
    rgba(252, 253, 255, 1) 0%,
    rgba(238, 237, 255, 1) 100%
  );
  
}

.meet-again-main-third1:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url(../images/about-bg-img.png);
  background-repeat: no-repeat;
  background-position: top left;
}
.welcom-img {
  content: "";
  background-image: url(../images/new-images/about-white-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 415px;
  position: relative;
  height: 415px;
}
.welcome-gigmarket-div {
  position: relative;
  height: 400px;
}

.gallery-img {
  height: 330px;
  top: 50px;
  position: relative;
  right: 30px;
}

.market-buyer-title {
  line-height: 37px;
  margin-bottom: 30px;
  color: #454545;
  font-size: 24px !important;
}
.faq-title{
  color: #454545 !important;
}
.market-buyer {
  padding: 80px 0px;
  max-width: 95%;
  margin: 0 auto;
}

.network-effects {
  padding: 80px 0px;
}

.network-effect-width {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 35px;
}
.gigmarket-apps {
  align-items: center;
  justify-content: center !important;
  cursor: pointer;
}

.img-fluid.app-icons {
  height: 53px;
}

.network-effects h2 {
  margin-bottom: 70px;
}

.gigmarket-footer {
  background: linear-gradient(107.15deg, #fcfdff 0%, #eeedff 100%);
  padding: 24px 0px;
  margin-bottom: 70px;
}

.img-fluid.video-imgabout {
  height: 264px;
  margin-top: 30px;
}
.color-about-third {
  color: #68686a;
}
.btn-primary-outline-about {
  border: 1px solid #47127d !important;
  background-color: transparent !important;
  color: #47127d !important;
  font-size: 14px !important;
  padding: 5px 33px !important;
  margin-left: 20px;
  margin-right: 5px;
}
.btn-primary-outline-about.premfichrom {
  padding:6px 10px !important;
 
}
.about-page-mobile {
  display: none;
}
.bg-purple-about-img {
  background-image: url(../images/bg-purpleAbout.svg);
  /* background-repeat: no-repeat; */
  width: 100%;
  display: block;
  background-size: 100%;
  background-position: center;
  /* min-height: 700px; */
}
.faq-main-div{
  padding-bottom: 20px;
  color: #454545 !important;

}

@media (min-width: 1401px) {
  .pf-about-main .about-third-video {
    width: 500px !important;
    height: 368px !important;
  }
  .history-img{
    height: 500px;
  }
  /* .btn-yellow-about.about-pf-btn{
    height: 40px;
    } */
}
@media (min-width: 1023px) and (max-width: 1400px){
  .pf-about-main .about-third-video {
    width: 400px !important;
    height: 368px !important;
  }
  .faq-main-div{
    margin-bottom: 60px !important;
  }
  .pf-about-main .welcom-img {
    height: 800px;
  }
  .meet-again-main2:after {
    min-height: 820px;
  }
  .history-img{
    height: 500px;
  }
  .btn-yellow-about.about-pf-btn{
    height: 40px;
    }
    .premfi .btn-primary-outline-about {
      font-size: 13px !important;
    }
    .about-primary-btn-purple.mobpremfi {
      font-size: 13px !important;
    }
}


 @media (min-width: 373px) and (max-width: 426x) {
  .pf-about-main.logo-insgig {
    height: 32px !important;
}

.pf-about-main .meet-again-main:after {
  min-height: 900px !important;
}
.pf-about-main .meet-again-main:after {
  min-height: 939px !important;
}
.pf-about-main.logo-insgig-text {
  height: 20px !important;
}
.btn-yellow-about.about-pf-btn {
  width: 76px !important;
  font-size: 10px !important;
}

.about-pf-chrome-btn {
  padding: 5px !important;
}
.pf-about-main .about-third-video {
  width: 100% !important;
  height: 300px !important;
}
.about-primary-btn-purple .mobpremfi {
  padding: 6px 79px !important;
}
}


 @media (min-width: 320px) and (max-width: 767px) {
  .signup-btn-pf{
    font-size: 10px !important;
    padding: 5px !important;
    margin-right: 5px !important;
  }
  .meet-again-main {
    min-height: 800px;
  }
  .pf-about-main .meet-again-main {
    min-height: 834px;
}
  .img-fluid.app-icons {
    height: auto;
  }

    .img-fluid.app-icons.premficon{
      height: 29px;
  }
  /* .img-fluid.app-icons.premficon {
    margin-right: 35px;
} */
  .pf-about-main .meet-again-main:after {
    min-height: 835px !important
  }
  .meet-again-main:after {
    min-height: 875px;
  }
  .about-mob-btn {
    position: relative;
    text-align: center;
    margin-top: 15px !important;
    display: block !important;
  }
  .about-primary-btn-purple {
    margin-bottom: 5px;
  }
  .mob-req-btn {
    padding: 4px 42px !important;

  }
  .meet-again .poweredby-mb-div {
    bottom: 8px !important;
  }
  .btn-yellow-about {
    margin-right: 0px !important;
  }
  .btn-primary-outline-about {
    margin-left: 0px !important;
    margin-right: 0px;
  }
  .mob-about-img {
    margin-top: 20px;
    text-align: left !important;
  }
  .about-page-desktop {
    display: none;
  }
  .about-page-mobile {
    display: block;
  }
  .meet-again h2 {
    font-size: 24px;
    line-height: 150%;
  }
  .market-buyer-title br {
    display: none !important;
  }
  .create-gig-bottom-btn {
    text-align: left !important;
    margin-left: 3% !important;
    margin-top: 10px;
  }
  .meet-again-main-third {
    background: rgb(252, 253, 255);
    min-height: 545px;
    width: 100%;
    background-image: url(../images/new-images/mob-about-img.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    position: relative;
  }

  .meet-again-main-third:after {
    content: "";
    background: linear-gradient(
      107deg,
      rgba(252, 253, 255, 1) 0%,
      rgba(238, 237, 255, 1) 100%
    );
    position: absolute;
    top: 0px;
    opacity: 0.5;
    min-height: 545px;
    width: 100%;
  }
 
  .meet-again-main-third1:before {
    background-image: url(../images/new-images/mob-about-img.png);
    background-repeat: no-repeat;
    background-position: bottom right;
  }
  .gigmarket-apps {
    position: relative;
  }
  .bg-purple-about-img {
    background-size: 560%;
    background-position: center;
    /* min-height: 980px; */
  }

  .welcom-img {
    background-image: none;
  }
  .welcome-gigmarket-div {
    position: relative;
    height: 800px;
  }

  .gallery-img {
    height: 245px;
    top: 72px;
    position: relative;
    right: 20px;
  }
  .welcom-img-gig {
    content: "";
    background-image: url(../images/new-images/about-white-bg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 315px;
    position: relative;
    height: 400px;
  }
  .meet-again-width {
    min-height: 877px !important;
  }

  .meet-again-width ::after {
    min-height: 877px !important;
  }
  .pf-about-main .meet-again-width, .pf-about-main .meet-again-width ::after{
    min-height: 730px !important;
}
.pf-about-main .welcom-img {
  height: 224px;;
}
.about-pf-chrome-btn {
  padding: 5px !important;
}
.btn-yellow-about.about-pf-btn {
  font-size: 10px !important;
}
/* .pf-about-main .welcom-img {
  height: 1147px;
} */
.first-div-pf-about{
  margin-top: 113px;

}
.history-img{
  height: 300px;
}
.mob-req-btn.mob-view{
padding:6px !important;
}
.mob-req-btn.prem-quote{
  padding: 8px 0px !important;
  font-size: 4px !important;
}
.about-primary-btn-purple.mobpremfi {
  padding: 6px 84px !important;
}
}
@media (min-width: 767px) and (max-width: 767px) {
  .btn-yellow-about.about-pf-btn{
    height: 40px;
    }
    .signup-btn-pf{
      font-size: 14px !important;
      padding: 5px !important;
      height: 40px;
    }
    .btn-yellow-about.about-pf-btn {
      font-size: 14px !important;
  }
  .pf-about-main.meet-again-main:after {
    min-height: 801px !important;
}
.pf-about-main.meet-again-width{
  padding-top: 0px !important;
}
}
@media (min-width: 374px) and (max-width:400px) {
  .btn-yellow-about.about-pf-btn {
    font-size: 10px !important;
  }
  .about-pf-btn img {
    height: 24px !important;
}
.pf-about-main .meet-again-main:after {
  min-height: 833px !important;
}
}
@media (min-width: 320px) and (max-width:320px) {
  .pf-about-main .logo-insgig {
    height: 30px;
}
.pf-about-main .meet-again-main:after {
  min-height:829px !important;
}
.pf-about-main .logo-insgig-text {
  height: 16px !important;
}
.about-pf-chrome-btn{
  padding: 5px !important;
}
.pf-about-main .header-fixed {
  padding: 15px 6px;
}
   .btn-yellow-about.about-pf-btn{
   /* width: 53px !important; */
   font-size: 10px !important;
  }
  .btn-yellow-about {
  font-size: 9px !important;
  }
  .about-pf-btn img {
   height: 21px !important;
 }
 
 .meet-again-main2:after {
  min-height: 980px;
}

}
 @media (min-width: 768px) and (max-width: 992px){
 .about-mob-btn{
  text-align: center !important;
  display: block !important;
 }
  .about-primary-btn-purple,  .mob-req-btn{
    width: 250px !important;
  }
 .mob-req-btn{
  margin-left: 0px !important;
  margin-top: 10px;
 }
 .btn-primary-outline-about.prem-quote {
 margin-top: 0px !important;
 font-size: 15px !important;

}
.btn-primary-outline-about.premfichrom{
  font-size: 13px !important;
  padding: 5px 10px !important;
}
  .pf-about-main .about-third-video {
    width: 320px !important;
    height: 300px !important;
  }
  .faq-main-div{
    margin-bottom: 60px;
  }
  .pf-about-main .welcom-img {
    height: 800px;
  }
  .meet-again-main2:after {
    min-height: 823px;
  }
  .history-img{
    height: 315px;
  }
  .btn-yellow-about.about-pf-btn{
    font-size: 13px !important;
    margin-right: 0 !important;
    }
}

/*********** Agent onboarding************/
.successmsg-a-font,
.successmsg-a-font a {
  font-size: 18px !important;
}

.successmsg-a-font p {
  font-size: 16px !important;
}

.register-msg-font a {
  color: #007bff !important;
  text-decoration: unset !important;
  font-size: 16px !important;
}

.successmsg-a-font .copyclipboard {
  display: none;
  position: absolute;
  right: 0px;
  /* bottom: 78px; */
  background: #333;
  color: #dbcfcf;
  padding: 5px;
  font-size: 12px;
}

.copyclipboard-div:hover .copyclipboard {
  display: block;
}

.vertafore-logos .vertafore-logo {
  height: 85px !important;
}

.backbtn-withoutsidebar {
  top: 63px !important;
}

@media only screen and (max-width: 420px) {
  .semsee-wizard-btns button {
    width: 100%;
  }
}

.mymarkettab .semsee-new-table {
  padding: 0px !important;
}

.semsee-new-table .p-column-filter-menu-button {
  width: 24px;
  height: 24px;
}

.semsee-new-table .p-column-filter-menu-button:hover {
  background: #c5b0db;
  border-radius: 8px;
}

.semsee-new-table .p-column-filter-menu-button .pi {
  font-size: 12px;
}

.semsee-new-table .p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background-color: #47127d !important;
  border-radius: 8px;
  color: #fff !important;
}

.semsee-new-table .p-button {
  background-color: #47127d !important;
}

/***********UPContent************/
.badge-trans-primary {
  background-color: #eee6f6;
  color: #47127d;
  font-size: 12px;
  padding: 5px;
  font-weight: 400;
}

.badge-trans-yellow {
  background-color: #fffacc;
  color: #e5ce00;
  font-size: 12px;
  padding: 5px;
  font-weight: 400;
}

.badge-trans-danger {
  background-color: #ff57a866;
  color: #f72a51;
  font-size: 12px;
  padding: 5px;
  font-weight: 400;
}

.badge-trans-info {
  background-color: #2980cf59;
  color: #2980cf;
  font-size: 12px;
  padding: 5px;
  font-weight: 400;
}

a.clear-all:hover {
  color: #47127d !important;
  text-decoration: none;
}

.upcontent-content {
  display: flex;
  align-items: center;
}

.custom-switch .custom-control-label.success::before {
  width: 40px;
  height: 22px;
  border-radius: 25px;
}

*/ .custom-switch .custom-control-label.success::after {
  height: 14px;
  width: 14px;
  top: 8px;
  left: -32px;
}

*/ .custom-control-input:checked ~ .custom-control-label.success::before {
  border-color: #419333;
  background-color: #419333;
  width: 40px;
  height: 22px;
  border-radius: 25px;
}

.custom-control-input:checked ~ .custom-control-label.success::after {
  height: 14px;
  width: 14px;
  top: 8px;
  left: -26px;
}

.custom-switch1.upcontent-success {
  border: 1px solid #419333;
}

.custom-switch1.upcontent {
  height: 46px;
  box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03),
    0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222),
    0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 28%;
  background-color: #fff;
}

.custom-switch1.upcontent-danger {
  border: 1px solid #f72a51;
}

.custom-switch1 .custom-control-label.danger::before {
  width: 40px;
  height: 22px;
  border-radius: 25px;
}

.custom-switch1 .custom-control-label.danger::after {
  height: 14px;
  width: 14px;
  top: 8px;
  left: -32px;
}

.custom-control-input:checked ~ .custom-control-label.danger::before {
  border-color: #f72a51;
  background-color: #f72a51;
  width: 40px;
  height: 22px;
  border-radius: 25px;
}

.custom-control-input:checked ~ .custom-control-label.danger::after {
  height: 14px;
  width: 14px;
  top: 8px;
  left: -26px;
}

.agency-deails-content {
  border: 1px solid #e8e7e7;
  height: 168px;
  width: 100%;
  padding: 15px;
}

.upcontent-third {
  display: flex;
  align-items: center;
}

.clear-all {
  color: #47127d !important;
}

.leads-table-new.leads-table.table thead th {
  background-color: #eee6f6 !important;
  color: #47127d !important;
}

.badge-success.label-success {
  background-color: #ebf4f8;
  color: #2fad87;
  font-size: 12px;
  padding: 4px 4px;
}

.badge-success.label-success .badge-success-ico {
  filter: brightness(0) saturate(100%) invert(53%) sepia(73%) saturate(416%)
    hue-rotate(111deg) brightness(94%) contrast(81%);
  height: 16px;
}

.badge-warning.label-warning {
  background-color: #fffacc;
  color: #ccb700;
  font-size: 12px;
  padding: 4px 4px;
}

.badge-warning.label-warning .badge-white-ico {
  filter: brightness(0) saturate(100%) invert(76%) sepia(53%) saturate(2459%)
    hue-rotate(11deg) brightness(93%) contrast(100%);
  height: 16px;
}

.badge-danger.label-danger {
  background-color: #f72a511a;
  color: #f72a51;
  font-size: 12px;
  padding: 4px 4px;
}

.badge-danger.label-danger .badge-white-ico {
  filter: brightness(0) saturate(100%) invert(26%) sepia(46%) saturate(5627%)
    hue-rotate(335deg) brightness(100%) contrast(94%);
  height: 16px;
}

.btn-tans-primary.btn-send-drop:hover {
  background-color: #eee6f7;
  padding: 0px 2px;
  border-radius: 8px;
}

.btn-tans-primary.btn-send-drop {
  height: 32px;
  width: 32px;
  padding: 0px 2px;
}

.dropdown-menu-right.dropdown-linkgen {
  width: 170px;
  /* border: 2px solid #47127D; */
  border-radius: 8px;
  padding: 0px;
}

.dropdown-menu-right.dropdown-linkgen .dropdown-item {
  font-size: 12px;
  height: 40px;
  display: flex;
  align-items: center;
}

.dropdown-menu-right.dropdown-linkgen .dropdown-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-menu-right.dropdown-linkgen .dropdown-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dropdown-menu-right.dropdown-linkgen .dropdown-item:hover {
  background-color: #47127d;
  color: #fff !important;
}

.dropdown-menu-right.dropdown-linkgen .dropdown-item:hover img {
  filter: brightness(0) saturate(100%) invert(99%) sepia(6%) saturate(1632%)
    hue-rotate(277deg) brightness(117%) contrast(100%);
}

.dropdown-menu-right.dropdown-linkgen .dropdown-item:hover .svg-primary {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(49deg) brightness(107%) contrast(102%);
}

.leads-table-new .talage-table.p-datatable tr > th {
  border-right: 1px solid #c5b0db;
  /* border-width: 1px !important; */
}

.leads-table-new .talage-table.p-datatable tr > th:last-child {
  border-width: 0px !important;
}

.has-search-control .form-control-feedback-new {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  line-height: 1.375rem;
  text-align: center;
  pointer-events: none;
  color: #47127d;
  left: 6px;
  top: 7px;
}

.has-search-control .form-control {
  padding-left: 2.175rem !important;
  height: 35px;
  border: 1px solid #bcbcbc;
}

.btn-primary-outline.ref-linkgen {
  padding: 4px 7px !important;
}

.upload-lossrun {
  padding: 5px 60px !important;
}

.datatable-leads .p-datatable .p-datatable-tbody > tr > td.columns-border {
  border-right: 1px solid #dddddd !important;
}

.width-for-search {
  width: 70%;
}

.width-for-btns {
  width: 29%;
}

input[type="search" i]:enabled:read-write:-webkit-any(
    :focus,
    :hover
  )::-webkit-search-cancel-button {
  margin-right: 12px;
  color: #47127d;
  background: #47127d;
  font-size: 18px;
}

.card-body.card-shadow {
  box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03),
    0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222),
    0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
}

.card-radius {
  border-radius: 8px !important;
}

.link-popup.p-dialog .p-dialog-header .p-dialog-title {
  text-align: center;
}

.link-popup.p-dialog {
  position: relative;
  width: 50%;
}

.link-popup.p-dialog .p-dialog-header-icons {
  position: absolute;
  left: 93%;
  top: 18px;
  width: auto;
}

.btn-send-drop:hover .svg-primary {
  filter: brightness(0) saturate(100%) invert(17%) sepia(20%) saturate(6269%)
    hue-rotate(253deg) brightness(93%) contrast(117%);
}

.upcontent-upload-btn.prospect-width {
  width: 113px;
  min-width: 113px;
  margin-right: 10px;
}

@media (max-width: 2000px) and (min-width: 1800px) {
  .width-for-search {
    width: 76%;
  }

  .width-for-btns {
    width: 23%;
  }
}

/***********Validate*********/
.validate-main {
  background: rgb(252, 253, 255);
  height: 427px;
  width: 100%;
  background-image: url(../images/header-design-2.png),
    url(../images/header-design-1.png);
  background-repeat: no-repeat;
  background-position: top right, bottom left;
  position: relative;
}

.validate-main:after {
  content: "";
  background: linear-gradient(
    107deg,
    rgba(252, 253, 255, 1) 0%,
    rgba(238, 237, 255, 1) 100%
  );
  position: absolute;
  top: 0px;
  opacity: 0.5;
  height: 427px;
  width: 100%;
}

.z-index-1 {
  z-index: 1;
}

.logo-insgig {
  height: 34px;
}

.logo-insgig-text {
  height: 20px;
}

.igig-logo {
  cursor: pointer;
  /* margin-top: 30px;
        margin-left: 30px; */
}

.validate-title {
  color: #454545;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 24px;
}

.validate-text {
  color: #68686a;
  font-size: 16px;
  line-height: 24px;
}

.validate-width {
  width: 910px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 391px;
}

.btn-purple-gradient {
  background: linear-gradient(91.59deg, #47127d 0%, #6d2daf 100%);
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 20px !important;
  padding: 11px 40px !important;
}

.validate-logos {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.validate-logos .payroll-logo {
  color: #bcbcbc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.validate-logos .payroll-logo img {
  height: 65px;
  margin-bottom: 10px;
}

@media (min-width: 320px) and (max-width: 767px) {
 
  .validate-main {
    background-image: url(../images/header-design-1.png);
    background-position: bottom left;
    background-repeat: no-repeat;
  }
  .meet-again-main2:after {
    min-height: 1167px;
  }
  .validate-width {
    width: 100%;
    height: 521px;
  }

  .validate-title {
    font-size: 26px;
    line-height: 31px;
  }

  .validate-main {
    height: 564px;
  }

  .validate-main:after {
    height: 564px;
  }

  .validate-logos .payroll-logo {
    display: block;
  }

  .footer {
    height: 100px;
  }

  .igig-logo {
    margin-left: 0px;
  }
}

@media (min-width: 1800px) and (max-width: 2000px) {
  .validate-main {
    height: 644px;
  }

  .validate-width {
    height: 644px;
  }

  .validate-main:after {
    height: 644px;
  }
}

.merge-table.p-datatable .p-datatable-thead > tr > th {
  font-size: 12px !important;
  padding: 9px;
}

.merge-table.p-datatable .p-datatable-tbody > tr > td {
  font-size: 12px !important;
  padding: 9px;
}

.btn.btn-yellow.request-btn {
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
}

.btn.btn-yellow.request-btn:hover {
  color: #2a2a2a !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .mob-header {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 2 !important;
    margin-top: 0px;
  }

  /* .meet-again-main {
    background-image: url(../images/new-images/about-gigmarket-img.svg);
  } */

  .text-right-introduction {
    text-align: left;
    margin-top: 18px;
  }

  .mob-font {
    font-size: 14px !important;
    font-weight: 400;
    font-size: 14px !important;
  }
  .mob-font.premfi{
    font-size: 16px !important;
  }
  .gigmarket-text {
    margin-top: 80px;
  }

  .network-effects {
    padding: 40px 0px;
  }

  .network-effect-width {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 0px !important;
  }

  .meet-again-middle {
    padding: 41px 0px;
  }

  .img-fluid.video-imgabout {
    margin-top: 0px;
  }

  .market-buyer {
    padding: 44px 0px;
  }
  /* 
    .market-buyer-title {
        font-size: 16px;
        line-height: 34px;
    } */

  .network-effects h2 {
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 35px;
  }
  .meet-again p {
    text-align: justify !important;
    font-size: 15px !important;
  }
  .about-bootom-p {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .btn-yellow-about {
    background-color: #ffe500 !important;
    border-radius: 4px !important;
    color: #2a2a2a !important;
    font-size: 10px !important;
    padding: 5px 5px !important;
  }
  
  .about-video-size {
    height: 211px !important;
    width: 100% !important;
  }
  .about-third-video {
    margin: 0px auto !important;
    width: 100% !important;
    height: 168px !important;
  }
  .btn-yellow-about-bottom {
    background-color: #ffe500;
    border-radius: 4px !important;
    color: #2a2a2a !important;
    font-size: 10px !important;
    margin-left: 0% !important;
    padding: 6px 10px !important;
  }
}

.btn-yellow-about {
  background-color: #ffe500 !important;
  border-radius: 4px !important;
  color: #2a2a2a !important;
  font-size: 14px !important;
  padding: 5px 10px;
  margin-right: 35px;
}
.btn-yellow-about-bottom {
  background-color: #ffe500;
  border-radius: 4px !important;
  color: #2a2a2a !important;
  font-size: 14px;
  padding: 6px 14px;
  margin-right: 38px;
}
.about-video {
  align-items: center !important;
  display: grid;
  justify-content: center;
  margin-top: 22px;
}
.about-bootom-p {
  padding-left: 25%;
}
.create-gig-bottom-btn {
  margin-bottom: 10px;
  text-align: right;
}
/* .pf-about-main .about-pf-chrome-btn{
display: none;
}
.about-page-main .about-pf-chrome-btn{
  display: revert;
  } */
  .about-page-main .mob-req-btn.prem-quote {
    display: none;
  }
  .premfi .about-mob-btn{
    display: flex;
  }
