@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600&display=swap');


body {
    font-family: 'Readex Pro', sans-serif !important;
}

.text-10 {
    font-size: 10px;
}

.text-12 {
    font-size: 12px;
}

.text-14 {
    font-size: 14px !important;
}

.text-16 {
    font-size: 16px;
}

.text-18 {
    font-size: 18px;
}
.text-19{
    font-size: 19px;
}
.text-20 {
    font-size: 20px;
}

.text-22 {
    font-size: 22px;
}

.text-24 {
    font-size: 24px;
}

.text-32 {
    font-size: 32px !important;
}

.w-30 {
    width: 30% !important;
}

.lh-160 {
    line-height: 160%;
}

@font-face {
    font-family: 'Readex Pro', sans-serif;
    src: url('../fonts/ReadexPro-Regular.woff2');
}

.font-weight-medium {
    font-weight: 600 !important;
}

.text-secondary-1 {
    color: #6f2d80 !important;
}

.btn-primary {
    background-color: #001F4D !important;
    border-color: #001F4D !important;
    color: #fff !important;
    border-radius: 5px !important;
}

.bg-1 {
    background: rgb(0, 31, 77);
    background-image:
        linear-gradient(180deg, rgba(0, 31, 77, 1) 0%, rgba(95, 39, 110, 0.74) 65%), url('../images/bg-2.png');
    height: auto;
    background-repeat: no-repeat;
    color: aliceblue;
    background-position: bottom;
}

.bg2 {
    background: #171d27;
    background-image: url(), linear-gradient(#1c1b54, #aa31d1);
    height: 200px;
    background-repeat: no-repeat;
    color: aliceblue;
}

.bg-2 {
    background: rgb(0, 31, 77);
    background-image:
        linear-gradient(45deg, rgba(0, 31, 77, 1) 0%, rgb(95 39 110 / 65%) 65%), url('../images/bg-3.png');
    height: auto;
    background-repeat: no-repeat;
    color: aliceblue;
    background-position: top right;
}

.app-bg1 {
    position: relative;
    top: 0;
    right: 24px;
    width: calc(var(--desktop-base-size)*255);
}

.app-bg2 {
    position: absolute;
    right: -10px;
    width: calc(var(--desktop-base-size)*255);
    max-width: 265px;
}

.continer2 {
    margin: 0 auto;
    position: relative;
}

.leftbg {
    display: flex;
    width: 15%;
}

.midbg {
    display: flex;
    width: 70%;
    z-index: 1;
}

.rightbg {
    display: flex;
    width: 15%;
}

/* .menu1 ul li {
    display: inline;
    padding: 0 2px 0 2px;
    color: gray;
}

.menu1 ul {
    padding: 0;
    margin: 0;
} */

.mpad {
    padding: 5px 0 0 0;
}



:root {
    --color-one: #e6e6ff;
    --color-two: #1a1aff;
    --color-three: #b3b3ff;
}

.header-bar {
    position: fixed;
    top: 0;
    background-color: #ffffff;
    width: 100%;
    z-index: 10;
}

.header-bar ul {
    display: flex;
    margin-bottom: 0px;
    align-items: center;
}

.header-bar ul li {
    list-style: none;
}

.header-bar .header-row {
    align-items: center;
    height: 70px;
}

.user-img img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.header-row #sidebarCollapse i {
    color: #818182;
    font-size: 18px;
}

.header-bar .header-row .logo {}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: block;
    align-items: stretch;
}

/* #sidebar {
    min-width: 250px;
    max-width: 250px;
    color: #fff;
    height: auto;
} */

.toggle-sidebar {
    margin: 25px 0;
    cursor: pointer;
}

.toggle-sidebar span:nth-child(2) {
    margin: 4px 0;
}

.toggle-icon {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.toggle-sidebar span {
    width: 20px;
    height: 2px;
    background-color: #405679;
    display: block;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    position: relative;
}

/* 
#sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: left;
    font-size: 16px !important;
}

#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar .sidebar-header {
    padding: 20px;
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul li {
    padding-top: 10px;
}

#sidebar ul li a {
    padding: 12px;
    font-size: 16px;
    display: block;
}

#sidebar ul li a:hover {
    color: #fff;
    background: #6F2D80;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 31px;
    text-decoration: none;
} */

.bg1.active .components span {
    display: none;
}

.bg1.active .components li.active>a,
a[aria-expanded="true"] {
    margin-right: 0px !important;
}

/* #sidebar ul li a i {
    margin-right: 10px;
}

#sidebar ul li a {
    color: #fff;
}

/* #sidebar ul li.active>a {
    color: #fff;
    background: #6F2D80;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 31px;
} */

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    /* min-height: 100vh; */
    transition: all 0.3s;
    background: #FBFBFA;
    overflow-x: hidden;
}

.heading-1 h4 {
    padding-top: 30px;
    padding-bottom: 25px;
    width: 100%;
    transition: all 0.3s;
}



.eventcal {
    width: 100%;
}

.text-block {
    padding-left: 20px;
    /* background: linear-gradient(180deg, rgba(87, 87, 87, 0) 0%, rgba(87, 87, 87, 0.75) 100%);  */
    width: 100%;
    padding-left: 0px;
    padding-right: 20px;
    margin-top: 40px;
    /* height: 127px; */
    z-index: 999;
    font-family: 'Readex Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.blog-description p {
    margin-bottom: 0px;
}

.atricle-info {
    font-size: 12px;
    color: #000;
    font-family: 'Readex Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.article-title-text {
    padding-left: 20px;
    border-left: 4px solid #47127d;
}

.heading-1 h4 {
    padding-top: 30px;
    padding-bottom: 25px;
}

.cardhover {
    box-shadow: 0px 2px 4px #0000001a;
}

.dashboard .card-header {
    background-color: transparent;
    border-bottom: none;
}

.com-event-title {
    font-size: 24px;
    font-weight: 600;
}

.com-event-date {
    color: #BCBCBC;
    padding-top: 6px;
}

.hr-line {
    height: 2px;
    width: 100% !important;
    margin-top: 16px;
    margin-bottom: 16px;
}

.com-event-link {
    border: 1px dashed rgba(221, 221, 221, 1);
    padding: 8px;
    margin-top: 55px;
    font-size: 12px;
    border-radius: 4px;
}

.com-event-link a {
    color: rgba(71, 18, 125, 1);
}

.dashboard .table td:last-child {
    text-align: center;
}

.main-content {
    display: flex;
}

.footer {
    color: #fff;
    font-size: 12px;
    bottom: 0;
    z-index: 10;
    padding-top: 10px;
    padding-bottom: 10px;
}
.error-page-main .footer, .nowcerts-sso-page-main .footer{
    position: absolute;
    bottom: 0;
}
.error-page-main .poweredby-mb-div, .nowcerts-sso-page-main .poweredby-mb-div{
    position: unset !important;
}
.error-page-main .poweredby-logo, .nowcerts-sso-page-main .poweredby-logo{
    bottom: 10% !important;
}
.event-outline-btn {
    color: rgba(71, 18, 125, 1) !important;
    border-color: rgba(71, 18, 125, 1) !important;
}

.event-outline-btn:hover {
    background-color: rgba(71, 18, 125, 1) !important;
    color: #fff !important;
}

.event-wout-btn {
    background-color: rgba(71, 18, 125, 1) !important;
    border-color: rgba(71, 18, 125, 1) !important;
}

.event-wout-btn:hover {
    color: rgba(71, 18, 125, 1) !important;
    border-color: rgba(71, 18, 125, 1) !important;
}

.banner-show-hide {
    display: block;
}

.mb-view-link {
    display: none !important;
}

.atricle-info {
    padding-top: 2rem !important;
}

.activeeventblog {
    background-color: rgba(71, 18, 125, 1) !important;
    color: #fff !important;
}

.error-page-top {
    margin-top: 64px !important;
}

.row.error-page-top:after {
    /* top: 57px !important; */
}

.blog-img {
    /* height: 124px !important; */
    /* height: 164px !important; */
}

.community-small-blog .blog-img {
    /* height: auto!important; */
}

.notfoundcard {
    border: 1px solid #FFE500 !important;
    background: #FFFACC !important;
    color: #CCB700;
}

.iborder {
    border: 1px solid #CCB700 !important;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
}

.calhideondesktop {
    display: none;
}

/* 
.register-msg-font {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
} */

.register-msg-font a {
    display: block;
}

.btn-approve {
    width: 100px !important;
}

.hideCalPreBtn button.p-datepicker-prev.p-link {
    display: none;
}

.create-cal-desktop .p-datepicker table {
    font-size: 12px !important;
}

.create-cal-desktop .p-datepicker table td {
    padding: 0px !important;
}

.create-cal-desktop .p-datepicker table th {
    padding: 0px !important;
    text-align: center;
}

.eventcal .p-datepicker table th {
    text-align: center;
}

.highlight {
    color: red !important;
}

/****** scroll*******/

.event-scoll {
    height: 300px;
    overflow-y: scroll;

}

.history-scoll {
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.event-scoll::-webkit-scrollbar,
.history-scoll::-webkit-scrollbar {
    width: 4px;
}

.event-scoll::-webkit-scrollbar-track,
.history-scoll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 30px;
}

.event-scoll::-webkit-scrollbar-thumb,
.history-scoll::-webkit-scrollbar-thumb {
    background: rgba(71, 18, 125, 1);
    border-radius: 30px;

}

/****** scroll end*******/
.article-prev-next-btn {
    border: 1px solid rgba(71, 18, 125, 1) !important;
    background-color: transparent !important;
    color: rgba(71, 18, 125, 1) !important;
    padding: 5px 32px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    width: 169px !important;
}

.img-secondary {
    filter: brightness(0) saturate(100%) invert(7%) sepia(68%) saturate(5262%) hue-rotate(268deg) brightness(112%) contrast(100%);
    height: 17px;
    padding-bottom: 2px;
}

.btn-outline-primary.autofillbtn:hover .img-secondary.refresh-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(9%) saturate(7500%) hue-rotate(269deg) brightness(121%) contrast(108%);
}

.ref-btn-lossrun.btn-outline-primary {
    padding: .375rem .75rem !important;
}

.ref-btn-lossrun.btn-outline-primary .img-secondary {
    height: 20px;
}

.article-prev-next-btn:hover {
    background-color: rgba(71, 18, 125, 1) !important;
    border: 1px solid rgba(71, 18, 125, 1) !important;
    color: #fff !important;
}

.btn-arrow-color:hover .img-secondary {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(53deg) brightness(105%) contrast(101%);
}

.prev-next-btn-div {
    text-align: right;
}

.login-menu-dropdown li {
    padding: 5px;
}

.login-menu-dropdown a {
    color: rgba(71, 18, 125, 1) !important
}

.before-login-mb-menu {
    display: none;
}

.before-login-desktop-menu {
    display: inline-block;
}


.forgot-main-div .footer,
.setpassword-main-div .footer,
.company-main-div .footer {
    position: absolute !important;
}

.forgot-main-div .poweredby-mb-div,
.setpassword-main-div .poweredby-mb-div,
.company-main-div .poweredby-mb-div {
    position: absolute;
    bottom: 6%;
}

.poweredby-mb-div {
    position: relative;
}

.com-blogs,
.community-calendar,
.p-dialog,
.article-content-font {
    font-family: 'Readex Pro', sans-serif !important;
}

.bg-community-banner.bg-height {
    height: 370px;
}

.container-fluid.bg-community-banner.bg-height:after {
    height: 370px;
}

.main-head-title {
    z-index: 1;
}

.index-blog .blog-date {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
}

.z-index-1 {
    z-index: 1 !important;
}

.index-blog .blog-description {
    display: flex;
    align-items: center;
}

.index-blog .card {
    border-radius: 8px;
}

.com-blog-more.blog-index-btn {
    position: absolute;
    bottom: -1px !important;
    right: -1px !important;
}

.index-blog .card:hover {
    border: 1px solid #f4f3f3;
    transform: translateY(0px);
    cursor: pointer;
}

.index-blog-service,
.index-blog-service1 {
    background: rgba(248, 247, 247, 1);
    padding: 6px;
    border-radius: 4px;

}

.type-img {
    padding: 6px;
    border-radius: 4px;
}

.history-tile {
    background: rgba(248, 247, 247, 1);
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 12px;
}

.history-text {
    color: #47127D;
}

.blog-bgimg {
    width: 96px;
    height: 96px;
    border-radius: 8px;
}

.carousel-demo {
    z-index: 1;
}

.liveStatusCard {
    border: 1px solid #2FAA85 !important;
}

.status-tag {
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;

}

.status-tag-live {
    background: #EBF4F8;
    color: #2FAA85;
}

.status-tag-pending {
    background: #F7F2E8;
    color: #DEA73F;
}

.mb-view-logo {
    padding-left: 0px !important;
}

.carousel-demo .p-carousel-content {
    z-index: 1 !important;
}

.carousel-demo .product-item {
    margin-right: 20px;
}

.carousel-demo ul.p-carousel-indicators.p-reset {
    display: none;
}

.content-width {
    width: 94% !important;
}

.mt-64 {
    /* margin-top: 64px; */
}

.api-details-btn {
    width: 237px;
    padding: 0px 32px;
    font-size: 14px;
}

.workflow-btn {
    /* width: 237px; */
    padding: 0px 32px;
    height: 32px;
    font-size: 14px;
}

.service-detail-btns {
    text-align: end;
}

.badge-transperent-primary {
    background-color: #E7D5EF;
    color: #862CB1;
    font-size: 12px;
    font-weight: 400;

}

.service-details .card {
    border: none;
}

.service-details .card:hover {
    border: none;
    transform: translate(0px);
}

.details-bg.gig-services {
    background-image: url(../images/new-images/service-bg.png);
    background-position: top right;
    background-repeat: no-repeat;
}

.create-gig-btn {
    display: none !important;

}

.create-Own-GigMarket-btn {
    margin-top: 20px;
}

.service-details .nav-pills .nav-link {
    border: 2px solid rgba(246, 245, 245, 1) !important;
}

.details-tabss .p-tabview-nav-content {
    margin: 15px;
}

.detail-service-type {
    font-size: 16px;
}

.detailtopbar {
    min-height: 262px;
}

.tag-para-height {
    min-height: 95px;
}

.api-details-btn {
    width: 237px !important;
    padding: 0px 32px !important;
    font-size: 16px !important;
    height: 32px !important;
    margin-right: 10px !important;
}


.workflow-btn {
    /* width: 237px !important; */
    padding: 0px 32px !important;
    height: 32px !important;
    font-size: 16px !important;
    margin-right: 10px !important;
    border: 1px solid #001f4d !important;
}

.workflow-btn:hover {
    background-color: transparent !important;
    border: 1px solid #001f4d !important;
    color: #001f4d !important;
}

.detail-content {
    width: -webkit-fill-available;
    width: -moz-available;
}

.ss .slick-prev,
.ss .slick-next {
    top: 40%;
}

.gig-services {
    position: relative;
}

.index-blog-service,
.type-img {
    position: absolute;
    bottom: 10%;
    width: 90%;
}

.status-tag-rtg {
    width: 27px;
}

.mediacard {
    min-height: 170px;
}

.ss .slick-dots {
    display: none !important;
}

/* .modal{
       z-index: 1045 !important;
   }
   .modal-backdrop.show {
    opacity: 0.2 !important;
} */
.modal-backdrop.fade.show {
    display: none !important;
}

.gig-services {
    font-family: 'Readex Pro', sans-serif !important;
}

.gig-services .badge {
    font-weight: 400 !important;
}

.lob-modal {
    position: absolute !important;
    /* top: 120px !important; */
    z-index: 999 !important;
    height: auto !important;
}

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
    background: #862CB1;
    border-radius: 4px;
    width: 32px;
    z-index: 999;
}

.slick-next:before {
    content: "\f178" !important;
    font-family: FontAwesome !important;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: inherit !important;
    font-size: 11px !important;
    color: #fff !important;
}

.slick-prev:before {
    content: "\f177" !important;
    font-family: FontAwesome !important;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: inherit !important;
    font-size: 11px !important;
    color: #fff !important;

}

.p-image-preview-container:hover>.p-image-preview-indicator {
    border-radius: 4px;
}

.p-items-hidden .p-carousel-item {
    visibility: visible !important;
}

/*********Workflow*********/
.btn-outline-white {
    border: 2px solid #fff !important;
    color: #fff !important;
    background-color: transparent !important;
    font-size: 16px !important;
    padding: 11px 16px !important;
    border-radius: 8px !important;
}

.btn-outline-white:hover {
    color: #212529 !important;
    text-decoration: none;
}

.search-bar.workflows input {
    height: 48px;
}

.search-bar.workflows .fa-search {
    top: 16px;
}

.search-bar.workflows button {
    top: 7px;
}

.search-bar.workflows .arrow-search {
    top: 12px;
}

.community-position.workflow-position {
    top: 28px;
}

.table-header {
    background-color: rgba(238, 230, 246, 1);
    font-weight: bold;
    font-size: 25px;
    padding: 10px;
    text-align: justify;
    font-size: 12px;
    color: rgba(71, 18, 125, 1);
    font-weight: 600;
    border-radius: 8px;
}

.table-data {
    text-align: start;
    padding: 10px;
    background-color: rgba(246, 246, 246, 1);
    font-size: 12px;
    font-weight: 400;
    border-radius: 8px 8px 8px 0px;
    margin-top: 10px;
    color: #2A2A2A !important;
}

.table-data-col {
    border-right: 1.5px solid #C5B0DB;

}

.table-data-heading {
    font-weight: bold;
}

.table-data-col-header {
    border-right: 2px solid #C5B0DB;
}

.implementation-table {
    border: 1.5px solid #C5B0DB;
    border-radius: 8px;
    font-size: 12px;
    padding: 5px;
    margin-bottom: 8px;
}

.implementation-table-header {
    color: rgba(71, 18, 125, 1);
    font-weight: bolder !important;
}

.implementation-table-data {
    color: #212529;

}

.workflow {
    background: #FFFFFF;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
    border-radius: 8px;
    padding: 10px;
}

.workflow:hover {
    /* border: none; */
    /* -webkit-transform: translateY(-10px);
    transform: translateY(0px); */
    /* cursor:auto; */
}

.btn-success {
    background-color: rgba(47, 173, 135, 1);
    color: #fff;
    padding: 3px 4px;

}

.btn-success.accordion-btn {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 12px;
    position: relative;
}

.accordion-btn i {
    font-size: 10px;
    margin-left: 45px;

}

.btn-pink {
    background: #F72A51 !important;
    border-color: #F72A51 !important;
    font-size: 12px;
}

.workflows-table-inner {
    border-collapse: separate;
}

.workflow-banner {
    height: unset !important;
}

.new-request a {
    color: #46127c !important;
}

.workflow-filter {
    border: none !important;
}

.arrow-search img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(45%) saturate(5769%) hue-rotate(264deg) brightness(95%) contrast(104%) !important;
}

/* #req-after-login{
        margin-top: 50px;
    }   */
#req-before-login {
    margin-top: 400px !important;
}

.term-page .middle-header-main-div,
.privacy-page .middle-header-main-div{
    display: table-cell;
    vertical-align: middle;
}

.req-header .minput {
    width: 100% !important;
}

.dropdown-span span.p-dropdown-label.p-inputtext.p-placeholder {
    font-size: 12px;
    color: rgba(188, 188, 188, 1);
}

.dropdown-span.p-dropdown .p-dropdown-trigger {
    right: 0 !important;
    position: absolute !important;
    top: 10px !important;
    font-size: 12px !important;
}

.dropdown-span span.p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
    font-size: 12px !important;
    color: #000;
}

.dropdown-span.p-dropdown.p-component.p-inputwrapper.form-control.dropdown-span {
    display: flex !important;
    align-items: center !important;
}

.mid-container-dashboard {
    max-width: 92%;
    margin: 0 auto;
}

.dashboard-card {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
}

.card.dashboard-card:hover {
    border: none;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    cursor: inherit;
}

.dashboard-service-table .table thead th {
    padding-top: 7px;
    padding-bottom: 7px;
    background: #F8F7F7;
    border: none;
    font-size: 12px;
    font-weight: 600;
    color: #8B17E7;
}

.dashboard-service-table .table thead th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dashboard-service-table .table thead th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.dashboard-service-table .table td,
.table th {
    border-top: none;
    vertical-align: middle;
}

.dashboard-service-table table {
    border-collapse: separate;
    border-spacing: 0px 12px;
}

.dashboard-service-table .table td {
    font-size: 12px;
    background: #F8F7F7;
    font-weight: 400;
}

.dashboard-service-table .table tbody td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dashboard-service-table .table tbody td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.badge-success.badge-admin {
    border: 1px solid #2FAD87;
    background-color: #D5EFE7;
    color: #2FAD87;
}

.servicelistingtable .badge {
    font-size: 12px;
    border-radius: 20px;
    padding: 3px 12px !important;
}

.badge-warning.badge-admin {
    border: 1px solid #FFE500;
    background-color: #FFFACC;
    color: #CCB700;
}

.custom-control-label {
    padding-top: 6px;
    padding-left: 5px;
    color: #575757;
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #A9A9A9;
}

.start-service-btn {
    height: 32px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 0 auto;
    font-size: 14px !important;
}

.adminbtn-position {
    position: absolute;
    left: 34%;
    bottom: -14px;
}

.adminbtn-position-full {
    position: absolute;
    left: 43%;
    bottom: -14px;
}

.admin-card-h {
    /* height: 350px; */
    height: 500px;
}

.workflow-card-h {
    height: 350px;
}

.logo-purple {
    width: 64px;
    height: 64px;
    background-color: #47127D;
    padding: 13px;
}

.dashboard-card {
    border: none !important;
    border-radius: 8px !important;
}

/************datatable**************/
.userlisttable span.p-column-title {
    color: #47127D;
    font-weight: 700 !important;
}

.userlisttable .p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem #47127d;
}

.userlisttable .p-paginator {
    background: #F8F7F7 !important;
    border: none;
    padding: 2px;

}

.userlisttable .p-datatable .p-sortable-column .p-sortable-column-icon,
.userlisttable .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon .userlisttable .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #47127D !important;
    font-size: 12px !important;
    font-weight: 700;
}

.userlisttable .p-paginator span,
.userlisttable .p-paginator button {
    font-size: 12px !important;
}

.userlisttable .p-datatable .p-datatable-thead>tr>th {
    padding: 0px;
    border: none;
}

.userlisttable .p-column-title {
    padding: 7px !important;
}

.userlisttable .p-datatable-thead tr {
    background: #F8F7F7 !important;
    border-radius: 4px;
    font-size: 12px !important;
}

.userlisttable .p-datatable .p-datatable-tbody>tr>td {
    border: none !important;
    padding: 10px !important;
}

.userlisttable .p-datatable .p-datatable-tbody>tr>td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.userlisttable .p-datatable .p-datatable-tbody>tr>td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.userlisttable .p-datatable .p-datatable-tbody>tr {
    border-radius: 4px !important;
    background: #F8F7F7 !important;
    font-size: 12px !important;
}

.userlisttable .p-datatable table {
    border-collapse: separate !important;
    border-radius: 4px !important;
    border-spacing: 0px 7px;
}

.report-table thead.p-datatable-thead tr {
    background: #EEE6F7 !important;
}

.legal-table thead.p-datatable-thead {
    display: none;
}

.container-fluid.bg-community-banner.bg-community-banner-left:after {
    left: 0px;
}

.userlisttable.report-table .p-datatable .p-datatable-tbody>tr>td.cost-alignment {
    text-align: center !important;
}

/* .report-last-row.p-datatable .p-datatable-tbody>tr:last-child {
    font-weight: bolder !important;
} */

/* **************paginator css ************** */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #2A2A2A !important;
    background: #EEE6F7 !important;
    border-color: none !important;
}

.p-link:focus {
    box-shadow: none !important;
}

button.p-paginator-next.p-paginator-element.p-link,
button.p-paginator-prev.p-paginator-element.p-link {
    background: #EEE6F7 !important;
    border-radius: 4px !important;
    color: #47127D !important;
}

button.p-paginator-prev.p-paginator-element.p-link.p-disabled,
button.p-paginator-next.p-paginator-element.p-link.p-disabled {
    background: none !important;
}

/* span.p-paginator-icon.pi.pi-angle-left:before {
            content:"\f177" !important;
          }
          
          span.p-paginator-icon.pi.pi-angle-right:before {
            content:"\f178" !important;
          } */
/************datatable end**************/
.edit-img {
    cursor: pointer;
}

.whole-card {
    border: none !important;
    padding: 10px;
}

.whole-card2 {
    border: none !important;
}

.form-row label,
.form-row h6,
.form-row .p-multiselect-label,
.form-row .report-cal input {
    font-size: 12px !important;
}

.report-div .btn-cmn3 {
    background: #47127D !important;
}

.bg-community-banner.report-banner:after {
    left: 0 !important;
}

/************Set up Your Service***************/
.service-position {
    position: relative;
    bottom: 66px;
    z-index: 2;
}

.service-card-hover {
    border: none;
    border-radius: 8px;
}

.card.service-card-hover:hover {
    border: none;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    cursor: inherit;
}

.mid-container.mid-container-service {
    max-width: 92%;
    margin: 0 auto;
}

.setup-service-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    /* background-color: #47127D;
    color: #ffffff; */
    width: 126px;
    height: 24px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.setup-service-tabs .nav-pills .nav-link {
    color: #2A2A2A;
    width: 126px;
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
}

.setup-service-tabs .nav-pills {
    width: max-content;
    padding: 3px;
    border-radius: 8px;
}

/* .setup-service-tabs .nav-item a:active {
    background: #47127D !important;
} */

.setup-service-tabs .nav-item a {
    background: #fff;
}

.service-setup-form .form-control::placeholder {
    color: #BCBCBC;
    opacity: 1;
}

.service-setup-form .cover-msg textarea.form-control {
    height: 292px;
    resize: none;
}

.setup-service-tabs .card-body {
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);
}

.img-secondary-purple {
    filter: brightness(0) saturate(100%) invert(19%) sepia(95%) saturate(6733%) hue-rotate(274deg) brightness(92%) contrast(100%);
    height: 20px;
}

.service-tile-preview {
    background: #F8F7F7;
    padding: 60px 0px;
}

.alert-warning {
    color: #CCB700;
    background-color: #FFFACC;
    border-color: #FFE500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.alert-warning-icon {
    filter: brightness(0) saturate(100%) invert(57%) sepia(92%) saturate(673%) hue-rotate(17deg) brightness(101%) contrast(101%);
    height: 20px;
}

.alert-icon-b {
    border: 2px solid #ccb700;
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    margin-right: 10px;
}

.leads-check-select.setup-service {
    justify-content: flex-start;
}

.leads-check-select.setup-service .form-check {
    display: flex;
}

.leads-check-select.setup-service .form-check .form-check-input {
    margin-top: 21px;
    margin-right: 24px;
}

/***********Admin Dashboard************/
.mid-container-dashboard {
    max-width: 92%;
    margin: 0 auto;
}

.admin-dashboard-position {
    position: relative;
    bottom: 156px;
    z-index: 2;
}

.dashboard-card {
    border: none !important;
    border-radius: 8px !important;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778) !important;
}

.card.dashboard-card:hover {
    border: none;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    cursor: inherit;
}

.dashboard-service-table .table thead th {
    padding-top: 7px;
    padding-bottom: 7px;
    background: #F8F7F7;
    border: none;
    font-size: 12px;
    font-weight: 600;
    color: #8B17E7;
}

.dashboard-service-table .table thead th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dashboard-service-table .table thead th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.dashboard-service-table .table td,
.table th {
    border-top: none;
    vertical-align: middle;
}

.dashboard-service-table table {
    border-collapse: separate;
    border-spacing: 0px 12px;
}

.dashboard-service-table .table td {
    font-size: 12px;
    background: #F8F7F7;
    font-weight: 400;
}

.dashboard-service-table .table tbody td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dashboard-service-table .table tbody td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.badge-success.badge-admin {
    font-size: 12px;
    border: 1px solid #2FAD87;
    background-color: #D5EFE7;
    color: #2FAD87;
    border-radius: 20px;
    padding: 3px 12px;
}

.badge-warning.badge-admin {
    font-size: 12px;
    border: 1px solid #FFE500;
    background-color: #FFFACC;
    color: #CCB700;
    border-radius: 20px;
    padding: 3px 12px;
}

.custom-control-label {
    padding-top: 6px;
    padding-left: 5px;
    color: #575757;
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #A9A9A9;
}

.start-service-btn {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 0 auto;
}

.adminbtn-position {
    position: absolute;
    left: 34%;
    bottom: -14px;
}

.adminbtn-position-full {
    position: absolute;
    left: 43%;
    bottom: -14px;
}

.admin-card-h {
    /* height: 335px; */
    height: 450px;
}

.logo-purple {
    width: 64px;
    height: 64px;
    background-color: #47127D;
    padding: 13px;
}

/****** delete popup*******/
.p-confirm-popup .p-confirm-popup-footer {
    text-align: center !important;
}

.p-button.p-confirm-popup-accept.p-button-danger {
    background: #47127D !important;
    border-radius: 4px !important;
    border: 1px solid #47127D !important;
    width: 140px !important;
    padding: 2px !important;

}

.p-button.p-confirm-popup-reject.p-button-text {
    background: #fff !important;
    border-radius: 4px !important;
    border: 1px solid #47127D !important;
    color: #47127D !important;
    width: 140px !important;
    padding: 2px !important;
}

.p-button.p-button-danger:enabled:focus,
.p-button.p-button-text:enabled:focus {
    box-shadow: none !important;
}

.p-confirm-popup {
    width: 30%;
}

.p-confirm-popup .p-confirm-popup-content {
    display: block !important;
    text-align: center !important;
    padding-bottom: 40px !important;
}

.p-confirm-popup {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    top: 43% !important;
    left: 40% !important;
}

.p-confirm-popup.p-confirm-popup-flipped:before,
.p-confirm-popup.p-confirm-popup-flipped:after {
    display: none
}

.history-btn-div {
    display: flex;
}

/****** delete popup end*******/
.dashboard-banner-left::after {
    left: 0 !important;
}

.account-label {
    color: #fff;
}

/* .canvas-width canvas.canvasjs-chart-canvas {
    width: 90% !important;
} */

/***********Company Profile*************/
.setup-service-tabs.company-profile {
    width: 100%;
}

.inputfile.inputfile-2+label {
    border: 1px solid rgb(232 231 231);
}

.brokerbudda-table.table td,
.brokerbudda-table.table th {
    vertical-align: middle;
}

.table.brokerbudda-table.company-profile-table .thead-light th {
    background-color: #F9F9F9;
}

.table.brokerbudda-table.company-profile-table td,
.table.brokerbudda-table.company-profile-table th {
    background-color: #FBFBFB;
}

/**********User Profile***************/
.user-name {
    background-color: #EEE6F7;
    width: 48px;
    height: 48px;
    font-size: 24px;
    padding: 8px;
    border-radius: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #47127D;
}

.password-info-text {
    color: #47127D;
    margin-top: 20px;
}

.p-inputtext {
    font-size: 12px !important;
}

/*************Account Users*************/
.account-users-card {
    border: none;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
    border-radius: 8px;
}

.account-users-card.card:hover {
    /* border: none; */
    /* -webkit-transform: translateY(0px); */
    /* transform: translateY(0px); */
    cursor: inherit;
}

.single-acc-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: #F6F6F6;
    border-radius: 8px;
    margin-bottom: 10px;
}

.account-user-hover {
    cursor: pointer;
}

.account-edit-icon {
    display: none;
}

.account-edit-icon {
    display: block;
}

/* .profile-wrapper {
    min-height: 100vh;
} */

.dashboard-service-table .dashboard-service-action {
    display: none !important;
}

.dashboard-service-action {
    display: block !important;
}

.workflowtable td {
    width: 50% !important;
}

/* .btn-secondary:hover img {
    filter: brightness(0) saturate(100%) invert(15%) sepia(44%) saturate(3876%) hue-rotate(257deg) brightness(89%) contrast(112%) !important;
} */

.poweredby-logo {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 10;
}

.request-position-in .req-header {
    bottom: 125px;
}

.mid-bg-center {
    margin: 0 auto;
}

.company-minput .minput {
    width: 100% !important;
}

/* ************Video page **************** */
.videos-search-bar input {
    height: 64px;
    text-indent: 25px;
    border: none;
    border-radius: 8px !important;
    padding: 10px 10px 10px 30px;
}

.videos-search-bar {
    box-shadow: 0 0 40px rgb(51 51 51 / 10%);
    background: #fff;
    border-radius: 8px;
    position: relative;
    bottom: -31px;
    width: 100%;
    z-index: 1;
}

.videos-search-bar .fa-search {
    position: absolute;
    top: 24px;
    left: 27px;
}

.videos-search-bar .arrow-search {
    position: absolute;
    top: 18px;
    right: 27px;
}

.video-img {
    width: 100%;
    border-radius: 8px;
    height: 144px;
}

.video-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.video-description {
    display: block;
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px;
}

.com-video-more {
    position: absolute;
    bottom: -1px;
    right: -1px;
}

.react-video iframe,
.react-video video {
    border-radius: 8px;
    height: 144px;

}

.react-video iframe:hover,
.react-video video:hover {
    width: 100%;
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 8px;
    border: 2px solid #47127D;
    /* opacity: 0.5; */
}

.react-video-dialog iframe,
.react-video-dialog video {
    border-radius: 8px;

}

.iconvideo {
    color: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.video-main:hover .video-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(106%) contrast(106%);
    height: 35px;
}
/* .video-main:first-child{
width: 500px !important;
} */
.video-title-border {
    border-left: 4px solid #47127D;
}

.btn-outline-secondary:hover .img-secondary {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(103%) contrast(104%);
}

.videoDialog.p-dialog .p-dialog-header {
    /* padding-top: 0px;
   padding-bottom: 0; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.videoDialog {
    position: absolute !important;
    /* top: 25% !important;
    left: 25% !important; */

}

.videoDialog.p-dialog .p-dialog-content {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}




/* ******************Add label for multiselect dropdown************************* */
.p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box:after {
    content: "Select All";
    left: 27px;
    position: absolute;
    width: 100px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    color: #000 !important;
}

.video-prevnextbtn-div {
    text-align: right;
}

.video-dialog-date-title-div {
    margin-left: 20px;
}

.counter {
    color: #9a9a9a;
    line-height: 40px;
    text-align: center;
    position: absolute;
    font-size: 0.875em;
    opacity: 0.4;
    height: 100%;
    width: 50px;
    top: 22px;
    right: 10px;
}

.conter-color {
    color: #2FAD87 !important;
    font-weight: 700;
}

/* .p-component.p-editor-container.editor_css{
    position: relative;
}
.p-editor-toolbar.ql-toolbar.ql-snow{
    position: absolute;
    z-index: 1;
    background: transparent;
    border: none;
    bottom: 0px;
} */

.fs-loader {
    position: absolute;
    background: #1c0446;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 2;
    visibility: visible;
    background-image: url(../images/new-images/bg-design-2x-2.png), url(../images/new-images/bg-design-2x-1.png);
    background-position: bottom left, top right;
    background-repeat: no-repeat;
}

.fs-loader:after {
    content: "";
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #443b5c, #47127d);
    background-color: #333;
    opacity: .7;
}

.community-card.event-card .card-body {
    min-height: auto !important;
}

.apispec-top {
    min-height: 400px;
    margin-top: 4rem;
    width: 100%;
}

.review-btn {
    width: 50%;
}

/*********Vertafor page***********/
.vertafor-reg-pos {
    position: relative;
    bottom: 182px;
}

.vertafor-reg-inst {
    background-color: #EEE6F6;
    border-radius: 8px;
    padding: 32px;
}

.vertafore-logo,
.vertafore-textlogo {
    height: 45px !important;
}

.ins-logo {
    height: 50px !important;
}

.ins-text-logo {
    height: 23px !important;
}

.vertafor-title {
    padding: 8px 16px;
    background: linear-gradient(146.14deg, #7E59A4 22.98%, #6C4197 84.05%);
    border-radius: 4px;
}

.vertafor-number {
    background: linear-gradient(146.14deg, #7E59A4 22.98%, #6C4197 84.05%);
    border-radius: 4px;
    color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.vertafore-form-title {
    border-left: 4px solid #47127D;
}

.vertafore-form-title h2 {
    font-weight: 700;
    color: #555555 !important;
}

.vertafor-card .card {
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);
    border-radius: 8px;
    border: none;
}

.vertafore-ok-btn {
    width: 150px;
}

.vertafore-submit-btn {
    padding: 5px 32px;
    font-size: 16px;
    font-weight: 400;
    width: 169px;
    position: absolute;
    bottom: 0px;
    left: 40%;
}

.vertafore-main .poweredby-mb-div {
    display: none !important;
}

.vertafore-loading {
    position: absolute !important;
    bottom: 0 !important;
    left: 50% !important;
}

.vertafore {
    min-height: 87.5vh;
}

.vertafore-message .message-padding {
    margin-bottom: 10px;
}

.feature-play {
    cursor: pointer;
}

.subscribed-service-main-div #content {
    min-height: 92vh;
}

.seller-link-div p:hover,
.buyer-link-div p:hover {
    cursor: pointer;
    color: #a531cd !important;
}

/*********partner login************/
/* .partner-background-theme{
    background-image: url('../images/new-images/right-bg-top.svg'), url('../images/new-images/left-bottom-bg.svg');
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;
} */
.max-w-570px {
    max-width: 570px;
    margin: 0 auto;
    width: 570px;
}

.login-box {
    height: calc(100vh - 101px);
}

.login-box-wrapper {
    vertical-align: middle;
    align-items: center;
    height: 100%;
    display: flex;
}

.FooterLogo {
    width: 225px !important;
}

.partner-footer a {
    font-size: 18px;
    color: #6F2D80 !important;
}

.partner-box-content {
    margin-top: 50px;
    background: #fff;
    padding: 25px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 16%);
    border-radius: 5px;
    min-height: 434px;
}

#emailPreviewId {
    right: 25%;
}

.emailpreviewclocebtn {
    position: absolute;
    display: block;
    top: -120px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    z-index: 75;
    right: 26%;
}

.gigmarket-admin {
    position: relative;
}

.gigmarket-admin:after {
    top: 0 !important;
}

.errormsg-a-font {
    background: #FFCDD2;
    border: solid #e60017;
    border-width: 0px;
    color: #73000c;
}
.middle-header-width .main-middle-header-div{
    width: 100%;
}
.faq-col{
    text-align: justify;
    margin-bottom: 10px;
}
.pf-about-main .about-btn{
display: none;
}
.pf-about-main h2{
    line-height: 1.2 !important;
}
.about-page-main .about-pf-btn{
    display: none !important;
}

.hbspt-form iframe{
    position: absolute !important;
    top:77% !important;
    left: 7px !important;
}
.policy-center{
  display: flex;
  align-items: center; 
  justify-content: center;  
}
.company-name-text{
    font-size: 12px;
}
.authenticating-block{
    border-radius: 8px;
    padding: 10px;
    font-size: 24px;
}
.social-icon-css {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    display: flex;
    margin: 0px 2px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    font-size: 0px;
    line-height: 0;
    user-select: none;
  }
  
  .google-auth-button {
    font-size: 14px;
    display: inline-flex;
    width: 100%;
    border-width: 0px;
    border-radius: 3px;
    align-items: center;
    font-weight: bold;
    height: 40px !important;
    line-height: 40px !important;
    background: rgb(255, 255, 255) !important;
    /* box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important; */
  }
  
  .social-icon-text {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    margin: 0px 2px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .login-mid-container{
    min-height: 63.5vh;
  }
  .social-button-main-div{
    width: 75%;
  }
/* ---------------------------------------------------
  MEDIAQUERIES
----------------------------------------------------- */
@media(max-width:1441px) and (min-width:320px) {
    .req-btn {
        font-size: 12px !important;

    }

}

@media(min-width:1024px) and (max-width:1600px) {
    #req-before-login {
        margin-top: 270px !important;
    }

    .vertafore {
        min-height: 89.5vh !important;
    }
    .hbspt-form iframe{
        position: absolute !important;
        top:100% !important;
        left: 7px !important;
    }
}

@media(min-width:1024px) and (max-width:1441px) {
    .bg-community-banner.bg-height {
        min-height: 425px !important;
    }

    #emailPreviewId {
        right: 15%;
    }

    .emailpreviewclocebtn {
        right: 16%;
    }

    .status-tag {
        padding: 5px 6px !important;
    }

    .admin-card-h {
        /* height: 430px; */
        height: 470px;
    }

    .blog-card-height {
        min-height: 180px !important;

    }

    .blog-img {
        height: auto !important;
    }

    .blog-article-card {
        min-height: 300px !important;
    }

    .community-small-blog {
        min-height: 312px !important;
    }

    .req-btn {
        font-size: 12px !important;
    }

}

@media (max-width:1024px) {
    .req-btn {
        font-size: 11px !important;
        padding: 5px !important;
        height: 45px;
    }

    .req-div {
        padding: 5px !important;
        margin-right: 0px !important;
    }

    .service-tile-preview {
        padding: 12px;
    }

    .history-alert {
        font-size: 14px;
    }

    .history-btn {
        font-size: 12px !important;
    }
}

@media(min-width:768px) {
    .image-length .slick-slide {
        width: 170px !important;
        height: 110px !important;

    }

    /* .about-third-video {
     
        width: 200% !important;
        height:400px !important;
    } */

}

@media(min-width:1024px) and (max-width:1024px) {
    .bg-community-banner.bg-height {
        min-height: 480px !important;
    }

    .report-div {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .blog-card-height {
        min-height: 217px !important;
    }

    .image-length .slick-slide {
        width: 120px !important;
        height: 90px !important;

    }

    .slick-slide img,
    .image-slider video,
    .image-slider iframe {
        width: 120px !important;
        height: 90px !important;
    }
}

@media (max-width: 768px) and (min-width: 768px) {
    .banner-heading {
        font-size: 31px;
    }

    .p-datepicker table {
        font-size: 10px !important;
    }

    .wf-wrapper {
        min-height: 180vh !important;
    }

    .error-page-top {
        margin-top: 85px;
    }
}

@media (max-width: 768px) {

    .error-page-main .poweredby-logo, .nowcerts-sso-page-main .poweredby-logo{
        bottom: 14% !important;
    }
    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

 
}

.cardhover {
    border: 1px solid #f4f3f3;
    margin-bottom: 20px;
    transition: border 0.1s, transform 0.3s;
}

.cardhover:hover {
    border: 1px solid #6f2d80;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    cursor: pointer;
}

.card-p {
    color: var(--color-three);
}

.card-p i {
    color: var(--color-two);
    margin-right: 8px;
}

.service1 strong {
    color: #062350;
    font-size: 16px;
}

.service1 p {
    color: gray;
    font-size: 14px;
}

.service1 span {
    color: #6f2d80;

}

.ser-des strong {
    font-size: 14px;
    color: #6f2d80;
}

.sub-heading p {
    font-size: 16px;
    color: #6f2d80;
}

.sub-heading strong {
    color: #062350;
    font-size: 16px;
}

.footer {
    color: #fff;
    font-size: 14px;
}

.mid-sec {
    min-height: 300px;
    background-color: #fff;
    width: 500px;
    border-radius: 5px;
}

.mid1-right {
    padding: 0 !important;
    margin: 0 !important;
}

.mid1-left {
    padding: 0;
    margin: 0;
}

.mid1-right b {
    font-size: 16px;
    color: black;
}

.mid1-left,
.ml1 a {
    font-size: 14px;
    color: #a531cd !important;
}

.resetpassword-main-div .ml1 a,
.setpassword-main-div .ml1 a {
    font-size: 1rem;
}

.listbtn {
    font-size: 14px;
    color: #47127d !important;
    /* font-weight: bold; */
}

.mid1-left,
.ml1 p {
    font-size: 14px;
    color: black;
}

.mid-sec-inner {
    padding: 0 10px;
}

.mid-sec-inner label {
    color: gray;
}

.mid-sec-inner h3 {
    color: black;
}

.mid-sec-inner1 {
    padding: 0 10px;
}

.mid-sec-inner1 label {
    color: gray;
}

.mid-sec-inner1 h3 {
    color: black;
}

@media screen and (min-width: 2000px) {
    .continer2 {
        padding-left: 100px;
    }

}

@media screen and (min-width: 2500px) {
    .continer2 {
        padding-left: 200px;
    }
}

@media screen and (min-width: 3000px) {
    .continer2 {
        padding-left: 400px;
    }
}

@media screen and (min-width: 3500px) {
    .continer2 {
        padding-left: 500px;
    }
}

@media screen and (min-width: 4000px) {
    .continer2 {
        padding-left: 700px;
    }
}

@media screen and (min-width: 4500px) {
    .continer2 {
        padding-left: 800px;
    }
}

@media screen and (min-width: 5000px) {
    .continer2 {
        padding-left: 1000px;
    }
}

@media screen and (min-width: 5500px) {
    .continer2 {
        padding-left: 1100px;
    }
}


/* @font-face {
    font-family: ReadexPro-Light;
    src: url(../fonts/ReadexPro-Light.ttf);
}

@font-face {
    font-family: ReadexPro-Bold;
    src: url(../fonts/ReadexPro-Bold.ttf);
}

@font-face {
    font-family: ReadexPro-semiBold;
    src: url(../fonts/ReadexPro-SemiBold.ttf);
} */

@font-face {
    font-family: 'Readex Pro', sans-serif;
    src: url(../fonts/ReadexPro-Regular.woff2);
}


html {
    width: 100%;
}

html,
body {
    color: rgba(87, 87, 87, 1);
    line-height: 20px;
    
}

p {
    line-height: 20px;
    font-weight: 400;
}


.main-header {
    border-bottom: 1px solid #d4cece;
}

.main-content {
    padding-top: 60px;
}

.bg-hash {
    background-color: #FBFBFA !important;
}

.bg-hash .p-tabview .p-tabview-nav,
.bg-hash .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: #FBFBFA !important;
}

/* .menu1 .dropdown-menu1.show {
    margin-top: 22px;
    box-shadow: 0px 2px 4px #00000038;
}

.menu1 .nav-link {
    color: #707070;
}

.menu1 .dropdown-menu1 ul li {
    text-align: center;
    width: 79px;
}

.menu1 .dropdown-menu1 {
    padding: 17px 15px;
}

.menu1 .dropdown-menu1 ul li a {
    padding-left: 0px;
}

.menu1 .dropdown-menu1 ul {
    justify-content: space-between;
}

.menu1 .dropdown-menu1 ul li a {
    padding: 6px;
    color: #3D2D80;
}

.menu1 .dropdown-menu1 .dropdown-item.active {
    padding: 6px;
    background-color: #F6F5FC;
    border: 1px solid #6F2D80;
    border-radius: 5px;
    color: #3D2D80;
}

.menu1 .dropdown-menu1 i {
    font-size: 20px;
}

.menu1 .dropdown-menu1 span {
    font-size: 11px;
    display: block;
    padding-top: 8px;
} */

.dropdown-menu-ul {
    display: flex;
    align-items: center;
}

.main-header-row {
    padding: 5px 0px;
}

.mysidenavshow {
    width: 250px;
}

.mysidenavhide {
    width: 0;

}

.cardLogo {
    height: 35px !important;
    width: 35px !important;
}

i.fa.fa-ellipsis-v.test {
    padding-right: 2px !important;
    font-size: inherit !important;

}

.search-fa-icon {
    cursor: pointer;
}

.middleheader-heading,
.middle-main-heading {
    font-size: 20px;
    color: #fff;
}

.middleheader-para {
    color: rgba(255, 255, 255, 0.5);
}

.search-btn-border {
    border: 1px solid #dee2e6 !important;
}

.validation-error-msg {
    color: red;
    font-size: 12px;
}

.inputValidation {
    border: 1px solid red !important;
}

.leftbg {
    height: 200px;
}

.mid2 {
    height: 700px;
}

.mid2-register {
    height: 610px;
}

.mid3 {
    height: 950px;
}

.card-p {
    min-height: 290px !important;
}

.dashboard .card,
.usercard {
    border: none !important;
    box-shadow: 0px 2px 4px #0000001A !important;
}

.dashboard .card:hover {
    border: 1px solid #f4f3f3;
    -webkit-transform: translateY(-10px);
    transform: translateY(0px);
    cursor: initial;
}

.dashboard .card-header {
    background-color: transparent;
    border-bottom: none;
}

.usercard {
    margin-bottom: 20px;
}

::placeholder {
    color: #3f5678 !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    color: #3f5678;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3f5678;
}

a {
    cursor: pointer;
}

a.nav-link.dropdown-user-link.nav-link2 {
    display: inline-block !important;
}

.middle-header-position {
    margin-top: 64px;
}

.row-position {
    position: relative;
    top: 64px;
}

.privacypolicylink,
.privacypolicylink:hover {
    color: #fff;
    text-decoration: none;
}

.policy-li {
    text-align: justify;
}

.policy-p {
    text-align: justify;
    margin-top: 80px;
}

.policy-p h5,
.policy-p h6 {
    font-weight: bold;
}

.policy-p h5 {
    color: #6f2d80 !important;
}

.sticky-top {
    background-color: #fff;
}

.row.bg2 {
    margin-right: 0px !important;
}

.wf-header {
    margin-top: 51px;
}

.wfbg {
    margin-top: 51px;
}

.wfbg .rightbg {
    position: absolute !important;
}

.filteractive {
    background-color: rgba(134, 44, 177, 1) !important;
    color: #fff !important;
}

.filteractive img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%) !important;
}

.registration-p {
    color: black !important;
}

.registration-b {
    color: #a531cd;
    font-size: 14px;
}

.term-condition-dialog {
    width: 50vw;
}

.termconditioninput {
    width: auto;
    margin-top: 10px;
    margin-right: 5px;
}

.link-expire-h1 {
    color: #6f2d80;
    font-size: 40px;
    margin-top: 10px;
    text-align: center;
}

.link-expire {
    font-size: 20px;
    text-align: center;
}

.reset-link-h1 {
    color: #6f2d80;
    font-size: 50px;
    margin-top: 10px;
}

.summary {
    margin-bottom: 30px;
}

.privacy-heading {
    text-align: center;
}

.header-div1,
.logodiv {
    padding: 0 !important;
    margin: 0 !important;
}

.opensidebar {
    font-size: 22px;
    cursor: pointer;
}

.footer-content1 {
    text-align: left !important;
}

.footer-content2 {
    text-align: right !important;
}

.details-tabss .p-tabview-nav li {}

.nav-tabs .nav-link.active,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #47127D !important;
    color: #fff !important;

    /* font-weight: bold !important;
    background-color: transparent !important;
    border-bottom: 3px solid #0d2a56 !important;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    color: #495057 !important; */
}

.p-tabview-nav-link {
    border-radius: 4px;
    width: 122px;
    height: 24px;
    color: rgba(0, 31, 77, 1) !important;
    font-size: 12px;
    justify-content: center;
}

.servicedetailstabs.p-tabview .p-tabview-nav li {
    border: 3px solid #DDDDDD;
    border-radius: 4px;
}

.servicedetailstabs.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 12px !important;
}

.servicedetailstabs.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #DDDDDD !important;
}

/* .ss .slick-track{
    left: 35% !important;
} */
.image-slider {
    margin: 0 auto;
    text-align: center;
}

.ss .slick-prev,
.ss .slick-next {
    margin: 10px;
}

.details-tabss .p-tabview .p-tabview-nav {
    border: none !important;
}

a:hover,
.nav-link:hover {
    text-decoration: none !important;
}

li:focus-visible,
a:focus-visible {
    outline: none !important;
}

.nav-tabs {
    border-bottom: 1px solid #fff;
}

.nav-justified {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs a {
    color: gray;
}

.wf-btn {
    color: #fff !important;
    background-color: #6f2d80 !important;
    padding: 0.375rem 0.75rem !important;
    width: 100%;
    font-size: 1rem !important;
}

/* .btn-outline-secondary {
    color: gray;
    border-color: #d6d1d1;
} */

.btn-outline-secondary:hover {
    background-color: rgba(71, 18, 125, 1) !important;
    border: 1px solid rgba(71, 18, 125, 1) !;
    color: #fff !important;
}

.btn-outline-secondary {
    background-color: transparent !important;
    border: 1px solid rgba(71, 18, 125, 1) !important;
    color: rgba(71, 18, 125, 1) !important;
    width: 167px;
}

#ThumbnailCarousel img {}

#ThumbnailCarousel div {
    height: 250px;
    width: 100%;
}

#ThumbnailCarousel.carousel {
    padding-top: 10px;
    text-align: center;
}

.col-md-3 .img-thumbnail {
    height: 100%;
}

.cars {
    height: 170px;
}

.tab-content {
    font-size: 14px;
}

.table td,
.table th {
    font-size: 14px;
}

.lp {
    border-bottom: 1px solid #e0dcdc;
    padding: 10px 0 30px 0;
}

.lp2 {
    padding: 10px 0 30px 0;
}

.bt1 {
    border-radius: 25px !important;
    padding: 2px 15px 2px 15px !important;
    background-color: #001f4d !important;
    border-color: #001f4d !important;
    color: #fff !important;
    margin: 3px 0 3px 0 !important;
}

.card-name-height {
    height: 50px;
}

.ctl {
    width: 610px;
    background-color: #fff;
    border: 1px solid gray;
    padding: 30px 0 0 0;
    border-radius: 5px;
    margin: 5px 0 0 0;
    color: #0e0e45 !important;
}

.ctl p {
    color: #0e0e45 !important;
    cursor: pointer;
}

.ctl span {
    color: #0e0e45;
}

.editor-description span.ql-font.ql-picker,
.editor-description button.ql-link,
.editor-description button.ql-image,
.editor-description button.ql-code-block,
.editor-description button.ql-clean {
    display: none !important;
}

.slick-slide img {
    width: 160px;
    height: 99px;
    border-radius: 4px;
}

.image-slider video,
.image-slider iframe,
.image-slider img {
    border-radius: 4px;
}

.slick-dots li button:before {

    content: "\f068" !important;
    font-family: FontAwesome !important;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: inherit !important;
    /*--adjust as necessary--*/
    color: #000 !important;
    font-size: 18px !important;
    padding-right: 0.5em !important;
    position: absolute !important;
    top: 10px !important;
    left: 0 !important;
}

.scroll-top {
    background-color: #001f4d !important;
    bottom: 90px !important;
    right: 20px !important;
    width: 60px !important;
    height: 60px !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none !important;
}

li.p-unselectable-text.p-disabled {
    opacity: 0;
}

.PricePadding {
    min-height: 550px;
}

.user-profile img {
    height: 100px;
    width: 100px;
}

.companybtn .btn-cmn3 {
    margin: 0 !important;
    position: absolute !important;
    top: 43% !important;

}

.companybtn .btn-cmn3 {
    font-size: 12px !important;
    width: 120px !important;
}

.bg-1.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

.bg-1.active span {
    display: none;
}

.bg-1.active li.active {
    color: #fff;
    background: #6F2D80;
    border-radius: 5px;
}

.bg-1.active ul li.active>a {
    margin-right: 0px !important;
}

.bg-1.active ul li a:hover {
    margin-right: 0px !important;
}

.serviceonboardingtab a {
    flex-grow: 0 !important;
}

/* .servicedetailstabs ul.p-tabview-nav {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    box-shadow: 0px 2px 4px #0000001a;
    padding: 1.25rem;
} */
.servicedetailstabs.p-tabview .p-tabview-nav {
    border: none !important;
}

.servicedetailstabs .p-tabview-panels {
    padding: 0 !important;
}

.FileLabel {
    display: block !important;
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 600;
}

label.input.input-file {
    position: relative;
    display: block;
    font-weight: 400;
}

.input-file .button {
    position: absolute;
    top: 4px;
    right: 4px;
    float: none;
    height: 28px;
    margin: 0;
    padding: 3px 25px;
    font-size: 13px;
    line-height: 22px;
    background-color: #6F2D80;
    opacity: .8;
    outline: 0;
    border: 0;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;

}

.input-file .button input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    font-size: 30px;
    cursor: pointer;
    opacity: 0;
}


.input input {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    height: 35px;
    padding: 8px 10px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    background: #fff !important;
    font: 13px/16px 'Open Sans', Helvetica, Arial, sans-serif;
    color: #404040;
    -moz-appearance: none;
    -webkit-appearance: none;
}

input[type='radio'] {

    width: 23px;
    height: 23px;
    border: 1px solid #707070;
    border-radius: 50%;
    outline: none;
}

.videorow {
    display: block !important;
    margin: 0 auto !important;
}

.p-inputtext {
    padding: 0px !important;
}

i.pi.pi-refresh,
i.pi.pi-undo {
    display: none;
}

.service-rating i {
    font-size: 12px;
    color: #EA9624;
}

.headeravatarlogo .p-avatar {
    width: 20px !important;
    font-size: 12px !important;
    height: 20px !important;
    padding: 16px !important;
    /* color: black !important; */
}

.nav-link2 {
    padding: 10px !important;
}

.mid3 {
    min-height: 400px;
}

.title-p {
    font-size: 20px;
    color: #001f4d !important;
    font-weight: 500;
}

input[type='radio'] {
    -webkit-appearance: none;
    width: 23px;
    height: 23px;
    border: 1px solid #707070;
    border-radius: 50%;
    outline: none;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

input[type='radio']:checked:before {
    background: #001F4D;
}

.form-check-input {
    margin-top: 0px;
}

.form-check-label {
    padding-left: 5px;
    font-weight: 400;
}

.overview-form .form-control,
.price-form .form-control,
.p-dropdown-items-wrapper,
.apispec-form .form-control,
.screenshot-form .form-control,
.video-form .form-control {
    font-size: 12px !important;
    /* border: 1px solid rgb(232 231 231) !important; */
}

.price-form .p-dropdown .p-dropdown-label,
.price-form .pi {
    font-size: 12px !important;
}

.overview-form .p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid rgb(232 231 231);
}

.overview-form .p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #47127D;
}

.overview-form .ql-snow .ql-picker.ql-header {
    display: none !important;
}

.service-cardd {
    min-height: 100% !important;

}

/* .p-tabview-panels {
    background-color: #fbfbfa !important;
} */

.main-header {
    position: fixed !important;
    top: 0 !important;
    background-color: #ffffff !important;
    width: 100% !important;
    z-index: 10 !important;
}

.mobilecard {
    display: none !important;
}

.desktopcard {
    display: block;
}

.header-bar ul {
    display: flex !important;
    margin-bottom: 0px !important;
    align-items: center !important;
}

.header-bar ul li {
    list-style: none !important;
}

.servicedetailstabs .btn-cmn3 {
    width: 135px !important;
}

.wfcontiner {
    margin-top: 3rem;
}

.header-bar .header-row {
    align-items: center !important;
    /* height: 70px !important; */
}

.user-img img {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50% !important;
}

.header-row #sidebarCollapse i {
    color: #818182 !important;
    font-size: 18px !important;
}

/* Reports */
.report-cal input.p-inputtext.p-component {
    border: none;
}

.seller-report-cal {
    justify-content: right;
    align-items: center;
}

.report-cal .p-inputtext:enabled:focus {
    box-shadow: none;
}

.reports-charts .icon {
    background-color: #001F4D !important;
}

.total tr th {
    border-top: 1px dashed #c5c5c5 !important;
}

.dashboard .table thead th:last-child {
    text-align: center;
}

.border-top-none {
    border-top: none !important;
}

.reports-charts {
    display: flex;
    align-items: center;
}

.reports-charts i {
    color: #fff;
}

.reports-charts .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
}

.reports-user {
    display: flex;
    align-items: center;
}

.reports-user i {
    color: #fff;
}

.reports-user .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    background-color: #6F2D80;
}

.reports-costs {
    display: flex;
    align-items: center;
}

.reports-costs i {
    color: #fff;
}

.reports-costs .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    background-color: #D54CE0;
}

#mySidebar {
    width: 420px;
}

.textlogo1 {
    height: 30px;
    /* width: 160px; */
}
.textlogo-btis{
    height: 50px;
}
.textlogo {
    height: 20px;
    /* width: 160px; */
}

.sidebarnew .closebtn {
    position: absolute;
    font-size: 36px;
    right: 10px;
    cursor: pointer;

}

.filter-desktop-view img {
    margin-bottom: 22px;
    margin-left: 15px;
}

.sidebarnew {
    height: 100%;
    position: relative;
    z-index: 1;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    margin-top: 85px;
}

.btn-bg-close {
    /* background: rgba(205, 73, 73, 0.1) !important; */
    /* height: 32px;
    width: 32px; */
    padding: 0px 10px;
    height: 46px;
    color: rgba(205, 73, 73, 1);
    opacity: 1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex !important;
}

.bs-canvas-overlay {
    opacity: 0.85 !important;
    z-index: 1100 !important;
}

#sidebarCollapse {
    z-index: 99 !important;
}

.close-btn-desktop {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 30px;
}

.bs-canvas {
    top: 64px !important;
    z-index: 2 !important;
    /* overflow-x: hidden !important;
 overflow-y: auto !important; */
    width: 385px !important;
    transition: margin .4s ease-out !important;
    -webkit-transition: margin .4s ease-out !important;
    -moz-transition: margin .4s ease-out !important;
    -ms-transition: margin .4s ease-out !important;
}

.bs-canvas-left {
    left: 0 !important;
    margin-left: -385px !important;
}

/* .bs-canvas-right {
 right: 0 !important;
 margin-right: -330px !important;
} */
.ml-6 {
    margin-left: 4rem !important;
}

.mb-serachfilter {
    color: rgba(205, 73, 73, 1) !important;
    height: 30px;
    width: 30px;
    /* background: rgba(205, 73, 73, 0.1) !important; */
}

.clearbtn,
.filterserachbtn {
    width: 100%;
    margin-bottom: 10px;

}

/*end Reports */
@media (min-width: 320px) {
    .ctl {
        width: 100%;
    }

    .header-bar {
        padding: 10px 10px;
        /* height: 85px; */
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .logodiv {
        padding-left: 10px !important;
    }

    .listingpage-middle-header {
        margin-top: 20px;
    }

    #req-before-login {
        margin-top: 200px !important;
    }

    .topcmn {
        padding-top: 75px;
    }

    .wfcontiner {
        margin-top: 4rem;
    }
}

@media only screen and (max-width: 992px) and (min-width: 767px) {

    .wf-btn,
    .servicetopbar .btn-cmn3 {
        font-size: 14px !important;
        padding: 5px !important;
    }

    .pricingheight {
        min-height: 300px;
    }


    /* .topcmn{
        padding-top: 120px;
    } */
    .mid3 {
        min-height: 540px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {

    .pricingheight {
        min-height: 300px;
    }

    #req-before-login {
        margin-top: 200px !important;
    }

    .mid3 {
        min-height: 320px;
    }

    .card-name-height {
        height: 75px;
    }

    .card-p {
        min-height: 340px !important;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1025px) {

    .pricingheight {
        min-height: 450px !important;
    }

    .mid3 {
        min-height: 450px;
    }
    
}

@media (min-width: 320px) {
    .leftbg {
        height: 198px;
    }

    /* .rightbg{
        display: none !important;
    } */
}

.mobile-reports {
    display: flex;
}

.mobile-reports .form-padding-r {
    padding-right: 1rem;
}

@media (min-width: 320px) and (max-width: 720px) {
    .mobile-reports {
        display: block !important;
    }

    .mobile-reports .form-padding-r {
        padding-right: 0px;
        padding-bottom: 15px;
    }

    .report-form {
        padding-bottom: 15px;
    }

    .report-btn {
        margin-top: 10px;
    }

    .blog-bgimg {
        width: 50px;
        height: 50px;
    }

    .carousel-demo .product-item {
        margin-right: 0px;
    }

    /* .carousel-demo .p-carousel-items-content{
        height: 150px !important;
    }
    */
    .banner-heading {
        font-size: 24px;
    }

    .content.detail-content {
        margin-top: 0px !important;
    }

    .image-slider-mobile .p-image-preview-container {
        width: 100%;
    }

    .slick-slider.slick-initialized .slick-arrow.slick-prev {
        z-index: 1;
        position: absolute;
        left: 0px !important;
    }

    .slick-slide img {
        width: 100% !important;
    }

    .slick-slider.slick-initialized .slick-arrow.slick-next {
        position: absolute;
        right: 0px !important;
    }

    .slick-prev:before,
    .slick-next:before {
        color: #fff !important;
    }

    .row-position {
        top: 0px !important;
    }

    .video-width-screen {
        width: 100% !important;
        height: 140px !important;
    }

    .slick-slide img {
        height: 140px !important;
    }

    .btn-request-service-ico {
        display: none;
    }

    .agency-reg-text {
        text-align: center !important;
    }

    .terms-conditon-innovation {
        padding: 0px !important;
    }

    .innovation-council-main .policy-p {
        height: 815px !important;
    }

    .innovation-council-main .poweredby-mb-div {
        bottom: 0px !important;
    }

    .councilterms-condition-text {
        margin-bottom: 10px !important;
    }

    .company-minput .optionsbtn {
        margin-left: 0px !important;
        width: 100% !important;
    }

    .premiumpolicy-width {
        padding: 0px !important;
    }
}

@media (min-width: 768px) {
    .displayformobile {
        display: none !important;
    }
   .pf-about-main  .displayformobileview{
        display: none !important;
    }
    .community-position {
        position: relative !important;
        bottom: 120px !important;
        z-index: 2 !important;
    }

    .displayfordesktop {
        display: block !important;
    }
    .pf-about-main .displayfordesktopview {
        display: inline-block !important;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .slick-slide img {
        width: 157px;
    }

    .p-button.p-confirm-popup-accept.p-button-danger,
    .p-button.p-confirm-popup-reject.p-button-text {
        width: 100px !important;
    }

    .ss .slick-prev,
    .ss .slick-next {
        margin: 0px;
    }

    .community-mid-container {
        max-width: 92% !important;
    }

    .p-datepicker table {
        font-size: 12px !important;
    }

    .p-datepicker table td {
        padding: unset !important;
    }

    .app-desktop-topbar-background {
        width: 247px !important;
    }

    .rightbg {
        justify-content: unset !important;
        right: 0;
    }

    .wf-btn,
    .servicetopbar .btn-cmn3 {
        width: 100% !important;
    }

    .btn-cmn3 {
        width: 157px !important;
    }

    .rightbg {
        display: flex !important;
        width: 0% !important;
    }

    .app-bg2 {
        position: absolute !important;
        right: 248px !important;
    }

    .leftbg.login-page {
        width: 15%;
    }

    .mid-sec {
        margin-left: 0px;
        width: 100%;
    }

    .footer {
        position: initial;
    }

    .mobile-reports {
        padding-top: 10px;
    }

    .mobile-grid-form {
        display: block !important;

    }

    .mobile-grid-form .mobile-reports {
        max-width: 100%;
    }

    .row-position {
        top: 0px !important;
    }
}

@media only screen and (max-width: 600px) {
    .mid-sec {
        margin-left: 0px;
        width: 300px;
    }

    .mid2 {
        height: 760px;
    }

    .mid3 {
        height: 1060px;
    }
}

@media (min-width: 320px) {
    .leftbg {
        height: 198px;
    }

    /* .rightbg{
        display: none !important;
    } */
    .leftbg.login-page {
        width: 10%;
    }

    .login-submit {
        padding: 0px;
    }
}

.app-bg2 {
    position: absolute;
    /*top: 24px;*/
    right: -10px;
    width: calc(var(--desktop-base-size)*255);
    max-width: 265px;
}

.rightbg {
    justify-content: end;
}

.font-weight-bold {
    font-weight: 900 !important;
}

.leftbg {
    height: 200px;
}

.mid2 {
    height: 800px;
}

::placeholder {
    color: #3f5678 !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    color: #3f5678;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3f5678;
}

.badge-success,
.badge-warning,
.badge-danger {
    /* width: 110px; */
    font-weight: 500 !important;
}


.p-avatar {
    border-radius: 50% !important;
}

.btn-cmn4,
.btn-cmn5 {
    width: 77px;
}

.Dashboardcard {
    min-height: 575px;
    box-shadow: 0px 2px 4px #0000001a !important;
    /* border: 1px solid rgba(0,0,0,.125) !important; */

}

.account-content {
    padding: 1rem !important;
}

a.nav-link.dropdown-user-link.nav-link2:active {
    background: none !important;
}

.header-div1 {
    z-index: 999 !important;
}

.form-control:focus {
    border-color: #001f4da3 !important;
    box-shadow: 0 0 0 0.05rem hsl(216deg 100% 15% / 35%) !important;
}

/* form label {
    color: #8D8D8D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
} */

.p-inline-message {
    width: 100%;
    text-align: left;
}

.mid2,
.mid3 {
    background-color: #fbfbfa;
}

/* .p-avatar {
    background-color: #6f2d80 !important;
    color: #fff !important;
} */

.btn-primary:focus,
.btn-lg:focus,
.btn:focus,
.btn-warning:focus {
    box-shadow: none !important;
}

.btn-cmn {
    color: #fff !important;
    background-color: #6f2d80 !important;
    border-color: #6f2d80 !important;
    width: 157px !important;
    /* padding: 10px 40px ; */
}

.longbtn .btn-cmn {
    width: auto !important;
    margin-right: 5px;
    margin-bottom: 10px;
}

.btn-cmn2 {
    color: #fff;
    background-color: #df9941;
    border-color: #df9941;
    width: 157px !important;
    padding: 10px 40px;
}

.btn-cmn3 {
    color: #fff !important;
    background-color: #001f4d !important;
    border-color: #001f4d !important;
    width: 75%;
}

.btn-cmn4 {
    color: #6f2d80 !important;
    background-color: #fff !important;
    border-color: #6f2d80 !important;
    font-size: 14px !important;
    padding: 5px 10px !important;
}

.btn-cmn5 {
    color: #fff !important;
    background-color: #6f2d80 !important;
    border-color: #6f2d80 !important;
    font-size: 14px !important;
    padding: 5px 10px !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    /* background: #001f4d !important; */
    background: #2FAD87 !important;
}

.p-inputswitch .p-inputswitch-slider {
    background: #CD4949 !important;
}

/* .slick-prev:before,
.slick-next:before {
    color: #000 !important;
} */

.mobileview-hamburger {
    display: none !important;
}

.slick-prev {
    left: -20px !important;
}

.slick-next {
    right: -19px !important;
}

.errorpagetext {
    color: #000;
    text-align: center;
}

.p-datatable .p-datatable-thead>tr>th {
    /* background: none !important; */
}

.breadcrumb {
    padding: 0px;
}

ul.breadcrumb {
    list-style: none;
    background-color: transparent;
    padding-inline-start: 0px;
    padding-bottom: 20px;
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: #fff;
    content: "/\00a0";
    font-size: 14px;
}

ul.breadcrumb li {
    display: inline;
    font-size: 18px;
}

ul.breadcrumb li a {
    color: #f4f4f4;
    text-decoration: none;
    font-size: 14px;
}

ul.breadcrumb li a.active {
    color: #D54CE0;
    font-weight: 500;
}

ul.breadcrumb li a:hover {
    color: #a93db2;
    text-decoration: none;
}

.demo1 {
    margin: 0 auto;
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.middle-top {
    padding-top: 3rem;
}

.sub-heading {
    padding-top: 1rem;
}

.sub-heading2 {
    padding-top: 1rem;
}

.topcmn {
    /* padding-top: 80px; */
    margin-top: 64px;
}

.header-mb-sidebar {
    width: 100% !important;
    top: 0px !important;
}

.header-mb-sidebar a {
    font-size: 20px !important;
    color: #000 !important;
}

.header-mb-sidebar li {
    padding: 10px 15px;
}

.header-mb-sidebar a:hover {
    color: #832bad !important;
}

.header-mb-sidebar .p-dialog .p-dialog-header {
    padding: 10px !important;
}

.logoutimg {
    position: absolute;
    top: 20px;
    right: 0
}

.logoutli {
    box-shadow: 0px 1px 4px #00000029;
    padding: 10px !important;
}

.header-mb-sidebar li:hover {
    background-color: #47127D !important;
}

.header-mb-sidebar li:hover span {
    color: #fff;
}

.header-mb-sidebar li:hover>a img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.header-mb-sidebar .req-active:hover>a img {
    /* filter:brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(68%) contrast(94%) !important; */
    filter: invert(57%) sepia(93%) saturate(795%) hue-rotate(17deg) brightness(101%) contrast(102%);
}

.header-mb-sidebar .req-active:hover {
    background-color: #ffef66 !important;
}

.header-mb-sidebar .req-active:hover span {
    color: #ccb700 !important;
}

.header-mb-sidebar .req-active span {
    color: #CCB700 !important;
}

.header-mb-sidebar .p-avatar {
    background-color: #9e97a1 !important;
    color: #000 !important;
    /* font-weight: bold; */
    font-size: 20px !important;
    border-radius: 2% !important;
    padding: 20px 15px !important;
}

.header-mb-sidebar i {
    margin-right: 15px;
    width: 20px;
}

.p-dialog-visible {
    /* display: block !important;
    top: 53px !important; */
    /* left: 20px !important; */
}

.modal1 {
    display: none;
}

#mySidebar {
    display: block;
}

.logo-before-login {
    background: #46127c !important;
    height: 64px !important;
    width: 64px !important;
    display: flex !important;
    align-items: center !important;
    padding: 14px !important;
    justify-content: center !important;
}

.old-header {
    background: rgba(134, 44, 177, 1) !important;
    display: inline-block !important;
    height: 40px !important;

}

.old-header img {
    width: 40px;
    padding: 8px !important;
}

.main-header-before-login {
    padding-left: 0px !important;
}

.avatar-new-header {
    width: 20px !important;
    font-size: 12px !important;
    height: 20px !important;
    padding: 16px !important;
}

.desktopsidebar {
    margin-top: 64px;
}

/* .btn-primary-transperent {
    background-color: rgba(218, 208, 229, 1) !important;
    border-radius: 10px 0px !important;
    padding: 3px !important;
} */

.community-card {
    border: none !important;
}

.community-small-blog {
    min-height: 300px;
    position: relative;
}

.community-bigg-blog {
    min-height: 390px;
}

.community-calendar {
    min-height: 763px;
}

.community-event-btns {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: -3px;
    justify-content: center;
}

.blog-article-card {
    min-height: 270px;
}

.blog-article-card .card-body {
    min-height: 354px;
}

.com-blog-more {
    position: absolute;
    bottom: 0;
    right: 0;
}

.event-card.active {
    border: 2px solid rgba(71, 18, 125, 1) !important;
}

.event-card:hover {
    border: 2px solid rgba(71, 18, 125, 1) !important;
}

.com-event-btn:hover {
    background-color: transparent !important;
    color: rgba(0, 31, 77, 1) !important;
    border: 1px solid rgba(0, 31, 77, 1) !important;
}

.p-datepicker table td>span {
    font-weight: 600 !important;
}

.p-datepicker table td.p-datepicker-today>span {
    background: rgba(71, 18, 125, 1) !important;
    color: #fff !important;
    border-color: transparent !important;
    font-size: 16px !important;
}

.highlight-date {
    border-bottom: 2px solid rgba(71, 18, 125, 1) !important;

}

.p-datepicker table td>span {
    border-radius: 8px !important;
    width: 2rem !important;
    height: 2rem !important;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: rgba(218, 208, 229, 1) !important;
    color: rgba(42, 42, 42, 1) !important;
}

.p-datepicker table th {
    padding: 0.5rem !important;
    color: rgba(154, 154, 154, 1) !important;
    font-weight: 400 !important;
}

.p-datepicker table td>span {
    color: rgba(42, 42, 42, 1) !important;
}

.p-datepicker .p-datepicker-header {
    border-bottom: none !important;
}

.p-datepicker {
    border: none !important;
}

.back-btn-transperent {
    height: 64px;
    width: 64px;
    position: absolute;
    border-bottom-right-radius: 100% !important;
    border-top-left-radius: 0px !important;
    left: 0;
    top: 0px;
    z-index: 2;
}

.btn-primary-transperent {
    /* background-color: rgba(218, 208, 229, 1); */
    border-radius: 10px 0px;
    padding: 3px;
}

.mid-container {
    max-width: 85%;
    margin: 0 auto;
}

.community-search-bar input {
    height: 45px;
    text-indent: 25px;
    border: none;
    border-radius: 8px !important;
    padding: 10px 10px 10px 30px;
}

.community-search-bar {
    box-shadow: 0 0 40px rgb(51 51 51 / 10%) !important;
    background: #fff !important;
    border-radius: 8px !important;
    position: relative !important;
    /* bottom: -31px !important; */
    width: 100% !important;
    z-index: 1 !important;
}

.community-search-bar .fa-search {
    position: absolute !important;
    top: 15px !important;
    left: 27px !important;
}

.community-search-bar .arrow-search {
    position: absolute !important;
    top: 11px !important;
    right: 27px !important;

}

.p-dialog-header {
    text-align: center !important;
}

.p-dialog {
    margin: 0 auto !important;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: rgba(218, 208, 229, 1);
    color: #fff !important;
}

.p-calendar {
    width: 100%;
}

.com-event-btn {
    padding: 0.375rem 0.75rem !important;
}

/*****media query*****/



@media (min-width:1025px) {
    .rightbg-img {
        height: 130px;
    }
    .position-for-btis{
        position: absolute;
        left: 17%;
    }
    /* .req-header .main-header-class{
        width: 490px !important;
    } */
}

@media (max-width: 768px) and (min-width: 768px) {

    .desktopsidebar {

        margin-top: 0px;

    }

}

.hidearrow .p-multiselect .p-multiselect-trigger {
    display: none !important;
}

@media(max-width:1600px) and (min-width:991px) {
    .wf-wrapper {
        min-height: 170vh !important;
    }

    .text-yellow {
        font-size: 13px !important;
    }
}

@media(max-width:2000px) and (min-width:991px) {
    /* .wf-wrapper {
        min-height: 200vh !important;
    } */

    .bs-canvas {
        min-height: 80% !important;
        Overflow: scroll;
        padding-bottom: 57px !important;
    }

    .event-btn-width {
        padding: 6px 4px !important;
        font-size: 16px !important;

    }

    .content-width {
        width: 90% !important;
    }

}

@media(max-width:991px) and (min-width:770px) {

    .badge-success,
    .badge-warning,
    .badge-danger {
        width: unset !important;
        font-size: 10px !important;
    }

    .btn-yellow {
        font-size: 10px !important;
    }

    .text-yellow {
        font-size: 10px !important;
    }

    .admin-card-h {
        height: 480px;
    }

    .adminbtn-position {
        left: 27%;
    }

    .start-service-btn {
        width: unset;
    }

    .state-label {
        display: none;
    }
}

@media(max-width:991px) and (min-width:769px) {
    .community-small-blog {
        min-height: 415px;
    }

    .textlogo1 {
        height: 30px;
    }
    .textlogo-btis{
        height: 30px;
    }
    .mobile-reports {
        display: block;
    }

    .report-div {
        margin-top: 10px;
    }

    .community-buttons {
        top: 90px !important;
    }

    #req-before-login {
        margin-top: 390px !important;
    }

    .lob-modal {
        /* top: 0 !important; */
    }

    .back-btn.article {
        padding: .375rem .75rem !important;
    }

    .p-dialog {
        width: unset !important;
    }

    .blog-article-card {
        min-height: 340px;
    }

    .service-card-height {
        min-height: 185px !important;
    }

    .content-width {
        width: 78% !important;
    }

    .details-content-width {
        width: 70% !important;
    }

    .blog-img {
        height: auto !important;
    }

    .wf-wrapper {
        min-height: 175vh !important;
    }

}

@media(max-width:991px) and (min-width:767px) {
    .bs-canvas {
        min-height: 80% !important;
    }

    .workflow-btn,
    .api-details-btn {
        margin-right: unset !important;
    }

    .service-onboarding-card {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .service-cardd {
        min-height: 0 !important;
    }

    .history-btn {
        font-size: 16px !important;
    }
}

@media (min-width: 992px) {
    .leftbg.login-page {
        width: 15%;
    }

    .header-bar {
        padding: 0px 10px !important;
    }

    .leftbg-img {
        width: 50%;

    }

    .rightbg-img {
        width: 60%;

    }

}

@media (min-width: 320px) and (max-width:375px) {

    .badge-success,
    .badge-warning,
    .badge-danger {
        width: 90px !important;
        font-size: 9px !important;
    }

    .btn-filter {
        height: 30px !important;
        width: 30px !important;
        margin-top: 5px;
    }

    .textlogo {
        width: 70%;
    }

    .ss .slick-prev,
    .ss .slick-next {
        margin: 5px;
    }

    .servicedetailstabs .btn-cmn3,
    .overviewbtn .btn-cmn {
        width: 110px !important;
    }

    .request-header .account-content,
    .request-header .p-inline-message .p-inline-message-text,
    .request-header.p-inline-message .p-inline-message-icon,
    .request-header .p-multiselect-label,
    .request-header .form-control,
    .request-header .account-content b,
    .request-header .account-content textarea,
    .request-header .account-content p {
        font-size: 12px !important;
    }


    /* .logonewheader img{
        width: 95%;
    } */
    .wf-wrapper {
        min-height: 150vh !important;
    }

    .community-buttons .btn-outline-primary,
    .community-buttons .btn-primary {
        width: 100%;
    }
}

@media (min-width: 426px) {
    .mobileViewOfLogo {
        display: none !important;
    }
}

@media (min-width: 426px) and (max-width:767px) {
    .middleheader-div {
        margin-top: 30px;
    }

    img.logo {
        width: 50% !important;
    }

    .midbg-top {
        margin-top: 20px;
    }


    .topcmn {
        padding-top: 83px;
    }

    .longbtn .btn-cmn {
        width: 157px !important;
    }

    .banner-heading {
        /* line-height: 29px !important; */
        font-size: 25px;
    }

    .middle-top {
        padding-top: 45px !important;
    }
}

@media (min-width: 320px) and (max-width:767px) {
    /* .middle-header-width .main-middle-header-div{
        width: 100%;
    } */
    .gig-banner {
        background-image: none !important;
        height: 370px !important;
    }

    .main-banner-height {
        height: 346px !important;
    }

    .main-banner-height .padding-left-30 {
        padding-left: 20px !important;
    }

    .main-banner-height h2 {
        font-size: 28px !important;
    }

    .payment-card {
        height: 243px;
        padding: 5px !important;
    }

    .login-main-div .poweredby-mb-div,
    .forgot-main-div .poweredby-mb-div,
    .setpassword-main-div .poweredby-mb-div,
    .company-main-div .poweredby-mb-div {
        bottom: 10%;
    }

    .powered-by-stripe {
        margin-top: 20px;
    }

    .preview-overview .slick-track {
        width: 1110px !important;
        transform: translate3d(-444px, 0px, 0px) !important;
        z-index: 1;
    }

    .vertafore-submit-btn {
        position: inherit;
    }

    .vertafor-form {
        margin-top: 20px;
    }

    .vertafor-title .text-18 {
        font-size: 14px !important;
    }

    .vertafor-reg-inst .text-20 {
        font-size: 12px !important;
    }

    .vertafor-number {
        font-size: 12px !important;
        height: unset !important;
        padding: 7px 10px;
        width: unset !important;
    }

    .vertafore-powering-title {
        font-size: 20px !important;
    }

    .vertafore-img-div .img-fluid {
        height: 34px !important;
    }

    .ins-text-logo {
        height: 17px !important;
    }

    .ins-logo {
        height: 40px !important;
    }

    .payment-page-card {
        background-size: contain !important;
        height: 100% !important;
        width: 100% !important;
    }

    .review-btn,
    .tile-preview-btn {
        width: 100%;

    }

    .tile-preview-btn {
        margin-bottom: 0px !important;
    }

    .payment-card-number {
        top: 100px !important;
    }

    .payment-card-number h {
        letter-spacing: 7px !important;
    }

    .cardholder-name {
        bottom: 28px !important;
    }

    .cardexpiry-name {
        bottom: 28px !important;
    }

    .btn-danger.delete-card-btn {
        position: inherit !important;
    }

    .setup-service-tabs .nav-pills .nav-link {
        font-size: 9px;
    }

    .setup-service-tabs .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        font-size: 9px;
    }

    .mobile-center h2 {
        text-align: center;
    }

    .apispec-top {
        margin-top: 0 !important;
    }

    .card.community-card.video-card,
    .video-card .card-body {
        min-height: 90px !important;
    }

    .react-video-dialog {
        height: 230px !important;
    }

    .header-mb-sidebar.p-dialog {
        width: 100% !important;
        box-shadow: none !important;
    }

    .header-mb-sidebar.p-dialog {
        top: -34px !important;
    }

    .header-mb-sidebar.p-dialog .p-dialog-content {
        padding: 0px !important;
    }

    .article-prev-next-btn.nxt-btn {
        margin-left: 10px !important;
    }

    .community-btn-filter {
        position: absolute;
        top: 4px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;
        right: 6px;
    }

    .community-event-btns {
        display: block;
    }

    .article-prev-next-btn {

        width: 134px !important;
        padding: 5px !important;
    }

    .video-prevnextbtn-div {
        text-align: center !important;
    }

    .com-event-btn.event-btn {
        margin-left: 0px !important;
        margin-top: 10px;
    }

    /************admin dashboard mobile view***************/
    .service-mb-position {
        bottom: 0px !important;
        top: 20px !important;
    }

    .setup-service-tabs .card-body {
        box-shadow: none !important;
    }

    .poweredby-mb-div {
        margin-top: 77px;
    }

    .mobile-min-h {
        min-height: 100vh !important;
    }

    .mid-bg-center {
        width: 88%;
    }

    .admin-dashboard-position {
        position: inherit;
    }

    .service-dashboard-col span {
        color: #000 !important;
    }

    .badge {
        padding: 4px !important;
        border-radius: 4px !important;
        font-size: 12px !important;
        font-weight: 400 !important;
    }

    .badge-success {
        color: #2FAD87 !important;
        background-color: #EBF4F8 !important;
        border: 1px solid #2FAD87 !important;
    }

    .badge-warning {
        color: #CCB700 !important;
        background-color: #F7F2E8 !important;
        border: 1px solid #CCB700 !important;
    }

    .badge-danger {
        color: #F72A51 !important;
        background-color: #f72a511a !important;
        border: 1px solid #F72A51 !important;
    }


    .mb-view-before-login {
        display: inline-block;
    }

    .before-login-mb-menu ul,
    .before-login-mb-menu {
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
        padding-inline-start: 0px;
    }

    .banner-heading {
        text-align: center;
    }

    .videoDialog {
        /* left: unset !important;
        top: 13% !important; */
    }

    .video-backbtn-div {
        width: 100% !important;
    }

    .desktop-video-title,
    .desktop-video-date {
        display: none;
    }

    .desktop-video-date {
        margin-bottom: 20px;
    }

    .video-title-border {
        border: none !important;
    }

    .video-dialog-date-title-div h2 {
        font-size: 1rem !important;
    }

    .video-dialog-date-title-div {
        margin-left: unset !important;
    }

    .react-video-dialog {
        margin-bottom: 10px !important;
    }

    .mobile-video-date {
        margin-bottom: 30px;
    }

    .bg-none {
        background-color: transparent !important;
    }

    .border-dashed {
        border: 1px dashed #D4D4D4 !important;
    }

    .approval-sent-badge {
        color: #CCB700 !important;
        background-color: #FFFACC !important;
        border: 1px solid #FFE500 !important;
    }

    .admin-d-tabs-mobile .nav-pills {
        background: #F8F8F8 !important;
    }

    .setup-service-tabs.admin-d-tabs-mobile .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        width: 114x !important;
    }

    .setup-service-tabs.admin-d-tabs-mobile .nav-pills .nav-link {
        width: 93px !important;
        font-size: 10px;
        padding: 3px;
    }

    .btn-secondary-tab {
        position: relative !important;
        /* height: 81vh !important; */
        margin-bottom: 20px;
    }

    .btn-secondary-tab .btn-secondary {
        /* position: absolute !important;
    bottom: 20px !important; */
    }

    .saveinfo-btn {
        width: 100%;
    }

    .verifyinfo-btn {
        width: 100%;
    }

    /* .workflow-btn-mobile{
    height: calc(100vh - 20px);
  } */
    .feature-blog-header {
        display: none;
    }

    .history-btn-div {
        display: block;
    }

    .admin-card-h {
        height: unset;
    }

    .account-label {
        color: #000 !important;
    }

    .adminbtn-position {
        left: 25%;
    }

    .adminbtn-position-full {
        left: 25%;
    }

    .p-confirm-popup {
        width: 80% !important;
        /* top: 67% !important;  */
        top: 45% !important;
        left: 10% !important;
    }

    .p-button.p-confirm-popup-accept.p-button-danger,
    .p-button.p-confirm-popup-reject.p-button-text {
        width: 100px !important;
    }

    .history-btn {
        margin-bottom: 20px;
        font-size: 16px !important;
    }

    .mid-container.mid-container-service {
        max-width: 100%;
    }

    .overviewbtn .btn-secondary,
    .submit-buttons .btn-outline-secondary {
        width: 100% !important;
        font-size: 16px !important;
    }

    .btn-okay {
        width: 100% !important;
    }

    .p-dialog-visible {
        /* display: block !important; */
        top: 53px !important;
        /* left: 20px !important; */
    }

    .mid-container {
        max-width: 95%;
    }

    .service-type-mb {
        background-color: #F8F7F7;
        padding: 5px;
        margin: 10px 15px;
        border-radius: 4px;
    }

    .workflow-btn,
    .api-details-btn {
        width: 100% !important;
        margin-top: 5px;
    }

    .gigservice-start {
        margin-top: 0px !important;
    }

    .event-btn-width {
        width: 100%;
        margin-bottom: 10px;
    }

    .before-login-mb-menu {
        display: inline-block;
    }

    .before-login-desktop-menu {
        display: none;
    }

    /* .gig-services{
        min-height: 160px !important; 
    } */
    .index-blog-service,
    .type-img {
        margin-top: 10px;
    }

    .prev-next-btn-div {
        text-align: center;
    }

    .calhideonmb {
        display: none;
    }

    .calhideondesktop {
        display: block;
    }

    .text-block {
        position: unset;
        color: unset;
        padding-left: unset;
        background: none;
        padding-right: unset;
    }

    .article-title-text {
        border-left: none;
        padding-left: unset;
    }

    .article-title-text h2 {
        font-size: 25px;
        margin-top: 5px;
    }

    .atricle-info {
        padding-top: 1rem !important;
    }



    .back-btn {
        margin-bottom: 20px;
    }

    .article-img-position {
        height: 152px !important;
    }

    .blog-img {
        height: auto !important;
    }

    .banner-show-hide {
        display: none;
    }

    .article-top-position {
        top: 0px;
    }

    .text-16 {
        font-size: 12px !important;
    }

    .mb-view-link {
        margin-top: 30px;
        padding-bottom: 40px;
    }

    .mb-view-link {
        display: flex !important;
    }

    .community-position {
        bottom: unset !important;
        /* margin-top: 100px; */
    }

    .explore-card,
    .article-top-position {
        margin-top: 20px;
    }

    .blog-btn-mb,
    .back-btn {
        width: 100%;
    }

    .footer-content1,
    .footer-content2 {
        text-align: center !important;
    }

    button.p-scrolltop.p-link.p-component.scroll-top.p-scrolltop-enter-done {
        z-index: 998 !important;
    }

    .intercom-lightweight-app,
    .intercom-lightweight-app-launcher {
        z-index: 998 !important;
    }

    /* .main-header-before-login {
        display: none !important;
    } */
    /* .mobileview-header{
      display: block !important;
  } */
    .mobileview-hamburger {
        display: inline-block !important;
        padding: 0.5rem 1rem;
        /* z-index: 999; */
    }

    /* .start-service-btn {
        display: none !important;
    } */

    .mid-sec {
        width: 100% !important;
    }

    .displayformobile {
        display: block !important;
    }
    .pf-about-main .displayformobileview{
        display: inline-block !important;
    }
    .displayfordesktop {
        display: none !important;
    }
    .pf-about-main .displayfordesktopview {
        display: none !important;
    }
    .desktopsidebar {
        display: none !important;
    }

    .rightbg {
        display: none
    }

    .companybtn {
        text-align: right;
    }

    .companybtn .btn-cmn3 {
        font-size: 1rem !important;
        width: 157px !important;
        text-align: center;
        margin: 0 !important;
        position: relative !important;
        top: 0% !important;
        right: 0 !important;
    }

    /* .banner-heading {
        line-height: 29px !important;
        font-size: 20px;
    }
    .middle-top{
        padding-top: 20px !important;
    } */
    .search-bar {
        position: relative !important;
        bottom: 30px !important;
    }

    .sub-heading,
    .sub-heading2 {
        padding-top: 0px !important;
    }

    .setup-service-tabs .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        height: 37px;
    }

    .setup-service-tabs .nav-pills .nav-link {
        height: 37px;
    }

    .padding-left-right {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .card-body-padd {
        padding: 5px !important;
    }

    .company-logo-align {
        display: block !important;
    }

    .companylogo-mobile {
        justify-content: center;
    }

    .com-logo-mobile {
        display: flex;
        justify-content: center;
    }

    .service-position.company-position {
        top: -3px;
    }

    .workflow-mobile-position {
        bottom: 0px;
        top: 11px;
    }

}

.mobileview-header {
    display: none !important;
}

@media (min-width: 374px) and (max-width:400px) {
    .company-name-text{
        font-size: 8px !important;
    }
    .login-main-div  .poweredby-mb-div {
        bottom: 0 !important;
    }
    .poweredby-mb-div {
        bottom: 85px !important;
    }
}

@media (min-width: 376px) and (max-width:767px) {
    .servicedetailstabs .btn-cmn3 {
        width: 157px !important;
    }

    .wf-wrapper {
        min-height: 180vh !important;
    }
}

@media (min-width: 320px) and (max-width:768px) {
    .usage-report-mobile {
        position: relative;
        bottom: 120px;
        text-align: center;
    }

    .p-dialog {
        width: unset !important;
    }

    .content-width {
        width: 78% !important;
    }

    img.logo {
        width: 70%;
        padding-left: 10px;
    }

    .liForMob {
        list-style-type: none;
    }

    .details-bg.gig-services {
        background-image: none;
    }

    .service-details.mobile-service-bg {
        background-color: rgba(71, 18, 125, 1);
        padding: 15px;
        margin-top: 0px !important;
    }

    .service-detailes-title {
        font-size: 16px;
    }

    .tag-para-height {
        font-size: 12px;
    }

    .service-type-mb {
        font-size: 12px;
    }

    .detail-service-type {
        font-size: 12px;
    }
}

@media (min-width:426px) {
    .Dashboardcard {
        margin-top: 20px;
    }
}

@media (min-width: 375px) and (max-width:426px) {
    .company-name-text{
        font-size: 9px;
    }
    .midbg-top {
        margin-top: 20px;
    }

    .textlogo {
     width: auto;
     height: 14px;
    }

    .middle-top {
        padding-top: 30px !important;
    }

    img.logo {
        width: 70%;
    }
}

@media (min-width: 320px) and (max-width:426px) {
    .middle-header-position {
        /* margin-top: unset; */
    }
    .p-panel.p-component.p-panel-toggleable{
        margin: 5px 10px !important;
    }
    .profile-nav-link {
        padding: 0px !important;
    }
    .about-pf-btn img{
        height: 26px !important;
    }
    .single-acc-user .custom-switch {
        padding-left: 0px !important;
    }

    .policy-p {
        margin-top: 10px;
    }

    .text-block {
        top: 48%;
    }

    .search-filter-items {
        position: absolute !important;
        right: 0 !important;
    }

    .serach-filter-for-desktop {
        display: none;
    }

    .topcmn {
        margin-top: unset;
        padding-top: 50px;
    }

    /* .wf-header {
        margin-top: unset;
    } */
    i.fa.fa-search.pl-3,
    i.fa.fa-question-circle-o.pl-3 {
        display: none;
    }

    .desktopViewofLogo {
        display: none !important;
    }

    .wfcontiner {
        margin-top: 1.5rem;
    }

    .middleheader-div {
        padding-top: 40px;
    }

    /* .desktopcard {
        display: none !important;
    } */

    .mobilecard {
        /* display: block !important; */
        min-height: 100px;
    }

    .card-mb {
        min-height: 150px !important;
    }

    .account-content {
        padding: 10px !important;
    }

    .cardfordesktop {}

    .cardformobile {
        display: none;
    }

    .longbtn {
        text-align: right;
    }

    .companybtn .btn-cmn3 {
        margin-left: 20px !important;
        margin-right: 5px !important;

    }

    .longbtn .btn-cmn {
        width: 157px !important;
        margin-left: 20px;

    }



    .pricingheight {
        min-height: 200px;
    }

    .mid3 {
        min-height: 400px;
    }

    .displayformobile {
        display: block;
        right: 0;
    }
    .pf-about-main .displayformobileview{
        display: inline-block !important;
    }
    .card {
        margin-bottom: 20px;
    }

    .displayfordesktop {
        display: none;
    }
    .pf-about-main .displayfordesktopview {
        display: none !important;
    }
    .header-div1 {
        margin-right: 5px !important;
    }

    .mobile-header-logo {
        background-color: rgba(134, 44, 177, 1);
        padding: 5px 10px;
    }

    /* .mobileview-header{
        display: block !important;
    } */
    .desktopview-header {
        display: none !important;
    }

    .main-header-new {
        height: unset !important;
        padding: 0px;
    }

    .user-image {
        width: unset !important;
        border-radius: 4px !important;
    }

    a.arrow-search {
        display: none;
    }

    .search-bar button {
        right: 5px !important;
    }

    input#example-search-input {
        font-size: 12px !important;
    }

    .search-bar input {
        text-indent: 17px !important;
    }

    .modal1.left {
        left: 30px !important;
        top: 54px !important;

    }

    .clearbtn,
    .filterserachbtn {
        width: 100%;
        margin-bottom: 10px;

    }

    .modal1.left {
        left: 0px !important;
        top: 74px !important;

    }

    .modal-dialog {
        width: 100% !important;
    }

    .modal1 {
        display: block;
    }

    #mySidebar {
        display: none;
    }

    .logo-before-login {
        height: 45px !important;
        width: 55px !important;
        padding: 10px !important;

    }
}


@media only screen and (max-width: 600px) {
    .mid-sec {
        margin-left: 0px;
        width: 300px;
    }

    .mid2 {
        height: 860px;
    }
}

/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) {
    /* #sidebar {
        z-index: auto;
    } */

    .header-bar {
        padding: 10px 10px;
    }
}

.card-box-view {
    padding: 10px;
    background: #FBFBFA;
    border-radius: 5px;
    border: 0.5px solid #C1C1C1;
    margin-bottom: 20px;
}

h5.top-head {
    font-size: 15px;
    font-weight: 700;
    color: #6F2D80;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
}

h5.top-head span {
    font-weight: 400;
    color: #8D8D8D;
    margin-right: 10px;
}

.mid-container {
    /* min-height: 701px; */
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
}

.table tr:last-child {
    border-bottom: 2px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 1px !important;
}

.table-fixed {
    table-layout: fixed;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
}

.table tr:last-child {
    border-bottom: 2px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 1px !important;
}

.table-fixed {
    table-layout: fixed;
}


.table-fixed tbody {
    height: 300px;
    overflow-y: auto;
    width: 100%;
}


/* Postcard Start */
.postcard-body {
    box-shadow: 0px 3px 6px #00000029;
    padding: 27px 25px;
    border-radius: 4px;
    text-align: start;
}

.postcard-body h5 {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 4px;
}

.header-bottom {
    border-bottom: 1px solid #0000001c;
}

.postcard-top {
    /* margin-top: 50px; */
    padding-bottom: 25px;
    padding-top: 25px;
}

.postcard-top h4 {
    padding-bottom: 25px;
    font-size: 24px;
    font-weight: 600;
}

.postcard textarea {
    resize: inherit;
    height: 171px !important;
}

.post-btn-size {
    padding: 7px 20px !important
}

.post-btn-size:hover {
    padding: 7px 20px !important
}

.thankyou-front {
    background-color: #A1D1F7;
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thankyou-front h1 {
    border: 3px solid #fff;
    padding: 109px;
    color: #fff;
}

.thankyou-back {
    background-color: #A1D1F7;
    width: 100%;
    height: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thankyou-back h1 {
    color: #fff;
}

.submit-buttons-post .btn {
    padding: 7px 20px !important;
    font-size: 13px !important;
    border-radius: 4px !important;
}

.primary-input-bg {
    background-color: #C4D8F5 !important;
    border: 1px solid #C4D8F5 !important;
    color: #001F4D !important;
    font-size: 12px !important;
}

.primary-input-bg.form-control::placeholder {
    color: #001F4D !important;
}

.modal-postcard .file {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
}

.modal-postcard .file input {
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
    padding-bottom: 0px;
}

.postcard-top .select-form-control {
    color: #8D8D8D !important;
}

.modal-postcard .file-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2rem;
    padding: 0.3rem 1rem;
    line-height: 1.5;
    color: #C5C5C5;
    background-color: #F3E7F6;
    border: 0.075rem solid #F3E7F6;
    border-radius: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-weight: 400;
}

.modal-postcard .file-custom:before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: none;
    content: "Browse";
    height: 2rem;
    padding: 0.3rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
}

.modal-postcard .file-custom:after {
    content: "Darg and drop field here";
}

.muted-input-bg {
    font-size: 12px !important;
    background-color: #F6F6F6 !important;
    border: 1px solid #F6F6F6 !important;
}

.text-start {
    text-align: start !important;
}




@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.btn-secondary {
    background-color: rgba(71, 18, 125, 1) !important;
    border-color: rgba(71, 18, 125, 1) !important;
    color: #fff !important;
    border-radius: 5px;
    font-size: 14px;
    padding: 0.375rem 0.75rem !important;
}

.btn-secondary:hover {
    background-color: #fff !important;
    border-color: rgba(71, 18, 125, 1) !important;
    color: rgba(71, 18, 125, 1) !important;
    border-radius: 5px;
}

.back-btn.article {
    background: #47127D;
    padding: 5px 28px;
    border: 1px solid #47127D;
    color: #fff;
    font-size: 16px;
}

.back-btn.article:hover {
    background: transparent;
    color: #47127D;
}

.btn-rounded {
    border-radius: 40px !important;
}

.btn-outline-primary {
    background-color: transparent;
    border: 1px solid #001F4D !important;
    color: #001F4D !important;
}

.btn-outline-primary:hover {
    background-color: #001F4D !important;
    color: #fff !important;
    border-color: #001F4D !important;
}

/* .btn-outline-secondary {
    color: gray;
    border-color: #d6d1d1;
} */

.text-muted-color {
    color: #8D8D8D !important;
}

.text-secondary-color {
    color: #6F2D80 !important;
}

.text-secondary- {
    color: #47127d !important;
}

.form-control::placeholder {

    color: #8D8D8D !important;
    opacity: 1;
}

.postcard-top form label {
    color: #8D8D8D !important;
    font-size: 17px !important;
    font-weight: 400 !important;
}

.boxHeight {
    height: 100% !important;
    min-height: 297px !IMPORTANT;
}

.thankyou-front h1 {

    border:
        3px solid #fff;
    padding:
        74px;
    color: #fff;
    font-size: 87px;
}

.thankyou-back h1 {

    color: #fff;
    font-size: 47px;
}

.modal-postcard .btn-x {
    position: absolute;
    left: auto;
    right:
        9px;
    top:
        0px;
    color: #000;
    opacity: 1;
    font-size: 32px;
}

/* 
.tryit-title {
    text-align: center !important;
    justify-content: center !important;
    flex: auto !important;
} */
.btn-muted {
    background-color: #6c757d !important;
    color: #fff !important;
    border-color: #6c757d !important;
}

.btn-outline-muted {
    border-color: #6c757d !important;
    color: #6c757d !important;
    background-color: transparent !important;
}

.btn-outline-muted:hover {
    border-color: #6c757d !important;
    color: #fff !important;
    background-color: #6c757d !important;
}

@media (min-width:810px) and (max-width:1080px) {
    .submit-buttons-post .btn {
        padding: 5px 4px !important;
        font-size: 14px !important;
        margin-top: 24px;
    }
}

@media (min-width:769px) and (max-width:770px) {
    img.logo {
        width: 70%;
        padding-left: 10px;
    }

    .gig-banner,
    .main-banner-height {
        height: 273px !important;
    }

    .account-label {
        color: #000 !important;
    }

    .adminbtn-position-full {
        left: 34%;
    }

    .text-block {
        top: 60% !important;
        height: 152px !important;
    }

    .com-event-btn,
    .event-btn-width {
        font-size: 1rem !important;
        width: 100% !important;
    }

    .article-prev-next-btn,
    .back-btn.article {
        font-size: 13px !important;
    }

    .service-dashboard-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .community-event-name {
        font-size: 14px !important;
    }

    .event-link-col {
        padding: unset !important;
    }

    .p-dialog {
        width: 100% !important;
    }

    .blog-img {
        height: 100px !important;
    }

    .p-datepicker table {
        font-size: 10px !important;
    }

    .comm-cancel-btn {
        padding: 0.375rem 0.75rem !important;
    }

    #mySidebar h3 {
        font-size: 25px;
    }

    .wf-wrapper {
        min-height: 180vh !important;
    }
}

@media (min-width:770px) {
    .explore-btn-pos {
        position: absolute !important;
        bottom: -17px !important;
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

@media (min-width:767px) and (max-width:769px) {
    .filter-btns {
        font-size: 13px !important;
    }

    .p-confirm-popup {
        width: 40% !important;
    }

    .community-buttons {
        top: 90px !important;
    }

    .blog-article-card {
        min-height: 330px;
    }

    .community-small-blog {
        min-height: 420px;
    }

    .submit-buttons-post .btn {
        width: 100% !important;
        margin-bottom: 5px;
        margin-left: 0px !important;
    }

    .text-block {
        top: 60% !important;
        height: 152px !important;
    }

    .topcmn {
        margin-top: unset;
    }

    .community-mid-container {
        max-width: 96% !important;
    }

    .community-calendar {
        padding: 4px !important;
    }

}

@media (min-width:320px) and (max-width:768px) {
    .modal-postcard .btn-x {
        right: 6px !important;
        top: -2px;
    }

    #mySidebar h3 {
        font-size: 25px;
    }

    /* .main-header-new.main-header-before-login{
        display: none
    } */
    .mobileheader {
        display: block;
    }

    .bg-community-banner {
        margin-top: 0px !important;
    }

    .mobileheader {
        height: 64px !important;
        display: block !important;
    }

    .mobileheader .row {
        height: 64px;
        align-items: center;
    }

}

.Validations {
    background: transparent;
    padding: 1rem;
    border: 0 none;
    color: #f10c0c;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-left: -20px;
}

.text-16 {
    font-size: 16px;
}

.text-12 {
    font-size: 12px !important;
}

.font-weight-600 {
    font-weight: 600;
}

.text-secondary-transperant {
    color: rgba(255, 255, 255, 0.5);
}

.primary-colored {
    filter: brightness(0) saturate(100%) invert(11%) sepia(56%) saturate(2568%) hue-rotate(201deg) brightness(87%) contrast(106%);
}

#sidebar-new.active li>a img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(56%) saturate(2568%) hue-rotate(201deg) brightness(87%) contrast(106%);
}

#sidebar-new ul li.req-active>a {
    background-color: #FFEF66;
}

#sidebar-new ul li.req-active>a span {
    color: #CCB700 !important;
}

#sidebar-new ul li.req-active>a img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(82%) contrast(86%) !important;
}

#sidebar-new {
    min-width: 240px !important;
    max-width: 240px !important;
    background: #fff !important;
    color: rgba(0, 31, 77, 1) !important;
    -webkit-transition: all 0.3s !important;
    -o-transition: all 0.3s !important;
    transition: all 0.3s !important;
    z-index: 4;
}

.mid-container-new {
    max-width: 85%;
    margin: 0 auto;
}

/* 
button,
input,
optgroup,
select,
textarea {
    font-family: 'Poppins', sans-serif;
} */

.banner-heading {
    font-weight: 600;
    line-height: 40px;
}

.user-image {
    height: 32px;
    width: 32px;
    border-radius: 8px;
}

#sidebar-new.active {
    min-width: 64px !important;
    max-width: 64px !important;
    text-align: center !important;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05) !important;
    background: rgba(134, 44, 177, 0.1);
    color: rgba(134, 44, 177, 1);
    z-index: 4;
}

#sidebar-new ul li.active>a img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(29%) saturate(4759%) hue-rotate(264deg) brightness(103%) contrast(100%);
}

#sidebar-new ul li a img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(56%) saturate(2568%) hue-rotate(201deg) brightness(87%) contrast(106%);
    height: 19px;
}

#sidebar-new.active ul.components li {
    font-size: 14px;
}

#sidebar-new.active ul.components li a i {
    font-size: 16px;
}

#sidebar-new.active ul.components li a span {
    margin-right: 0;
    font-size: 24px;
}

.sidebar-logo {
    display: flex;
    align-items: center;
}

.logo-text-side {
    margin-left: -20px;
}

#sidebar-new.active .logo {
    background: rgba(134, 44, 177, 1) !important;
    height: 64px !important;
    width: 64px !important;
    display: flex !important;
    align-items: center !important;
    padding: 14px !important;
    justify-content: center !important;
}

#sidebar-new.active .logoname {
    display: none;
}

#sidebar-new.active .footer {
    display: none;
}

#sidebar-new .logo {
    color: #fff !important;
    font-weight: 900 !important;
    transition: 0.3s !important;
    background: rgba(134, 44, 177, 1) !important;
    height: 64px !important;
    width: 64px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 14px !important;

}

#sidebar-new .logo img {
    width: 33px;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar-new .logo {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar-new ul.components {
    padding: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar-new ul.components {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar-new ul li {
    font-size: 14px;
}

#sidebar-new ul li>ul {
    margin-left: 10px;
}

#sidebar-new ul li>ul li {
    font-size: 14px;
}

#sidebar-new ul li a {
    padding: 10px 30px;
    display: block;
    color: rgba(0, 31, 77, 1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

#sidebar-new ul li a span {
    margin-right: 15px;
}

#content-new {
    width: 100%;
    padding: 0;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    overflow-x: hidden;
    /* z-index: 9; */
}
@media (max-width: 992px) and (min-width:320px) {
    .pf-reg-chrome-btn button{
          width: 100% !important;
    }
    .login-mid-container{
        min-height: 65vh !important;
      }
      .social-button-main-div{
        width: 100% !important;
      }
}
@media (max-width: 991.98px) {
    #sidebar-new ul li a span {
        display: block;
    }
}

#sidebar-new ul li a:hover {
    color: rgba(205, 73, 73, 1);
    /* color: #fff; */
    text-decoration: none;
}

#sidebar-new ul li.active>a span {
    color: #fff !important;
}


.header-mb-sidebar a:hover img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(29%) saturate(4759%) hue-rotate(264deg) brightness(103%) contrast(100%);
}

#sidebar-new.active li.active>a {
    justify-content: center;
}

#sidebar-new.active li>a {
    justify-content: center;
}

#sidebar-new ul li>a span {
    padding-left: 24px;
}

#sidebar-new ul li.active>a {
    color: #fff !important;
    background-color: #46127c;
}

#sidebar-new ul li:hover>a {
    color: #fff !important;
    background-color: #46127c;
}

#sidebar-new ul li.req-active>a {
    background-color: #FFEF66;
}

#sidebar-new ul li.req-active>a span {
    color: #CCB700 !important;
}

#sidebar-new ul li.req-active>a img {
    filter: brightness(0) saturate(100%) invert(68%) sepia(21%) saturate(6855%) hue-rotate(21deg) brightness(107%) contrast(101%) !important;
}

#sidebar-new ul li.req:hover>a {
    background-color: #FFEF66;
}

#sidebar-new ul li.req:hover>a span {
    color: #CCB700 !important;
}

#sidebar-new ul li.req:hover>a img {
    filter: brightness(0) saturate(100%) invert(68%) sepia(21%) saturate(6855%) hue-rotate(21deg) brightness(107%) contrast(101%) !important;
}

#sidebar-new ul li.active>a img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

#sidebar-new ul li:hover>a img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.sidebarautorizeitem {
    background: rgba(134, 44, 177, 0.1);
    color: rgba(134, 44, 177, 1);
}

#sidebar-new ul li.active>a span {
    padding-left: 24px;
}

#sidebar-new.active .components li a span {
    display: none;
}

@media (max-width: 991.98px) {
    /* #sidebar-new {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    } */

    #sidebar-new.active {
        margin-left: 0 !important;
    }
}

#sidebar-new.active .btn-collapse {
    position: absolute;
    left: 53px;
    top: 69px;
    transition: 0.3s;
    color: #fff;
}

#sidebar-new .btn-collapse {
    position: absolute;
    top: 69px;
    left: 225px;
    transition: 0.3s;
    color: #fff;
}

.wf-wrapper {
    display: flex;
    min-height: 120vh;
}

.wf-row {
    margin-left: 3px !important;
}

.header-position {
    position: fixed;
    z-index: 9;
    top: 0;
}

.header-btn {
    background: rgba(205, 73, 73, 1) !important;
    border: rgba(205, 73, 73, 1) !important;
    height: 24px !important;
    width: 24px !important;
    padding: 0 !important;
    border-radius: 25px !important;

}

.main-header-new {
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 5%);
}

.bg-img-banner {
    background-image: linear-gradient(#1c1b54, #aa31d1);
    background-position: left bottom, right top;
    height: 200px;
}

.content-new .img-2 img {
    position: absolute;
    left: auto;
    right: 0px;
    top: 65px;
}

.content-new .img-1 img {
    position: absolute;
    top: 97px;
}

.main-header-new .dropdown-menu {
    min-width: 175px;
    padding: 7px 0px;
}

.main-header-new .dropdown-menu .dropdown-item {
    padding: 6px 14px;
    font-size: 12px;
    font-weight: 400;
}

.main-header-new .dropdown-menu .dropdown-item {
    color: rgba(0, 31, 77, 1);
}

.main-header-new .dropdown-menu .dropdown-item:hover {
    color: rgba(205, 73, 73, 1);
    background: transparent;
}

.main-header .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}

.search-bar {
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    background: #fff;
    border-radius: 8px;
    position: absolute;
    bottom: -31px;
    width: 98%;
    z-index: 1;
}

.search-bar input {
    height: 64px;
    text-indent: 25px;
    border: none;
    border-radius: 8px !important;
    padding: 10px 10px 10px 30px;
}

.search-bar input:focus {
    box-shadow: none;
    border: 2px solid #fff;
    outline: none;
}

.search-bar .fa-search {
    position: absolute;
    top: 24px;
    left: 27px
}

.search-bar button {
    position: absolute;
    top: 12px;
    right: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-filter {
    height: 36px;
    width: 36px;
    background-color: rgba(243, 234, 247, 1);
    border-radius: 8px;
}

.btn-filter img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(29%) saturate(4759%) hue-rotate(264deg) brightness(103%) contrast(100%);
}

.btn-filter:hover {
    background-color: rgba(134, 44, 177, 1);
}

.btn-filter:hover img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.arrow-search {
    position: absolute;
    top: 18px;
    right: 27px;
}

.arrow-search .img-primary {
    filter: brightness(0) saturate(100%) invert(22%) sepia(29%) saturate(4759%) hue-rotate(264deg) brightness(103%) contrast(100%) !important;
}

.search-bar .form-control::placeholder {
    color: rgba(171, 171, 171, 1);
    opacity: 50%;
}

.gigservice-start {
    margin-top: 50px;
}

.gig-services {
    background-color: #fff;
    box-shadow: 0 0 20px rgb(51 51 51 / 10%);
    padding: 16px;
    border-radius: 8px;
    margin-top: 10px;
}

.service-card-height {
    min-height: 230px;
}

.gig-services .service-title .icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gig-services .service-title .icon .icon-img {
    height: 24px;
    width: 24px;
    font-size: 7px;
    /* filter: brightness(0) saturate(100%) invert(91%) sepia(0%) saturate(7464%) hue-rotate(99deg) brightness(112%) contrast(108%); */
}

.gig-services .service-title .icon.orange {
    background-color: rgba(249, 115, 22, 1);
}

.gig-services .service-title .icon.blue {
    background-color: rgba(22, 140, 249, 1);
}

.gig-services .service-title .icon.green {
    background-color: rgba(117, 183, 107, 1);
}

.gig-services .service-title .icon.red {
    background-color: rgba(202, 99, 99, 1);
}

.gig-services .service-title .icon.light-orange {
    background-color: rgba(238, 196, 134, 1);
}

.gig-services .service-title .icon.dark-blue {
    background-color: rgba(82, 131, 159, 1);
}

.gig-services .service-title .icon.gray {
    background-color: rgba(114, 106, 100, 1);
}

.gig-services .service-title .icon.blue1 {
    background-color: rgba(79, 103, 231, 1);
}

.gig-services .service-title .icon.light-green {
    background-color: rgba(184, 217, 114, 1);
}

.gig-services .service-title .icon.light-pink {
    background-color: rgba(207, 124, 215, 1);
}

.gig-services .service-title .icon.light-blue {
    background-color: rgba(144, 178, 229, 1);
}

.gig-services .service-title .icon.green1 {
    background-color: rgba(111, 200, 162, 1);
}

.gig-services .service-title .service-title-text {
    line-height: 20px;
    font-weight: 600;
    color: rgba(87, 87, 87, 1);
}

.gig-service-main-title {
    font-weight: bold;
    margin-bottom: 0px;
    line-height: 30px;
    color: rgba(87, 87, 87, 1);
    margin-top: 14px;
}

.services-desc {
    padding-top: 12px;
    padding-bottom: 12px;
}

.badge-primary {
    color: #fff;
    background-color: rgba(134, 44, 177, 1);
    padding: 3px 6px 3px;
    font-weight: 400;
}

.modal1.left {
    position: fixed;
    top: 64px;
    left: 64px;
}

.modal-backdrop {
    background-color: transparent;
}

.modal1.left .modal-dialog,
.modal1.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 364px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal1.left .modal-content,
.modal1.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal1.left .modal-body,
.modal1.right .modal-body {
    padding: 15px 15px 80px;
}

/*Left*/
.modal1.left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal1.left.fade.in .modal-dialog {
    left: 0;
}

/*Right*/
.modal1.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal1.right.fade.in .modal-dialog {
    right: 0;
}

.btn-bg-close {
    border: 2px solid rgba(205, 73, 73, 1) !important;
    /* background: rgba(205, 73, 73, 0.1) !important; */
    /* height: 32px;
    width: 32px; */
    color: rgba(205, 73, 73, 1);
    opacity: 1;
    border-radius: 8px;
    display: flex !important;
}

.close {
    opacity: 1 !important;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal1 {
    z-index: 999;
}

.modal-content {
    border: none !important;
}

.modal-header {
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
}

.form-group label {
    font-size: 12px;
    color: rgba(87, 87, 87, 1);
    /* font-weight: bolder; */
    margin-bottom: 4px;
}

.form-group {
    margin-bottom: 24px;
}

.form-control {
    font-size: 12px !important;
    /* border: 1px solid rgb(232 231 231) !important;  */
}

.form-control-box {
    height: 32px;
    width: 100%;
    background-color: #F8F7F7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}

.custom-control-input-success:checked~.custom-control-label::before {
    background-color: #2CB28A !important;
    border-color: #2CB28A !important;
}

.custom-control-label::before {
    background-color: rgba(205, 73, 73, 1);
    border: rgb(205 73 73) solid 1px;
}

.custom-switch .custom-control-label::after {
    background-color: #fff;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 36px;
    height: 20px;
    pointer-events: all;
    border-radius: 1.5rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.25rem + 4px);
    left: calc(-1.1rem + -11px);
}

.btn-outline-primary {
    border: 2px solid rgba(0, 31, 77, 1);
    padding: 8px 32px;
    color: rgba(0, 31, 77, 1);
    border-radius: 4px;
    font-size: 16px;
}

.btn-outline-primary:hover {
    background-color: rgba(0, 31, 77, 1);
    color: #fff;
    border: 1px solid rgba(0, 31, 77, 1);
}

.btn-primary {
    border: 2px solid rgba(0, 31, 77, 1);
    padding: 8px 32px;
    background-color: rgba(0, 31, 77, 1);
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
}

.btn-primary:hover {
    background-color: transparent;
    color: rgba(0, 31, 77, 1);
    border: 2px solid rgba(0, 31, 77, 1);
}

.img-primary {
    filter: brightness(0) saturate(100%) invert(11%) sepia(56%) saturate(2568%) hue-rotate(201deg) brightness(87%) contrast(106%);
    height: 19px;
}

.main-header .dropdown-menu .dropdown-item:hover .img-primary {
    filter: brightness(0) saturate(100%) invert(36%) sepia(12%) saturate(2854%) hue-rotate(313deg) brightness(109%) contrast(100%);
}

/************Tile Details Screen**************/

.tile-details .gig-services {
    box-shadow: none;
}

.img-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7454%) hue-rotate(302deg) brightness(115%) contrast(92%) !important;
    height: 20px;
}

.tile-details .start-workflow:hover .img-white {
    filter: brightness(0) saturate(100%) invert(14%) sepia(93%) saturate(845%) hue-rotate(186deg) brightness(91%) contrast(114%);
}

.img-primary {
    filter: brightness(0) saturate(100%) invert(14%) sepia(93%) saturate(845%) hue-rotate(186deg) brightness(91%) contrast(114%);
    height: 20px;
}

.tile-details .api-details:hover .img-primary {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(242deg) brightness(103%) contrast(102%);
}

.start-workflow {
    height: 32px;
    padding: 0px 40px;
    font-size: 16px;
}

.api-details {
    height: 32px;
    padding: 0px 40px;
    font-size: 16px;
}

.image-style {
    position: absolute;
    top: 0px;
    right: 0px;
}

.card-shadow {
    border: none;
    box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 5%);
    margin-bottom: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: rgba(0, 31, 77, 1);
    width: 112px;
    font-size: 12px;
    text-align: center;
}

.company-profile-tab {
    border: 4px solid #DDDDDD;
    border-radius: 4px;
    width: 50%;
}

.nav-pills .nav-link {
    border-radius: 4px;
    color: rgba(0, 31, 77, 1);
    width: 112px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
}

.images-videos {
    font-size: 24px;
    font-weight: 600;
}

.badge-secondary {
    background-color: #001F4D;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 4px 7px;
    margin-bottom: 5px;
}

.hr-line {
    height: 2px;
    width: 300px;
    background-color: rgba(232, 231, 231, 1);
    margin-top: 20px;
    margin-bottom: 20px;
}

.tags-heading {
    font-size: 12px;
    font-weight: 600;
}

.website-link {
    color: rgba(87, 87, 87, 1);
    font-weight: 600;
    font-size: 12px;
}

.website-link:hover {
    text-decoration: none;
    color: rgba(87, 87, 87, 1);
}

.service-title {
    padding-right: 0px;
}

.tile-details .nav {
    background: rgba(246, 245, 245, 1);
    width: max-content;
    border-radius: 4px;
    padding: 5px;
}

.tile-details .nav .nav-item {
    background: #fff;
    border-radius: 4px;
    margin-right: 4px;
}

.filterswitch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #2CB28A !important;

}

.filterswitch.p-inputswitch .p-inputswitch-slider {
    background-color: rgba(205, 73, 73, 1) !important;
}

.filterswitch.p-inputswitch {
    display: block !important;
}

/***********Hawksoft*************/
.header-sec {
    height: 64px;
    display: flex;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);

}

.header-sec .logo-sidebar {
    height: 64px;
    width: 64px;
    background-color: rgba(134, 44, 177, 1);
}

.logo-text-sidebar {
    width: 200px;
    margin-left: -20px;
}

.banner-sec {
    display: flex;
    width: 100%;
    height: 192px;
    left: 0px;
    top: 64px;
    background: linear-gradient(159.88deg, #1C1B54 -110.57%, #AA31D1 104.55%);
    align-items: center;
    justify-content: center;
}

.hawksoft-logo {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    padding: 7px;
}

.hawksoft-text {
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    padding-left: 7px;
}

.image-1 {
    position: absolute;
    top: 64px;
    right: 0px;
}

.image-2 {
    position: absolute;
    top: 75px;
    left: 0px;
}

.h-title {
    color: rgba(87, 87, 87, 1);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.btn-action {
    width: 164px;
    height: 32px;
    padding: 0px;
}

.btn-outline-primary:hover .img-primary {
    filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(16%) hue-rotate(100deg) brightness(105%) contrast(107%);
}

.action-dropdown .dropdown-item {
    font-size: 12px;
    color: #001F4D;
    font-weight: 400;
}

.btis-logo {
    width: 35px;
}

.btn-action-arrow {
    padding: .375rem .75rem;
}

.clearbtn {
    border: 2px solid rgba(0, 31, 77, 1) !important;
    padding: 8px 32px !important;
    color: rgba(0, 31, 77, 1) !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    margin-right: 5px;
}

.filterserachbtn {
    border: 2px solid rgba(0, 31, 77, 1) !important;
    padding: 8px 32px !important;
    background-color: rgba(0, 31, 77, 1) !important;
    color: #fff !important;
    border-radius: 4px !important;
    font-size: 16px !important;
}

.filterserachbtn:hover {
    background-color: transparent !important;
    color: rgba(0, 31, 77, 1) !important;
    border: 2px solid rgba(0, 31, 77, 1) !important;
}

button#sidebarCollapse {
    z-index: 7;
}

.img-danger {

    height: 18px;

    filter: brightness(0) saturate(100%) invert(32%) sepia(26%) saturate(1404%) hue-rotate(314deg) brightness(121%) contrast(97%);

}

.search-filter-items {
    position: absolute;
    right: 53px;
    top: 3px;
}

.badge-transparent-success {
    background-color: #D5EFE7;
    color: rgba(38, 138, 108, 1);
}

.filter-applied {
    transform: rotate(270deg);
    -webkit-transform-origin: left top 0;
    transform-origin: left top 0;
    background: #2fad87;
    color: #fff;
    margin-left: 40px;
    padding: 2px 47px;
    border: 1px solid #2fad87;
    box-shadow: 2px -2px 0px rgb(0 0 0 / 10%);
    float: left;
    position: absolute;
    border-radius: 0px 0px 10px 10px;
    font-size: 12px;
    top: 128px;
    left: -149px;
}

.btn-filter-success {
    background-color: rgba(47, 173, 135, 1) !important;
    border: 1px solid rgba(47, 173, 135, 1) !important;
    color: #fff !important;
    height: 36px;
    width: 36px;
    border-radius: 8px;
}

.btn-filter {

    height: 36px;

    width: 36px;

    background-color: rgba(243, 234, 247, 1) !important;

    border-radius: 8px;

}

/* nav.main-header-new.container-fluid {
    position: fixed;
    z-index: 3;


/**********Community*************/
.content .header-design-2 img {
    position: absolute;
    top: 84px;
    height: 177px;
}

.content .header-design-1 img {
    position: absolute;
    left: auto;
    right: 0px;
    height: 177px;
    top: 65px;
}

.bg-community-banner {
    height: 200px;
    /* background-image: url(../images/header-design-1.png), url(../images/header-design-2.png); */
    width: 100%;
    background-position: top left, top right;
    background-repeat: no-repeat;
    margin-top: 64px;
}

.banner-color {
    background-color: rgba(71, 18, 125, 1) !important;
}

.container-fluid.banner-color:after {
    background: linear-gradient(180deg, #443B5C 0%, #47127D 100%) !important;
}

.feature-blog-header .btn-primary-transperent {
    background-color: #dad0e5 !important;
    border-radius: 10px 0 !important;
    padding: 3px !important;
}

.container-fluid.bg-community-banner:after {
    content: "";
    top: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 200px;
    background-color: #333;
    opacity: .7;

}

@media(min-width: 1536px) and (max-width:1910px) {
    .service-card-height {
        min-height: 240px;
    }

    .bg-community-banner.bg-height {
        height: 400px;
    }

    .card-body.p-2.blog-card-height {
        min-height: 140px;
    }

    .lob-modal {
        /* top: 251px !important; 
         bottom: -55px !important;  */
    }
}

@media (min-width: 320px) and (max-width: 720px) {

    .row.bg-community-banner {
        height: 200px;
        width: 100%;
        background-position: top left, top right;
        background-repeat: no-repeat;
        margin-top: 0px;
    }
}

.community-position1 {
    position: relative !important;
    z-index: 2 !important;
    top: 64px;
}

.calendar {
    border-radius: 8px;
    perspective: 1000;
    transition: .9s;
    transform-style: preserve-3d;
    width: 100%;
}

/* Front - Calendar */
.front {
    transform: rotateY(0deg);
}

.current-date {
    display: flex;
    justify-content: space-between;
    padding: 24px 40px 15px 40px;
}

.current-date span {
    color: rgba(42, 42, 42, 1);
    font-size: 16px;
    font-weight: 400;
}

.week-days {
    color: rgba(154, 154, 154, 1);
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    list-style: none;
    margin-bottom: 0px;
    font-size: 16px;
    padding: 9px 40px 19px 40px;
}

.days {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.weeks {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
}

.weeks div {
    display: flex;
    font-size: 1.2em;
    font-weight: 300;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}

.last-month {
    opacity: .3;
}

.weeks span {
    padding: 7px;
    color: rgba(42, 42, 42, 1);
    font-size: 16px;
    font-weight: 400;
}

.weeks span.active {
    background: rgba(42, 42, 42, 1);
    border-radius: 8px;
    color: #fff;
}

.weeks span:not(.last-month):hover {
    cursor: pointer;
    font-weight: 400;
    background: rgba(218, 208, 229, 1);
    border-radius: 8px;
    color: rgba(42, 42, 42, 1);
}

.event {
    position: relative;
}

.community-card {
    border: none;
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);

}

.community-card:hover {
    border: none;
    -webkit-transform: translateY(-10px);
    transform: translateY(0px);
    cursor: auto;
}

.img-rotate {
    transform: rotate(-180deg);
}

.community-event-name {
    font-size: 16px;
    font-weight: 600;
    color: rgba(42, 42, 42, 1);
}

.community-event-date {
    font-size: 12px;
    font-weight: 400;
    color: rgba(154, 154, 154, 1);
    display: block;
    padding-top: 5px;
}

.text-primary,
.event-page-link {
    color: rgba(71, 18, 125, 1) !important;
}

a.text-primary:focus,
a.text-primary:hover {
    text-decoration: none;
    color: rgba(71, 18, 125, 1) !important;
}

.community-buttons {
    padding-bottom: 30px;
}

.community-buttons {
    display: block;
    align-items: center;
    justify-content: space-around;
    position: relative;
    top: 40px;
}

.explore-btn-position {
    text-align: right;
}

.com-event-btn:hover .white-colored {
    filter: brightness(0) saturate(100%) invert(11%) sepia(47%) saturate(2775%) hue-rotate(198deg) brightness(93%) contrast(107%);
}

.community-card .card-body {
    border-radius: 8px;
    min-height: 354px;
}

.com-blogs {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);
    margin-bottom: 30px;
}

.event-p {
    font-size: 12px;
    color: rgba(85, 85, 85, 1);
    line-height: 18px;
}

.blog-img {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.blog-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.blog-description {
    display: block;
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px;
}

/* 
.btn-primary-transperent {
    background-color: rgba(218, 208, 229, 1);
    border-radius: 10px 0px;
    padding: 3px;
} */

.com-blog-more {
    text-align: right;
}

.community-calendar {
    background-color: #fff;
    padding: 16px;
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);
    margin-bottom: 30px;
    border-radius: 8px;
}

.search-focus:focus {

    box-shadow: none;

    border: none;

}

@media (max-width:767px) and (min-width: 320px) {
    .search-filter-items {
        right: 0px;
    }

    .service-cardd {
        min-height: 0 !important;
    }

    .main-header-new {
        display: block;
    }

    /* .bg-community-banner{
        height: 200px !important;
    } */
    .workflow-card-h {
        height: unset;
    }

    .bg-community-banner.bg-height {
        height: 300px !important;
    }

    .container-fluid.bg-community-banner.bg-height:after {
        height: 300px !important;
    }

    .submit-buttons-post .btn {
        width: 100% !important;
        margin-bottom: 5px;
        margin-left: 0px !important;
    }



}
@media (min-width: 320px) and (max-width:320px) {
    .company-name-text{
        font-size: 7px !important;
    }
    .btn-cmn4,
    .btn-cmn5 {
        width: unset !important;
        font-size: 10px !important;
    }

    .gig-banner {
        padding-top: 10px;
    }
  
    .adminbtn-position {
        left: 17%;
    }

    .header-heading-mb {
        font-size: 17px !important;
    }

    .adminbtn-position-full {
        left: 17%;
    }

    .search-bar input {
        text-indent: 13px !important;
    }

    .ss .slick-prev,
    .ss .slick-next {
        margin: 0px;
    }

    .search-bar .fa-search {
        font-size: 13px !important;
    }

    .article-prev-next-btn {
        font-size: 14px !important;
        padding: 5px 5px !important;
        width: 100px !important;
    }

    .community-mid-container {
        max-width: 95% !important;
    }

    .p-datepicker table {
        font-size: 12px !important;
    }

    nav.main-header-new {
        padding-right: 5px;
    }

    .logo-before-login {
        height: 40px !important;
    }

    .leftbgforaccountpage {
        width: 8%;
    }

    .notfoundcard {
        font-size: 13px;
    }

    .wf-wrapper {
        min-height: 170vh !important;
    }

    .middleheader-div {
        padding-top: unset;
    }

    input#example-search-input {
        font-size: 10px !important;
    }

    .mid1-left {
        padding: 0 !important;
        font-size: 12px
    }

    .textlogo {
        width: 135px;
    }

    img.logo {
        width: 66%;
        padding-left: 0px;

    }

    .midbg-top {
        margin-top: 5px;
    }

    .sub-heading {
        margin-top: 10px;
    }

    a#pop2-tab,
    a#pop1-tab {
        padding: 5px !important;
    }

    .banner-heading {
        line-height: 29px !important;
        font-size: 18px;
    }

    .middle-top {
        padding-top: 25px !important;
    }

    .form-group label {
        font-size: 12px !important;
    }

    .atricle-info {
        padding-top: 1rem !important;
    }
}


.btn-outline-primary.event-btn {
    background-color: transparent;
    border: 1px solid rgba(71, 18, 125, 1) !important;
    padding: 5px 28px;
    color: rgba(71, 18, 125, 1) !important;
}


.btn-outline-primary.event-btn:hover {
    background-color: rgba(71, 18, 125, 1) !important;
    color: #fff !important;
}

.btn-primary.event-btn {
    background-color: rgba(71, 18, 125, 1) !important;
    border: 1px solid rgba(71, 18, 125, 1) !important;
    color: #fff !important;
}

.btn-primary.event-btn:hover {
    background-color: transparent !important;
    border: 1px solid rgba(71, 18, 125, 1) !important;
    color: rgba(71, 18, 125, 1) !important;
}

nav#sidebar-new.active ul {
    width: 64px;
    position: sticky;
}

nav#sidebar-new ul {
    /* position: fixed; */
    width: 240px;
    background: #fff;
    z-index: 1;
    min-height: 75%;
}

#sidebar-new .btn-collapse {
    /* position: fixed !important; */
}

#sidebar-new.active .btn-collapse {
    /* position: fixed !important; */
}

.row.bg-community-banner {
    height: 200px;
    width: 100%;
    background-position: top left, top right;
    background-repeat: no-repeat;
    margin-top: 64px;
}

/* .row.bg-community-banner:after {
    background: linear-gradient(180deg, #443B5C 0%, #47127D 100%);
    background-color: #333;
} */

.header-margin {
    margin-top: 64px;
}

.row.header-margin:after {
    /* top: 64px !important; */
}

.middle-top {
    z-index: 1;
}

@media (min-width: 320px) and (max-width: 720px) {

    .main-head-title {
        margin-top: 0px !important;
        padding-top: 2rem !important;
    }

    .main-head-title .text-16 {
        font-size: 16px !important;
    }
}

.container-fluid.bg-community-banner.bg-img-banner:after {
    left: 0px;
}

.header-margin.bg-community-banner:after {
    left: 0px;
}

.container-fluid.bg-community-banner.banner-show-hide:after {
    left: 0px;
}

.service-details.mobile-service-bg {
    margin-top: 40px;
}

.details-tabss .p-unselectable-text {
    border: 2px solid rgba(246, 245, 245, 1);
}

.details-tabss .p-unselectable-text .p-tabview-nav-link:hover {
    border-color: rgba(246, 245, 245, 1);
}

.details-tabss .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #001f4d;
}

.main-head-title {
    margin-top: 1rem;
    padding-top: 3rem;
}

.mobileheader {
    display: none;
}

.content.detail-content {
    margin-top: 3rem;
    /* z-index: 9; */
}

.workflow-btn .img-white {
    height: 15px;
}

.workflow-btn:hover .img-white {
    filter: brightness(0) saturate(100%) invert(8%) sepia(65%) saturate(3569%) hue-rotate(205deg) brightness(94%) contrast(102%) !important;
}

.api-details-btn:hover .img-secondary {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1849%) hue-rotate(308deg) brightness(108%) contrast(100%) !important;
}

.search-bar ::-webkit-input-placeholder {
    color: rgba(188, 188, 188, 1) !important;
}

.serach-filter-for-desktop .form-group label {
    font-size: 12px;
    font-weight: 400;
    color: rgba(42, 42, 42, 1);
}

.serach-filter-for-desktop .p-multiselect {
    color: rgba(188, 188, 188, 1);
    font-size: 12px;
    border: 1px solid rgba(221, 221, 221, 1);
}

.serach-filter-for-desktop .p-multiselect .p-multiselect-label.p-placeholder {
    color: rgba(188, 188, 188, 1);
}

.serach-filter-for-desktop .p-multiselect-trigger-icon.pi-chevron-down:before {
    color: #000;
    font-size: 12px;
}

.btn-purple {
    background-color: rgb(71 18 125) !important;
    border-color: rgb(71 18 125) !important;
    color: #fff !important;
    border-radius: 5px;
    font-size: 14px !important;
    padding: 0.375rem 0.75rem !important;
}

.btn-purple:hover {
    background-color: transparent !important;
    border-color: rgb(71 18 125) !important;
    color: rgb(71 18 125) !important;
}

.services-not-found .p-inline-message.p-inline-message-info {
    background: rgba(255, 250, 204, 1);
    border: 2px solid rgba(255, 229, 0, 1);
    /* border-width: 0px; */
    color: rgba(204, 183, 0, 1);
    justify-content: flex-start;
    height: 72px;
    border-radius: 8px;
}

.services-not-found .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: rgba(204, 183, 0, 1);
    border: 2px solid rgba(204, 183, 0, 1);
    border-radius: 8px;
    padding: 8px;
}

.btn-outline-danger {
    background-color: transparent !important;
    border-color: rgba(247, 42, 81, 1) !important;
    color: rgba(247, 42, 81, 1) !important;
    border-radius: 5px;
    font-size: 14px !important;
    padding: 0.375rem 0.75rem !important;
}

.btn-outline-danger:hover {
    background-color: rgba(247, 42, 81, 1) !important;
    border-color: rgba(247, 42, 81, 1) !important;
    color: #fff !important;
}

.prcing-link a {
    text-decoration: underline;
}

.api-details-btn .img-primary {
    height: 17px;
}

.btn.btn-yellow {
    background-color: #FFE500;
    border-radius: 4px !important;
    color: #2A2A2A !important;
    font-size: 14px;
    padding: 5px 10px !important;
}

.btn.btn-yellow:hover {
    background-color: transparent;
    border-radius: 4px !important;
    color: #FFE500 !important;
    border: 1px solid #ffe500;
}

.btn.btn-yellow-request-demo {
    background-color: transparent;
    border: 1px solid #ffe500;
    color: #FFE500 !important;
    font-size: 14px;
    padding: 5px 10px !important;
}

.btn.btn-yellow-request-demo:hover {
    background-color: #FFE500;
    border-radius: 4px !important;
    color: #2A2A2A !important;
    font-size: 14px;
    padding: 5px 10px !important;
}

.btn-yellow-create {
    vertical-align: unset !important;
}

.btn-request-service-ico {
    transform: rotate(270deg);
    -webkit-transform-origin: left top 0;
    transform-origin: left top 0;
    background: #FFE500;
    color: #000;
    margin-left: 40px;
    padding: 2px 47px;
    border: 1px solid #FFE500;
    float: left;
    position: fixed;
    border-radius: 10px 10px 0px 0px;
    font-size: 12px;
    right: -166px;
    top: 524px;
    z-index: 2;
}

.row.bg-community-banner.row-position {
    top: 0px;
}

.btn-okay {
    width: 169px;
    border-radius: 5px !important;
    font-size: 14px !important;
}

.company-profile-table {
    border-collapse: separate;
    border-spacing: 0px 12px;
}

.table.company-profile-table .thead-light th {
    background-color: #F9F9F9;
}

.table.company-profile-table thead th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.table.company-profile-table thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: none;
}

.table.company-profile-table .thead-light th {
    color: rgba(71, 18, 125, 1);
    font-weight: 600;
    font-size: 12px;
    border-right: 1px solid #E8E7E7;
}

.table.company-profile-table thead th {
    border-bottom: none;
    border-color: transparent;
}

.table.company-profile-table td,
.table.company-profile-table th {
    background-color: #FBFBFB;
}

.table.company-profile-table td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.table.company-profile-table td:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.table.company-profile-table td,
.table.company-profile-table th {
    padding: 0.75rem;
    background-color: rgba(246, 246, 246, 1);
    border-top: none;
}

.table.company-profile-table td {
    font-size: 12px;
}

.company-profile-table.table td,
.company-profile-table.table th {
    vertical-align: middle;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile.inputfile-2+label {
    border: 1px solid rgb(232 231 231);
}

.inputfile+label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.inputfile+label {
    font-size: 12px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 3px 8px;
    width: 95%;
    margin-bottom: 0px;
}

.card.service-card-hover.setup-service-tabs.company-profile {
    border: none;
}

.company-logo {
    width: 48px;
    height: 48px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-logo img {
    height: 24px;
}

.company-logo-align {
    display: flex;
    align-items: center;
}

.company-logo-align h2 {
    margin-left: 10px;
}

.userlistspace .p-datatable table {
    border-spacing: 0px 12px;
}

.workflow-table .p-datatable .p-datatable-thead>tr>th {
    background-color: #EEE6F7 !important;
    border-right: 1px solid #C5B0DB;
}

.workflow-table thead.p-datatable-thead tr th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.workflow-table thead.p-datatable-thead tr th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: none;
}

.z-index-1 {
    z-index: 1;
}

.border-none {
    border: none !important;
}

.card-shadow1 {
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);
}

.text-hash {
    color: #bcbcbc;
    font-weight: 400;
}

.community-position.card.community-card-hover {
    border: transparent;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
}

.container-fluid.bg-community-banner.bg-left-0:after {
    left: 0px;
}

.avatar-icon {
    background-color: #6f2d80 !important;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
}

.services-mobile-card.card {
    border: none;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
    min-height: 80px;
}

.services-mobile-card.card:hover {
    border: none;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    cursor: inherit;
}

.services-mobile-btn {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.btn-outline-secondary:hover .img-secondary {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7485%) hue-rotate(240deg) brightness(103%) contrast(105%);
}

.login-icon {
    height: 20px;
}

.btn-primary.com-event-btn {
    padding: 8px 32px !important;
    font-size: 16px !important;
    border: 1px solid #001f4d !important;
}

.btn-outline-primary.comm-cancel-btn {
    padding: 8px 32px !important;
}

.service-position.account-users-position {
    bottom: -39px;
}

.video-ptable iframe {
    border-radius: 4px;
}

.legal-table .p-datatable-tbody td:first-child {
    text-align: start !important;
    width: 104px;
}

.legal-table .p-datatable-tbody td {
    text-align: center !important;
}

.userlisttable.report-table .p-datatable .p-datatable-tbody>tr>td {
    text-align: left !important;
}

.userlisttable .p-datatable .p-datatable-tbody>tr>td:first-child {
    width: 115px;
}

.legal-table .p-datatable-tbody a,
.index-blog-service1 a {
    color: #2A2A2A;
    font-weight: 600;
    font-size: 12px;
}

.article-prev-next-btn.nxt-btn {
    margin-left: 10px;
}

.com-event-btn.event-btn {
    margin-left: 10px;
}

.event-btn.exp-btn {
    padding: 8px 32px;
}

.report-csrd-shadow {
    box-shadow: 0 22px 80px rgb(0 0 0 / 3%), 0 8.47407px 25.4815px rgb(0 0 0 / 2%), 0 1.79259px 6.51852px rgb(0 0 0 / 1%);
}

.input-file .button.btn-upl {
    background-color: transparent;
    color: #000;
    padding: 0px;
    left: 4px;
}

.input-label-file .form-control {
    padding-left: 29px;
}

.video-card .card-body {
    min-height: 250px;
}

.card.community-card.video-card {
    min-height: 289px;
}

.service-preview-width {
    max-width: 304px;
    margin: 0 auto;
}

.overview-form .form-control::placeholder {
    color: #BCBCBC !important;
}

.userlisttable.legal-table {
    height: 204px;
    overflow-y: scroll;
}

.userlisttable.legal-table-scroll {
    height: 150px;
    overflow-y: scroll;
}

.userlisttable.legal-table-scroll::-webkit-scrollbar {
    width: 4px;
}

.userlisttable.legal-table-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 30px;
}

.userlisttable.legal-table-scroll::-webkit-scrollbar-thumb {
    background: rgba(71, 18, 125, 1);
    border-radius: 30px;

}

.userlisttable.legal-padding .p-datatable .abc .p-column-header-content {
    justify-content: flex-end;
    margin-right: 40px;
}

.service-form-control .form-control {
    padding: 0.375rem 3.5rem 0.375rem 0.75rem
}

.userlisttable.user-table-width .p-datatable .p-datatable-tbody>tr>td:first-child {
    width: auto !important;
}

.ql-editor.ql-blank::before {
    color: #BCBCBC !important;
    font-size: 12px !important;
    font-style: inherit !important;
    font-weight: 500 !important;
}

.user-video.userlisttable .p-datatable .p-datatable-tbody>tr {
    background: transparent !important;
}

.user-screenshot.userlisttable .p-datatable .p-datatable-tbody>tr {
    background: transparent !important;
}

.user-screenshot.userlisttable .p-datatable .p-datatable-tbody>tr td:nth-child(2) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 34px !important;
}

.user-screenshot.userlisttable .p-datatable .p-datatable-tbody>tr td {
    background: #F8F7F7;
}

.user-screenshot.userlisttable .p-datatable .p-datatable-tbody>tr td:first-child {
    background: transparent;
    width: 85px;
    padding: 0px !important;
}

.user-screenshot.userlisttable .p-datatable .p-datatable-tbody>tr td:last-child {
    text-align: right !important;
    padding-right: 30px !important;
}

.userlisttable.legal-table::-webkit-scrollbar {
    width: 4px;
}

.userlisttable.legal-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 30px;
}

.userlisttable.legal-table::-webkit-scrollbar-thumb {
    background: rgba(71, 18, 125, 1);
    border-radius: 30px;

}

.userlisttable.legal-table.legal-padding .p-datatable .p-datatable-tbody>tr>td {
    padding: 10px !important;
}

.userlisttable.legal-table.legal-padding .p-datatable .p-datatable-tbody>tr>td:last-child {
    text-align: right !important;
    padding-right: 26px !important;
}

.userlisttable.legal-table.legal-padding .p-datatable .p-datatable-tbody>tr>td:first-child {
    padding-left: 30px !important;
}

.fworkflow-table {
    background-color: transparent;
    border-bottom: none !important;
    color: #001f4d !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
}

table.fworkflow-table th:first-child {
    border-radius: 10px 0 0 10px;
}

table.fworkflow-table th:last-child {
    border-radius: 0 10px 10px 0;
}

.fworkflow-table.table {
    border-collapse: separate !important;
    border-spacing: 0 8px !important;
}

.fworkflow-table tbody tr {
    background-color: #f8f7f7 !important;
}

.fworkflow-table thead th {
    border-bottom: none !important;
    border-top: none !important;
}

.fworkflow-table tbody>tr td:first-child {
    font-weight: 700;
}

table.fworkflow-table td:first-child {
    border-radius: 4px 0 0 4px;
}

.fworkflow-table tbody>tr>td {
    border: none !important;
}

.fworkflow-table.table td {
    color: #575757;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.fworkflow-table tr {
    background-color: #f8f7f7 !important;
}

/* .fworkflow-table tbody>tr td:nth-child(4) {
    color: #2fad87;
    font-weight: 600;
} */
.fworkflow-table.table thead th,
.fworkflow-table.table tbody td {
    vertical-align: middle;
    font-size: 12px;
}

.fworkflow-table.table thead th {
    font-weight: 700;
    text-align: center;
}

.fw-btn-table {
    padding: 2px 6px !important;
    font-size: 12px !important;
}

.fw-btn-table:hover {
    border: 1px solid rgba(0, 31, 77, 1);
}

.fworkflow-table tbody>tr td:nth-child(6),
.fworkflow-table tbody>tr td:nth-child(7),
.fworkflow-table tbody>tr td:nth-child(8),
.fworkflow-table tbody>tr td:nth-child(12) {
    text-align: center;
}

.fworkflow-table tbody>tr td:nth-child(10),
.fworkflow-table tbody>tr td:nth-child(11) {
    text-align: right;
}

.mid-container-workflow {
    max-width: 92%;
    margin: 0 auto;
}

/* .fworkflow-table thead>tr th:nth-child(6){
    border-left: 2px solid #001F4D;
}
.fworkflow-table thead>tr th:nth-child(7), .fworkflow-table thead>tr th:nth-child(8){
    border-left: 1px solid #001F4D;
}
.fworkflow-table thead>tr th:nth-child(8){
    border-right: 2px solid #001F4D;
}
.fworkflow-table tbody>tr td:nth-child(6){
    border-left: 2px solid #001F4D !important;
}
.fworkflow-table tbody>tr td:nth-child(7), .fworkflow-table tbody>tr td:nth-child(8){
    border-left: 1px solid #001F4D !important;
}
.fworkflow-table tbody>tr td:nth-child(8){
    border-right: 2px solid #001F4D !important;
} */
.fworkflow-table tbody>tr td:nth-child(6) {
    border-left: 1px solid #001f4d !important;
    border-right: 1px solid #001f4d !important;
}

.fworkflow-table tbody>tr td:nth-child(7) {
    border-right: 1px solid #001f4d !important;
}

.fworkflow-table tbody>tr td:nth-child(8) {
    border-right: 1px solid #001f4d !important;
}

/* .fworkflow-table thead>tr th:nth-child(6){
    border-left: 1px solid #001f4d !important;
    border-right:  1px solid #001f4d !important;
} */
.fworkflow-table tbody>tr td:nth-child(9),
.fworkflow-table tbody>tr td:nth-child(10) {
    text-align: center;
}

.dm-workflow {
    text-align: center;

    font-size: 14px;
}

.dm-fworkflow {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    width: 19% !important;
}

.dm-fworkflow tr th {
    padding-top: 5px;
    padding-bottom: 5px;
}

.api-border {
    /* border-right: 1px solid #001f4d; */
    width: 33%;
}

.rtg-border {
    /* border-right: 1px solid #001f4d; */
    width: 33%;
}

.chrome-border {
    width: 33%;
}

.featured-wb {
    width: 16%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.featured-wb tr td {
    border-top: none;
}

.StripeElement {
    /* width:500px; */
    /* margin: 15px auto; */
    padding: 7px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid rgb(232 231 231) !important;
    border-radius: 4px;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
}

.btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: linear-gradient(135deg,
            rgb(49, 0, 62) 0%,
            rgb(195, 40, 110) 100%);
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
}

.product-info {
    padding: 0 16px;
}

h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
}

h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
    font-weight: 500;
}

.search-filter-group .form-group {
    margin-bottom: 10px;
}

/*****Payment Details***********/
.mid-container-payment {
    max-width: 92%;
    margin: 0 auto;
}

.btn-outline-white.payment-edit-btn {
    padding: 2px 50px !important;
    border-radius: 4px !important;
    font-size: 12px !important;
}

.payment-page-card {
    height: 266px;
    position: relative;
    background-color: #390E64;
    border-radius: 20px;
}

.payment-card {
    /* height: 281px; */
    /* padding: 14px; */
}

.payment-table .table thead th {
    color: #47137D;
    width: 50%;
}

.selected-service .table thead th {
    color: #47137D;
}

.payment-progress {
    height: 4px !important;
    background-color: #EEE6F6 !important;
}

.payment-progress .progress-bar {
    background-color: #47127D;
}

.dashboard-service-table.selected-service .table td {
    color: #575757;
}

.dashboard-service-table.payment-table .table td {
    color: #575757;
}

.payment-table-h {
    height: 241px;
    overflow-y: scroll;
}

.payment-table-h::-webkit-scrollbar {
    width: 4px;
}

.payment-table-h::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 30px;
}

.payment-table-h::-webkit-scrollbar-thumb {
    background: rgba(71, 18, 125, 1);
    border-radius: 30px;

}

.universal-bank {
    position: absolute;
    top: 34px;
    left: 34px;
}

.visa-img {
    position: absolute;
    top: 34px;
    right: 34px;
}

.payment-card-number {
    position: absolute;
    top: 128px;
}

.payment-card-number h {
    font-size: 20px;
    color: #fff;
    letter-spacing: 10px;
}

.cardholder-details {}

.cardholder-name {
    position: absolute;
    bottom: 21px;
}

.text-14 {
    font-size: 14px !important;
}

.cardholder-name1 {
    font-weight: 500;
    color: #FFFFFF33;
}

.cardexpiry-name {
    position: absolute;
    right: 19px;
    bottom: 22px;
}

.brand-logos img {
    width: 82px;
    margin-top: 39px;
    /* margin-right: 34px; */
}

.powered-by-stripe {
    background-color: #47127D;
    padding: 15px;
    border-radius: 8px;
    /* width: fit-content; */
}

.visa-img {
    height: 35px !important;
}

.btn-danger.delete-card-btn {
    padding: 5px 30px;
    position: absolute;
    /* bottom: 0px; */
    right: 15px;
}

@media (min-width: 1250px) and (max-width:1600px) {
    .payment-page-card {
        height: 240px;
        width: 100%;
    }
   
    .payment-card-number {
        top: 99px;
    }

    .payment-card-number h {
        letter-spacing: 7px;
    }

}

.btn-secondary.saveinfo-btn {
    /* position: absolute; */
    bottom: 0px;
    right: 15px;
}

.ElementsApp input {
    font-size: 12px !important;
}

.CardField-number .CardField-number-fakeNumber,
.CardField-number input {
    font-size: 12px;
    font-weight: 600;
}

.checkoutform-height .form-group {
    margin-bottom: 0px !important;
    min-height: 75px;
}


/**********Subscribed Services************/
.subscribed-search-bar {
    box-shadow: 0 0 40px rgb(51 51 51 / 10%);
    background: #fff;
    border-radius: 8px;
    position: absolute;
    bottom: -31px;
    width: 100%;
    z-index: 1;
}

.subscribed-search-bar .fa-search {
    position: absolute;
    top: 24px;
    left: 27px;
}

.subscribed-search-bar input {
    height: 64px;
    text-indent: 25px;
    border: none;
    border-radius: 8px !important;
    padding: 10px 10px 10px 30px;
}

.subscribed-search-bar button {
    position: absolute;
    top: 12px;
    right: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscribed-search-bar input:focus {
    box-shadow: none;
    border: 2px solid #fff;
    outline: none;
}

.subscribed-services-padd {
    padding-top: 60px;
}

.subscribed-service-details .card.subscribe-card {
    margin-top: 27px;
}

.gig-service-sub {
    border: none !important;
}

.btn-info {
    background-color: rgb(0 31 77) !important;
    border: 1px solid rgb(0 31 77) !important;
    padding: 7px 20px !important;
}

.btn-info:hover {
    background-color: rgb(0 31 77) !important;
    border: 1px solid rgb(0 31 77) !important;
}

.bb-feedback-btn.helpbtn {
    background-color: #fff;
    color: #081c4c;
    width: 120px;
    padding: 5px;
    border: 1px solid #081c4c;
    border-radius: 7px;
}

.feedbackbtn.bb-feedback-btn {
    border: none;
    color: #fff;
    text-align: center;
    padding: 7px;
    font-size: 14px;
    border-radius: 7px;
    width: 120px;
    background-color: #081c4c;
}

.suppdata-title {
    color: #2a2a2a;
    line-height: 20px;
    font-weight: 600;
}

.supplemental-card {
    box-shadow: 0 22px 80px rgb(0 0 0 / 3%), 0 8.47407px 25.4815px rgb(0 0 0 / 2%), 0 1.79259px 6.51852px rgb(0 0 0 / 1%);
    border-radius: 8px;
    border: none !important;
}

.align-horizontal .input-box {
    width: 42%;
    margin-right: 1%;
}

.align-horizontal .input-submit {
    width: 14%;
}

.h-fieldset .input-box {
    margin-top: 0 !important;
}

.align-horizontal {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.h-fieldset.frm-box label {
    margin-top: 0;
    color: #2a2a2a !important;
    font-size: 12px !important;
    font-weight: 400;
}

.frm-box .form-control::placeholder {
    color: #d8d8d8 !important;
    opacity: 1;
}

.btn-submit-class.btn-secondry {
    background: #6f2d80 !important;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-radius: 8px;
    border: 1px solid #6f2d80 !important;
    padding: 8px 32px !important;
    width: 100%;
    justify-content: center;
}

.btn-submit-class.btn-secondry:hover {
    color: #fff;
}

.supplemental-card.card {
    margin-top: 7px;
}

.text-red {
    color: #e32;
    font-size: 12px;
    font-weight: 500;
}

.text-gray {
    color: #bcbcbc;
}

.text-secondary.business-name,
.text-secondary.address-name {
    color: #47127d !important;
}

.content-block {
    margin-top: 35px;
}

.inner-box {
    background: #fff;
    box-shadow: 0 3px 10pxrgba(0, 0, 0, .16);
    /* padding: 10px; */
    border-radius: 5px;
}

.content-block .boxheading h2 {
    color: #2a2a2a !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    position: relative;
    padding-left: 40px;
}

.content-block .boxheading h2 img {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.content-block .card-box-view {
    padding: 16px;
    background-color: #f8f7f7;
    border: 1px solid #47127d;
    border-radius: 4px;
    margin-top: 16px;
    background-color: #f8f7f7;
}

span.key {
    color: #0a1128;
    font-size: 16px;
    text-align: right;
    width: 100%;
    display: block;
}

.val a,
span.key,
span.val {
    font-size: 12px !important;
}

span.key {
    color: #343a40 !important;
    text-align: left !important;
}

span.val {
    font-size: 16px;
    color: #8d8d8d;
}

.FB_FONT .p-dialog-header {
    padding-bottom: 5px !important;
}

.textAreaHeight {
    resize: none;
    height: 118px !important;
}

.logout-header {
    -webkit-filter: invert(100%) sepia(94%) saturate(0) hue-rotate(248deg) brightness(106%) contrast(106%);
    filter: invert(100%) sepia(94%) saturate(0) hue-rotate(248deg) brightness(106%) contrast(106%);
    height: 20px !important;
}

.FB_FONT.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: start;
}

.p-dialog-header,
.p-sortable-column {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    background-color: #f8f7f7 !important;
    color: #001f4d !important;
}

.FB_FONT .p-dialog-header,
.FB_FONT .p-dialog-title {
    background-color: #fff !important;
}

.video-frame {
    height: 519px;
}

.header-bar.header-left-0 {
    padding-left: 0px !important;

}

.p-dialog .p-dialog-header .p-dialog-title {
    text-align: start;
}

/***********Broker Buddha*************/
.brokerbuddha-table {
    border-collapse: separate;
    border-spacing: 0px 12px;
    transition: visibility 0s, opacity 0.5s linear;
}

.table.brokerbuddha-table thead th {
    border-bottom: none;
    border-color: transparent;
}

.table.brokerbuddha-table thead th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 62%;
}

.table.brokerbuddha-table thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.table.brokerbuddha-table .thead-light th {
    background-color: #f8f9fa;
    background-color: rgba(238, 230, 246, 1);
}

.table.brokerbuddha-table .thead-light th {
    color: rgba(71, 18, 125, 1);
    font-weight: 600;
    font-size: 12px;
}

.table.brokerbuddha-table td {
    font-size: 12px;
}

.bb-tr th {
    background-color: rgba(238, 230, 246, 1) !important;
}

.table.brokerbuddha-table td,
.table.brokerbuddha-table th {
    padding: .75rem;
    background-color: #f8f7f7 !important;
    border-top: none;
}

.table.brokerbuddha-table td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px
}

.table.brokerbuddha-table td:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.brokerbuddha-table img {
    pointer-events: none;
}

.brokerbuddha-textarea {
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);
    border-radius: 8px;
    border: none;
}

.brokerbuddha-textarea:hover {
    border: transparent;
    transform: none;
    cursor: auto;
}

.brokerbuddha-textarea .feedback-area {
    padding: 20px 30px;
}

.brokerbuddha-textarea .messanger {
    position: absolute;
    left: 49.1%;
    top: 39%;
    background: #fff;
}

.brokerbuddha-textarea .messanger img {
    margin-top: 10px;
    margin-bottom: 10px;
}

.brokerbuddha-textarea .para {
    font-size: 12px;
    color: #ABABAB;
    line-height: 20px;
    font-weight: 400;
    padding: 20px 30px;
    border-right: 1px solid #E8E7E7;
}

.bb-span {
    cursor: pointer !important;
}

.bb-searchinput {
    width: 100%;
}

.brokerbuddha-ul {
    list-style: none;
    display: flex;
}

.brokerbuddha-ul .anim {
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    transition: 0.1s;
}

.brokerbuddha-ul .number {
    border-radius: 10px;
    font-size: 18px;
    margin-top: -3px;
    font-weight: 500;
    padding: 15px;
}

.brokerbuddha-icon {
    filter: invert(12%) sepia(68%) saturate(5083%) hue-rotate(268deg) brightness(71%) contrast(100%);
}

.brokerbuddha-arrow {
    margin-top: 5px;
}

.brokerbuddha-ul .anim:active {
    background-color: rgba(238, 230, 246, 1);
}

.brokerbuddha-ul .anim:hover {
    background-color: rgba(238, 230, 246, 1);
}

.bb-modal-feedbackFrame {
    height: 300px !important;
    max-height: 40%;
    width: 50% !important;
}

.bb-modal-feedbackContent {
    max-height: 123px;
}

.bb-maxHeight {
    min-height: 60.3vh;
}

.bb-table-margin {
    width: 99%;
    margin-left: 5px;
    justify-content: center;

}

.bb-feedback-button {
    border: none;
    position: absolute;
    right: 0;
    margin-top: 14px;
    margin-right: 200px;
}

.bb-feedback-button.help-fenris-btn {
    position: inherit;
    margin-top: 0px;
    margin-right: 0px;
}

.bb-feedback-button.feedback-fenris-btn {
    position: inherit;
    margin-top: 0px;
}

.bb-feedback-btn {
    border: none;
    color: white;
    text-align: center;
    padding: 7px;
    font-size: 14px;
    border-radius: 7px;
    width: 120px;
    background-color: #081c4c;
}

.bb-bttn {
    width: 169px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/*responsive*/

@media(max-width: 768px) {

    .bb-feedback-button {
        margin-right: 100px;
    }

    .create-gig-header {
        display: none !important;
    }

    .bb-feedback-btn {
        height: 35px;
        width: 35px;
        border-radius: 100px;
    }

    .bb-feedback-button-responsive {
        display: none;
    }

    .video-btn .bb-feedback-button-responsive {
        display: block;
    }

    .video-btn .bb-feedback-btn {
        height: auto;
    }

    .create-gig-btn {
        display: block !important;
    }

}

@media(max-width: 780px) {

    .brokerbuddha-table,
    .brokerbuddha-table tbody,
    .tbrokerbuddha-able tr,
    .brokerbuddha-table td {
        display: block;
        width: 100%;
    }

    .brokerbuddha-table tr {
        display: block;
        margin-bottom: 15px;
        min-width: 100%;
        width: 100%;
        margin-top: 10px;
    }

    .bb-searchinput {
        width: 100%;
        max-width: 700px;
    }

    .brokerbuddha-table td {
        display: flex;
        justify-content: center;
        text-align: right;
        position: relative;
        border-radius: 0 !important;
    }

    .bb-justify {
        text-align: center !important;
        justify-content: center !important;
    }

    .brokerbuddha-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 100%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }

    .bb-table-wrap {
        border-radius: 20px !important;
    }

    .thead-light {
        width: 100% !important;
        max-width: 100% !important;
    }

    .bb-search-input {
        display: block !important;
    }

    .bb-input-group {
        width: 100%;
    }

    .bb-form-inline-input {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .bb-modal-content {
        width: 100%;
        max-width: 100%;
    }

    .bb-searchbutton {
        justify-content: center;
        text-align: center;
        margin: 0;
        padding: 0;
        margin-top: 10px !important;
    }

    .bb-input-btn {
        justify-content: center !important;
        vertical-align: middle;
        margin-top: 0 !important;
        width: 300px;
        max-width: 100%;
    }

    .bb-tr {
        margin: 0 !important;
        padding: 0 !important;
        margin-top: -10px !important;
    }

    .bb-search-input .col-md-2 .btn {
        width: 80%;
        width: 300px;
    }

    .bb-thead {
        width: 900px !important;
        max-width: 100% !important;
    }

    .bb-modal-frame {
        width: 100% !important;
        min-height: 100% !important;
        max-height: 100%;
    }

    .bb-responsive {
        display: none;
    }

    .bb-table-separator {
        margin-top: 20px;
        border: 1px solid rgba(82, 82, 82, 0.1);
    }
}

.bb-searchbutton {
    margin-left: 10px;
}

.bb-search-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.bb-input-group {
    width: 100%;
    display: block;
}

.bb-documentpreview-dialog>.p-dialog-content {
    padding: 0 !important;
}


.bb-form-inline-input {
    width: 90%;
}

.bb-featured-text-icon {
    transform: rotate(90deg);
}

.bb-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    transition: 0.1s;
    -webkit-transition: 0.1s;
}

.bb-modal-content {
    overflow: hidden;
    height: 100%;
}


/* The Close Button */
.bb-modal-close {
    margin-top: -50px;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.bb-modal-close:hover,
.bb-modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* PDF Form preview */

.bb-form-wrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    transition: all 0.5s ease-in-out;
}

.bb-form-header {
    width: 100%;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    padding: 20px;
}

.bb-form-button {
    width: 50px;
    justify-content: center;
    height: 50px;
    border: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
}

.bb-image-wrap {
    min-width: 100%;
    max-width: 100%;
    max-height: 90%;
    display: flex;
}

.bb-main-preview {
    width: 100%;
    height: 60x !important;
    max-height: 60% !important;
    max-width: 100%;
    overflow: auto;
    overflow-y: auto;
    border-radius: 6px;
}

.bb-img-preview {
    pointer-events: none;
    max-width: 100%;
}

.bb-img-thumbnail {
    max-height: 700px;
    max-width: 100px;
    overflow-y: auto;
}

.bb-img-out {
    padding: 5px;
}

.bb-img-out:hover {
    border-radius: 7px;
    border: 2px solid white;
    transition: 0.1s;
    cursor: pointer;
}

.bb-img-thumb {
    pointer-events: none;
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.bb-form-icon {
    transform: scale(1.4);
    margin-left: 8px;
    filter: invert(100%) sepia(28%) saturate(2%) hue-rotate(267deg) brightness(109%) contrast(101%);
}

.bb-form-icon:hover {
    transition: 0.2s;
    transform: scale(1.8);
}

@media(max-width: 780px) {
    .bb-form-container {
        max-width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .bb-form-wrapper {
        width: 100% !important;
    }

    .bb-form-button {
        margin: 0 !important;
    }

    .bb-image-wrap {
        display: block !important;
        min-height: 88%;
        width: 800px;
        max-width: 800px;
        margin-top: 75px;
        max-height: 100%;
        justify-content: center;
    }

    .bb-main-preview {
        display: none;
    }

    .bb-img-preview {
        display: none;
    }

    .bb-img-thumb {
        width: 800px;
    }

    .bb-img-thumbnail {
        display: block;
        position: absolute;
        top: 70px;

        margin: 0;
        margin-top: 10px;
        max-width: 800px;
        max-height: 100%;

        overflow-y: auto !important;
    }

    .bb-img-out {
        width: 100%;
        max-width: 100%;
        height: 100% !important;
    }

    .bb-img-thumb {
        width: 100%;
        max-width: 100%;
    }
}

/* end of PDF Form previews */

.bb-main-preview::-webkit-scrollbar {
    width: 10px;
}

.bb-main-preview::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.bb-main-preview::-webkit-scrollbar-thumb {
    background: #888;
}

.bb-main-preview::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.bb-modal-content::-webkit-scrollbar {
    width: 10px;
}

.bb-modal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.bb-modal-content::-webkit-scrollbar-thumb {
    background: #888;
}

.bb-modal-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.bb-img-thumbnail::-webkit-scrollbar {
    width: 5px;
}

.bb-img-thumbnail::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.bb-img-thumbnail::-webkit-scrollbar-thumb {
    background: #888;
}

.bb-img-thumbnail::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.bb-signedform-quote-results-container {}


.bb-signedform-quote-results-container {
    padding: 20px;
    border: 1px solid #bbb;
    border-radius: 4px;
}

.bb-signedform-quote-results-container>div {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
}

.bb-signedform-quote-results-container>div>div {
    width: 100px;
    font-size: 12px;
}

.bb-signedform-quote-bind-link {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

/* END OF BROKERBUDDHA */

.leads-selection label {
    color: rgba(87, 87, 87, 1);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
    display: block;
}

.leads-selection {

    padding: 0px 30px;
}

.leads-selection-padd {
    display: flex;
}

.leads-state-selected {
    min-width: 200px;
    margin-right: 12px;
}

.leads-business-selected {
    min-width: 198px;
    margin-right: 12px;
}

.leads-annual-selected {
    min-width: 160px;
    margin-right: 12px;
}

.leads-employee-range-selected {
    min-width: 100px;
    margin-right: 12px;
}

.leads-leads-selected {
    min-width: 51px;
}

.leads-freq-selected {
    min-width: 101px;
}

.badge-primary {
    font-size: 12px !important;
    padding: 5px 8px !important;
    border-radius: 4px !important;
    font-weight: 400 !important;
    background: rgba(0, 31, 77, 1) !important;
}

.text-primary-1 {
    color: #001F4D !important;
}



.table.leads-table {
    table-layout: fixed;
}

.p-component,
.p-dropdown {
    font-family: 'Readex Pro', sans-serif !important;
}

.datatable-leads .p-datatable table {
    width: 100%;
    border-collapse: collapse;
    min-width: 100%;
    overflow-x: scroll;
    display: inline-table;
}

/* .p-datatable-responsive-scroll>.p-datatable-wrapper {
    overflow-x: auto;
    overflow-y: scroll;
    max-height: 600px;
} */

.leads-table.table thead th {
    border-bottom: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    background-color: #f8f7f7 !important;
    color: #001f4d !important;
}

table.leads-table th:first-child {
    border-radius: 10px 0 0 10px;
}

span.p-sortable-column-icon.pi.pi-fw.pi-sort-alt:before {
    font-size: 12px;
}

.leads-table .p-datatable .p-datatable-tbody>tr {
    cursor: pointer;
}

.datatable-leads .p-datatable .p-datatable-tbody>tr>td {
    border: none !important;
}

table.leads-table td:first-child {
    border-radius: 10px 0 0 0;
}

table.leads-table th:last-child {
    border-radius: 0 10px 10px 0;
}

.table.leads-table tr:last-child {
    border-bottom: none;
}

.datatable-leads .leads-card {
    margin-top: 10px;
}

.leads-selection-padd label {
    color: rgba(87, 87, 87, 1);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
    display: block;
}

.leads-inline-padd label {
    display: flex;
}

.leads-inline-padd .form-group {
    margin-bottom: 0px;
}

.leads-inline-padd .p-multiselect {
    font-size: 12px;
    color: #575757;
    border: 1px solid #8d8d8d91;
    width: 100%;
    font-weight: 300;
}

.leads-inline-padd {
    padding: 20px 30px;
}

.leads-card-select {
    border-right: 1px solid #e0e0e0;
}

.leads-check-select {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
}

.leads-check-select .form-check-input[type=radio] {
    border-radius: 50%;
}

.leads-check-select .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.leads-check-select input[type='radio']:checked:before {
    background: #fff;
}

.leads-check-select input[type='radio']:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin: 22% auto;
    border-radius: 50%;
}

.form-check-input:checked {
    background-color: rgba(0, 31, 77, 1) !important;
    border-color: rgba(0, 31, 77, 1) !important;
}

.leads-check-select .form-check-input {
    width: 16px;
    height: 16px;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 31, 77, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.leads-check-select .form-check {
    margin-right: 25px;
    bottom: 20px;
}

.hor-line {
    height: 43px;
    width: 1px;
    background: rgba(232, 231, 231, 1);
    position: absolute;
    left: 48px;
    top: 72px;
}

.update-btn {
    display: flex;
}

.lead-selection-textarea .para {
    font-size: 12px;
    color: #ABABAB;
    line-height: 20px;
    font-weight: 400;
    padding: 20px 30px;
    border-right: 1px solid #E8E7E7;
}

.lead-selection-textarea .messanger {
    position: absolute;
    left: 49.1%;
    top: 39%;
    background: #fff;
}

.lead-selection-textarea .feedback-area {
    padding: 20px 30px;
}

.lead-selection-textarea textarea {
    height: 80px;
    resize: inherit;
    padding: 12px;
}

.lead-selection-textarea .messanger img {
    margin-top: 10px;
    margin-bottom: 10px;
}

.p-multiselect-panel .p-multiselect-items {
    font-size: 12px !important;
}

.p-checkbox .p-checkbox-box {
    font-size: 12px !important;
}

.fileupload-css.p-fileupload .p-fileupload-buttonbar {
    background: transparent !important;
    padding: 0 !important;
    border: none !important;
}

.fileupload-csss .p-fileupload .p-fileupload-buttonbar {
    background: transparent !important;
    padding: 0 !important;
    border: none !important;
}

.fileupload-csss .p-fileupload-buttonbar {
    background: #47127D !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 5px !important;
}

.fileupload-csss .p-fileupload-buttonbar:hover {
    background-color: white !important;
    border: 1px solid #47127d !important;
    border-color: #47127D !important;
    color: #47127D !important;
    width: 100% !important;
}

.fileupload-csss .p-button {
    background: transparent !important;
    padding: 6px 25px 6px 25px !important;
    width: 160px !important;
    border: none !important;
    transition: none !important;
}

.fileupload-csss .p-button .p-button-label {
    transition: none !important;
    font-size: 1rem !important;
}

.fileupload-csss .p-button:hover {
    background: none !important;
    color: #47127D !important;
    border-radius: 4px !important;
    background-color: transparent !important;
}

.lossrun-tbl .p-selectable-row {
    margin-top: 10px !important;
}

.lossrun-tbl .p-dropdown-label {
    align-self: center !important;
}

.lossrun-tbl .p-datatable-table {
    margin-top: -10px !important;
}

.lossrun-download-icon img {
    filter: invert(100%) sepia(97%) saturate(12%) hue-rotate(237deg) brightness(120%) contrast(120%);
    transform: scale(120%);
    height: 18px;
}

.lossrun-download-icon:hover img {
    filter: none !important;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom: 1px solid #dee2e6 !important;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-fileupload .p-fileupload-content {
    background: #fff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    display: none;
}

.p-button {
    background-color: #001f4d !important;
    border: 1px solid #001f4d !important;
    padding: 7px 20px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
}

.loss-run.leads-table.table thead th {
    border: none !important;
    color: #47127d !important;
    font-weight: 700 !important;
}

.loss-run .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead {
    background-color: transparent !important;
}

.datatable-leads .loss-run .p-datatable.table {
    border-collapse: separate !important;
    border-radius: 4px !important;
    border-spacing: 0 7px;
}

.lossrun .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    background: #f8f7f7 !important;
    font-size: 12px;
    font-weight: 400;
    padding: 7px 1rem;
}

.lossrun .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:first-child {
    border-radius: 10px 0px 0px 10px;
}

.lossrun .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child {
    border-radius: 0px 10px 10px 0px;
}

.leads-table.loss-run .p-datatable .p-datatable-tbody>tr {
    margin-top: 12px;
}

.badge-danger-ico {
    height: 18px;
    filter: brightness(0) saturate(100%) invert(27%) sepia(30%) saturate(7466%) hue-rotate(338deg) brightness(90%) contrast(90%);
}

.badge-danger.badge-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid #dc3545;
    color: #dc3545;
    background-color: #fbd9dd;
}

.badge-success.badge-admin {
    display: flex;
    align-items: center;
    justify-content: center;
}

.badge-success-ico {
    height: 18px;
    filter: brightness(0) saturate(100%) invert(53%) sepia(86%) saturate(326%) hue-rotate(111deg) brightness(90%) contrast(91%);
}

.badge-warning.badge-admin {
    display: flex;
    align-items: center;
    justify-content: center;
}

.badge-warning-ico {
    height: 18px;
    filter: brightness(0) saturate(100%) invert(66%) sepia(24%) saturate(5767%) hue-rotate(21deg) brightness(107%) contrast(101%);
}

.lossrun .p-paginator {
    background: #f8f7f7 !important;
    border: none;
    padding: 2px;
    margin-top: 17px;
    border-radius: 8px;
    font-size: 12px;
}

.lossrun .p-paginator .p-dropdown {
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
}

.lossrun .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.lossrun .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #47127d !important;
}

.details-eye {
    filter: brightness(0) saturate(100%) invert(10%) sepia(83%) saturate(3335%) hue-rotate(264deg) brightness(95%) contrast(104%);
    height: 18px;

}

.align-horizontal .input-box.input-data-3 {
    width: 33.33%;
    margin-right: 0px;
}

.imdtable .table tr:last-child {
    border-bottom: none !important;
}

.imdtable .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:first-child {
    border-radius: 10px 0px 0px 10px;
}

.imdtable .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child {
    border-radius: 0px 10px 10px 0px;
}

.imdtable .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 0px;
}

.imdtable .p-datatable .p-datatable-tbody>tr {
    border: none;
}

.imdtable .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    border-width: 0px;
}

.imdtable thead.p-datatable-thead tr:last-child {
    border-bottom: none;
}

.imdtable .p-paginator .p-dropdown {
    display: flex;
    align-items: center;
    padding: 10px;
}

.imdtable .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0px;
}

.im_table.p-datatable table {
    border-collapse: separate;
    border-spacing: 0px 10px;
}

.im_table.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    background: #f8f8f8;
}

.im_table.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:first-child {
    border-radius: 10px 0px 0px 10px;
}

.im_table.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child {
    border-radius: 0px 10px 10px 0px;
}

/*******Upcontent**********/
.upcontent-badge-container {
    display: 'flex';
    justify-content: space-between;
    width: 100%;
}

.upcontent-emailtemplate-preview {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upcontent-emailtemplate-box {
    display: flex;
    width: 100%;
}

@media(max-width: 1000px) {
    .upcontent-emailtemplate-box {
        display: block
    }

    .upcontent-emailtemplate-preview {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.upcontent-emailtemplate-preview>img {
    position: absolute;
    display: none;
    pointer-events: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 9999;
    transition: 0.1s;
    -webkit-transition: 0.1s;
}

.upcontent-emailtemplate-preview:hover img {
    display: block;
}

.upcontent-badge-container label {
    font-size: 14px;
    font-weight: 500;
}

.upcontent-topics-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    height: 70px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.topics {
    padding-top: 10px;
}

.upcontent-card-body {
    margin: auto;
    border-radius: 10px;
}

.upcontent .form-group {
    margin-bottom: 15px;
}

.upcontent-form-separator {
    border-right: 1px solid #bbb;
}

.upcontent .box {
    padding: 0px;
}

@media(max-width: 768px) {
    .upcontent-header-area {
        margin-top: 0px !important;
    }

    .upcontent-title-area {
        margin-top: -15px !important;
    }
}


/*******Upcontent**********/
.text-24 {
    font-size: 24px;
}

.upcontent-inline-padd {
    padding: 20px 40px;
}

.border-right-form {
    border-right: 1px solid rgba(188, 188, 188, 1);
    height: 80%;
}

.upcontent .form-control {
    border: 1px solid rgba(188, 188, 188, 1);
    color: rgba(154, 154, 154, 1);
    font-size: 12px;
    padding: 0.375rem 0.75rem;
}

.upcontent .form-group {
    margin-bottom: 15px;
}

.upcontent .box {
    padding: 0px;
}

.upcontent-select-box .p-component {
    height: 30px;
}

.upcontent-select-box .p-placeholder {
    margin-top: -10px;
}

.upcontent-select-box .p-inputtext {
    margin-top: -10px;
}

.upcontent-filter .p-dropdown {
    height: 32px;
    display: flex;
    width: 100%;
    font-family: 'ReadexPro-Regular', sans-serif !important;
}

.upcontent-filter .p-dropdown-item:hover {
    background-color: blue;
}

.upcontent-filter .p-inputtext {
    font-size: 12px;
}

.upcontent-template-btn {
    max-height: 38px;
    margin-top: 15px;
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding: 10px !important;
    width: 100% !important;
    max-width: 100% !important;
    color: white !important;
}

.upcontent-feedback-btn:hover {
    background-color: #47127D !important;
}

.upcontent-save-btn {
    max-width: 200px;
    width: 200px;
}

.upcontent-autocomplete {
    border-color: #bbb !important;
    height: 32px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    font-size: 12px !important;
}

.upcontent-prospects-wrapper {
    display: flex;
    justify-content: space-around;
}

.upconitent-remove-btn-background {
    border-color: white !important;
}

.upcontent-prospects-upload-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-height: 32px;
    overflow: hidden;
}

.upcontent-upload-text {
    min-width: fit-content;
    margin-top: 3px;
}

.upcontent-remove-image {
    height: 30px;
    min-width: 30px;
    display: flex;
    background-color: #e9ecef;
    cursor: pointer;
}

.upcontent-logo-upload-content {
    display: flex;
    min-width: 300px;
    justify-content: space-between;
}



.upcontent-upload-btn {
    text-align: center;
    background-color: #e9ecef;
    width: 166px;
    min-width: 166px;
    cursor: pointer;
    height: 30px;
    margin-top: 0px;
    margin-right: 20px;
}


.upcontent-loadingmessage-div {
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upcontent-loadingmessage-div .p-progress-spinner {
    margin: 0 !important;
}

.upcontent-upload-btn:active {
    background-color: rgb(213, 216, 219);
}

.upcontent-remove-image span {
    justify-content: center;
}

.upcontent-upload-file-input {
    display: flex;
    border: 1px solid #bbb;
    border-radius: 4px;
    height: 32px;
    overflow: hidden;
}

.upcontent-submit-btn {
    display: flex;
    width: 200px;
    margin-left: 10px;
}

.upcontent-submitbuttons-wrapper {
    display: flex;
}

@media(max-width: 1400px) {
    .upcontent-prospects-upload-wrapper {
        display: block;
        height: 64px;
        max-height: 64px;
    }

    .upcontent-upload-btn {
        width: 100%;
        max-width: 100%;
    }

    .upcontent-upload-file-input {
        display: block;
        height: 64px !important;
    }

    .upcontent-upload-text {
        margin-top: 3px;
        margin-left: 10px;
    }
}


.upcontent-emailtemplate-upc {
    display: flex;
    width: 100%;
}

@media(max-width: 1608px) {
    .upcontent-emailtemplate-upc {
        display: block;
    }
}

@media(max-width: 1410px) {
    .upcontent-body-header {
        /* height: 100px !important; */
    }
}

@media(max-width: 768px) {
    .upcontent-body-header {
        display: block !important;
    }

    .border-right-form {
        border: 0px;
        border-bottom: 1px solid rgba(188, 188, 188, 0.4);
    }

    .upcontent-submitbuttons-wrapper {
        display: block;
        width: 100%;
    }

    .upcontent-submitbuttons-wrapper button {
        padding: 20px;
    }

    .upcontent-submit-btn {
        margin: 15px auto;
        width: 90%;
    }

    .upcontent-loadingmessage-div {
        height: 36px;
        min-height: 36px;
    }

    .upcontent-dialog {
        background-color: black;
    }
}

.upcontent-header {
    display: flex;
    width: 83%;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 135px;
}

.upcontent-download-template {
    justify-content: flex-end;
}

.upcontent-card-body {
    width: 100%;
}

.upcontent-card-body label {
    color: black;
    font-size: 12px;
}

.upcontent-card-body-width label {
    color: #2A2A2A;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.upcontent-card-body-width {
    width: 95%;
    margin: auto;
    margin-top: 15px;
}

.upcontent-section-header {
    color: black;
}

.upcontent-logopreview-container {
    max-height: 100px;
    display: flex;
    /* justify-content: center; */
}

.upcontent-logopreview-container img {
    /* margin: auto; */
    max-height: 100px;
    pointer-events: none;
}

.upcontent-template-element {
    padding: 10px;
}

.upcontent-template-element>input {
    margin-right: 5px;
}

.upcontent-template-element input+div {
    cursor: pointer;
    border: 2px solid transparent;
}

.upcontent-template-element input:checked+div {
    border: 1px solid #001f4d;
}

.upcontent-template-element label {
    margin-top: -5px;
}

.upcontent-template-element img {
    margin-left: -30px;
}

.upcontent-large-image {
    display: none;
    z-index: 99;
}

.upcontent-template-element:hover .upcontent-large-image {
    display: block;
    position: fixed;
    border: 1px solid rgba(0, 31, 77, 0.25);
    margin-left: 200px;
    margin-top: -820px;
    overflow: hidden;
}

.upcontent-email-preview-dialog .p-dialog {
    background-color: black;
}

.upcontent-filter .p-multiselect {
    border: 1px solid #bbb;
    width: 100%;
}

.upcontent-tooltip {
    margin-left: 5px;
    cursor: pointer;
    filter: invert(10%) sepia(75%) saturate(2268%) hue-rotate(203deg) brightness(35%) contrast(104%);
}

.upcontent-alert-icon {
    filter: invert(80%) sepia(13%) saturate(7491%) hue-rotate(11deg) brightness(150%) contrast(80%);
}

.upcontent-right-border {
    border-right: 1px solid #bbb;
}

.upcontent-filter .p-multiselect-label-container {
    /* max-width: 410px; */
}

.upcontent-card-body-margin {
    margin: auto;
}

.upcontent-badges {
    margin-top: -2px;
    min-width: 150px;
}

.upcontent-horizontal-separator {
    border-bottom: 1px solid #BCBCBC;
    margin: auto;
    margin-top: 36px;
    width: 100%;
    margin-bottom: 43px;
}





/* CERTFIFICIAL CSS */

.batch-container {
    padding: 20px;
}

.batch-container>div {
    margin-bottom: 50px;
}



/* MULTIUPLOAD CSS */
.multiupload-batch-item {
    height: 50px;
    width: 99%;
    margin: auto;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
    padding-right: 40px;
}

.multiupload-batch-item-separator {
    height: 100%;
    width: 10px;
    max-width: 10px;
    border-top-left-radius: 6px;
}

.multiupload-batch-success {
    background-color: #D5EFE7;
    color: #2FAD87;
}

.multiupload-batch-success .multiupload-batch-item-separator {
    background-color: #2FAD87;
}

.multiupload-batch-success .multiupload-newrequest-option:hover {
    background-color: #ACDECF;
}

.multiupload-batch-failed {
    background-color: #ff9595;
    color: white;
}

.multiupload-batch-failed .multiupload-batch-item-separator {
    background-color: #F72A51;
}

.multiupload-batch-failed .multiupload-delete-option:hover {
    background-color: #F72A51;
}

.multiupload-batch-duplicates {
    background-color: #FFFACC;
    color: black;
    border-color: black;
}

.multiupload-batch-duplicates .multiupload-batch-item-separator {
    background-color: #FFE500;
}

.multiupload-batch-duplicates .multiupload-newrequest-option:hover {
    background-color: #FFF599;
}

.multiupload-batch-duplicates .multiupload-batch-msg {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

@media(max-width: 1680px) {
    .multiupload-batch-duplicates {
        height: 80px;
    }

    .multiupload-batch-msg>div {
        margin: 20px;
    }

    .multiupload-batch-duplicates .multiupload-batch-msg {
        display: block;
    }
}


@media(max-width: 1540px) {
    .certificial-header-title {
        font-size: 24px;
    }
}


.multiupload-progressbar .p-progressbar .p-progressbar-label {
    color: #fff;
    line-height: 11px;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
}


.multiupload-uploadmessage-container>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.multiupload-upload-label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.multiupload-upload-label>span {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* END OF MULTIUPLOAD CSS */

.certificial-header-title {
    font-size: 32px;
}

@media(max-width: 1680px) {
    .certificial-header-title {
        font-size: 28px;
    }
}


@media(max-width: 1540px) {
    .certificial-header-title {
        font-size: 24px;
    }
}

@media(max-width: 1400px) {
    .certificial-header-title {
        font-size: 20px;
    }
}

@media(max-width: 1240px) {
    .certificial-header-title {
        font-size: 16px;
    }
}

@media(max-width: 1100px) {
    .certificial-header-title {
        font-size: 10px;
    }
}

@media(max-width: 768px) {
    .certificial-header {
        display: block;
    }
}

.FB_FONT.p-dialog .p-dialog-header .p-dialog-title {

    font-size: 16px !important;
    font-weight: 600 !important;
}

.FB_FONT .p-dialog-header {
    background-color: #fff !important;
    padding-bottom: 5px !important;
}

.FB_FONT .p-dialog-title {
    background-color: #fff !important;
}

.CertTabs {
    float: right;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 50px;
    width: 325px;
    background-color: #fff;
    border-radius: 4px
}

.CertImg {
    height: 344px;
    width: auto;
}

.CertHeader .p-dialog-title {
    font-size: large !important;
}

@media (max-width:2000px) and (min-width: 1700px) {
    .CertTabs {
        width: 326px;
    }
    .main-middle-header-div {
        width: 600px !important;
    }
    .subscribed-service-main-div #content {
        min-height: 94vh;
    }

    .FB_IMD {

        margin-right: -62.5rem !important;
    }

    .instr_Img {
        height: 462px !important;
        width: 762px !important;
    }
    .position-for-btis {
        position: absolute;
        left: 11% !important;
    }
}

.login-app-width {
    min-width: 400px;
    width: 400px;
    margin: 0 auto;
}

.talage-application {
    border-bottom: none !important;
}

.nav-tabs.talage-application .nav-link {
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    color: rgba(0, 31, 77, 1);
    border-color: transparent transparent #dee2e6 transparent;
    height: 50px;
}

.nav-fill.talage-application .nav-item,
.nav-fill.talage-application>.nav-link {
    border: 2px solid rgba(246, 245, 245, 1);
}

.nav-tabs.talage-application .nav-link:focus {
    color: #fff;
    border-color: #6c757d;
    border-width: 0px 0px 2px 0px;
}

.nav-tabs.talage-application .nav-link.active:hover {
    color: #fff !important;
}

.nav-tabs.talage-application .nav-link:hover {
    border-color: #6c757d;
    border-width: 0px 0px 2px 0px;
    color: rgba(0, 31, 77, 1) !important;
}

.talage-label label {
    font-size: 12px;
    margin-bottom: 4px;
}

.talage-table.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 0px;
    border-right: 1px solid #c5b0db;
}

.get-price {
    font-size: 11px !important;
    padding: 7px 6px !important;
    border-radius: 8px !important;
}

.talage-table-p .p-paginator {
    background: #F8F7F7 !important;
    border: none;
    padding: 2px;
    border-radius: 8px !important;
}

.talage-table-p button.p-paginator-prev.p-paginator-element.p-link.p-disabled,
button.p-paginator-next.p-paginator-element.p-link.p-disabled {
    background: none !important;
}

.talage-table-p button.p-paginator-next.p-paginator-element.p-link,
button.p-paginator-prev.p-paginator-element.p-link {
    background: #EEE6F7 !important;
    border-radius: 4px !important;
    color: #47127D !important;
}

.talage-table-p .p-paginator span,
.talage-table-p .p-paginator button {
    font-size: 12px !important;
}

.datatable-leads .talage-table.p-datatable .p-datatable-tbody>tr>td {
    padding: 10px;
    font-size: 12px !important;
    vertical-align: middle;
    background: #F8F7F7 !important;
}

.datatable-leads .talage-table.p-datatable .p-datatable-tbody>tr>td:first-child {
    border-radius: 10px 0px 0px 10px;
}

.datatable-leads .talage-table.p-datatable .p-datatable-tbody>tr>td:last-child {
    border-radius: 0px 10px 10px 0px;
}

.talage-table .p-datatable-table {
    border-collapse: separate !important;
    border-spacing: 0px 10px;
}

.datatable-leads .talage-table.p-datatable .p-datatable-tbody>tr>td p {
    margin-bottom: 0px;
}

.text-danger {
    font-size: 12px;
    font-weight: 500;
}

.pricepopup {
    border-collapse: separate;
    border-spacing: 0px 10px;
}

.pricepopup thead th {
    border: none;
}

.pricepopup tbody td {
    border-top: none;
}

.pricepopup tbody tr {
    background-color: #F8F7F7 !important;
}

.pricepopup tbody tr td:first-child {
    border-radius: 10px 0px 0px 10px;
}

.pricepopup tbody tr td:last-child {
    border-radius: 0px 10px 10px 0px;

}

.btn-cmn.p-button {
    color: #fff !important;
    background-color: #6f2d80 !important;
    border-color: #6f2d80 !important;
    width: 157px !important;
    border-width: 1px;
}

.table-information {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.lable-min-width {
    min-width: 94px;
}

.text-color-size-events {
    font-size: 12px;
    color: #575757;
    font-weight: 600;
}

.text-color-size {
    font-size: 12px;
    color: #575757;
    font-weight: 400;
    margin-left: 7px;
}

.btn-hash {
    background-color: #f8f7f7 !important;
    border-color: #f8f7f7 !important;
    color: #001f4d !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    padding: 0.5rem 0.75rem !important;
}

.table-information .form-check {
    display: block;
    min-height: 1.5rem;
    margin-bottom: 0.125rem;
    width: 216px;
    background: #f8f7f7;
    padding: 8px 12px 8px 30px;
    margin-right: 10px;
    border-radius: 4px;
}

.update-lead .custom-control {
    width: 100px;
}

.w-77 {
    width: 77% !important;
}

.w-75 {
    width: 75% !important;
}

.leads-table .form-control {
    background: #f8f7f7;
    border: 1px solid #f8f7f7;
}

.lead-report-submit {
    padding: 7px 20px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    height: 32px;
}

.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #001f4d !important;
    background-color: #001f4d !important;
}

.leads-table.report-table.table td {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #575757;
}

.bg-greencolor {
    background-color: #2cb28a;
    width: 26px;
    height: 26px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.img-whitecolored {
    -webkit-filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(2%) hue-rotate(356deg) brightness(109%) contrast(101%);
    filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(2%) hue-rotate(356deg) brightness(109%) contrast(101%);
    height: 18px;
}

.website-link a {
    font-size: 18px;
    color: #6f2d80 !important;
}

.custom-checkbox .custom-control-label:before {
    background-color: #fff !important;
    border: #adb5bd solid 1px !important;
}

.text-black {
    color: #000;
}

.text-align-center {
    text-align: center;
}

.semsee-video-iframe iframe {
    height: 100%;
}

.semsee-video-iframe iframe:hover {
    border: none;
}

.companylogo {
    height: 45px;
}

.btn-outline-secondary.export-btn {
    padding: 6px 32px;
    font-size: 14px;
    width: auto;
    border-radius: 8px;
}

.MR_Export {
    margin-right: 810px;
}

.react-video iframe,
.react-video video {
    border-radius: 8px;
    height: 100%;
}

.react-video iframe:hover,
.react-video video:hover {
    border: none;
}

.toast-position {
    position: absolute !important;
    top: -87px !important;
    right: -17px !important;
}

.back-btn-transperent.subscribed-back-btn {
    top: 64px;
    left: 0px;
}

.talage-table.p-datatable .p-column-header-content {
    color: #47127d;
    font-weight: 700;
}

.talage-table.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #47127d !important;
}

.FB_IMD {

    margin-right: -41.5rem;
}

.gig-services .service-title .icon.subscribed-icons .icon-img {
    height: 40px;
    width: auto;
}

.gig-services .service-title .icon.subscribed-icons {
    width: 100%;
}

.subscribed-service-details .card.subscribe-card .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-primary.feedback-rtg {
    border: 1px solid rgba(0, 31, 77, 1);
    padding: 4px 7px;
    border-radius: 4px !important;
}

.btn-primary.feedback-rtg:hover {
    border: 1px solid rgba(0, 31, 77, 1);
}

.feedback-rtg-ico {
    filter: brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(0%) hue-rotate(105deg) brightness(104%) contrast(105%);
    height: 20px;
}

.help-rtg {
    padding: 4px 7px !important;
    border-radius: 4px !important;
}

.btn-outline-primary.help-rtg .help-rtg-ico {
    height: 20px;
}

.btn-outline-primary.help-rtg:hover .help-rtg-ico {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(0%) hue-rotate(176deg) brightness(115%) contrast(100%);
}

/* .datatable-leads .semseetable.talage-table.p-datatable .p-datatable-tbody>tr>td:first-child {
    padding-left: 55px;
} */

/* .datatable-leads .semseetable.talage-table.p-datatable .p-datatable-tbody>tr>td:last-child {
    padding-left: 72px;
} */

.subscribed-height {
    min-height: 100vh;
}

.auth-loader {
    align-items: center;
    justify-content: center;
}

.mrgntp {
    margin-top: 30px;
}


/* ADMIN PAGE CSS */

.new-header .image-1 {
    position: absolute;
    top: 0;
    right: 0px;
}

.new-header .image-2 {
    position: absolute;
    top: 10px;
    left: 0px;
}

.mid-container1 {
    width: 86%;
    margin: 0 auto;
}

.lead-campaign {
    position: relative;
}

.lead-campaign .img-1 img {
    position: absolute;
    top: 32px;
}

.lead-campaign .img-2 img {
    position: absolute;
    left: auto;
    right: 0px;
    top: 0px;
}

.UsersTag {

    border-radius: 5px;
    font-weight: bold !important;
}

.leads-card.semsee-info-card {
    margin: 0 auto;
    position: relative;
    z-index: 1;
    bottom: 48px;
}

.SemseeInfo {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 15px;
}

.bg-community-banner:after {
    content: "";
    top: 0;
    position: absolute;
    left: 0px;
    /* top: 63px; */
    width: 100%;
    height: 200px;
    opacity: .7;
}

.card-info .card {
    border: none !important
}

.leads-card.semsee-info-card .card {
    box-shadow: 0px 22px 80px rgb(0 0 0 / 3%), 0px 8.47407px 25.4815px rgb(0 0 0 / 2%), 0px 1.79259px 6.51852px rgb(0 0 0 / 1%);
    border-radius: 8px;
    border: none;
}

.semseePopUp {
    width: 20vw;
}

.semseePopUp.p-dialog .p-dialog-header {
    padding: 5px;
}

.semseePopUp .p-dialog-header-close-icon {
    display: none;
}

/* CANOPY CONNECT WIDGET */

.canopy-loading-link-div {
    margin-left: 40%;
}

.canopy-card-body {
    height: auto;
    max-height: 800px;
}

.canopy-box {
    background-color: #fafafa;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 6px;
    height: 100px;
    max-height: 100px;
}

.canopy-items-list {
    display: flex;
    justify-content: space-between;
    width: 90%;
    font-weight: 800;
    padding: 0;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
}

.canopy-bottom-border {
    border-bottom: 1px solid #bbb;
    max-height: 5px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.canopy-link-box {
    width: 95%;
    height: 70px;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 6px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    font-size: 16px;
    background-color: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    cursor: pointer;
}

.canopy-link-box:hover {
    background-color: #efefef;
    transition: all 0.15s ease-in-out;
}

.canopy-row-identifier {
    background-image: linear-gradient(to bottom right, #6f2d80, #9a44af);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 70px;
}

.canopy-data-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto 30px auto 30px;
    max-height: 70px;
}

.canopy-link-box-content {
    margin-left: 30px;
}

.canopy-link-box-content span {
    margin-right: 45px;
}

.canopy-url-info {
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
    align-items: center;
}

.canopy-action-buttons {
    float: right;
    display: flex;
    width: 300px;
    justify-content: space-between;
}

.canopy-action-buttons>div {
    cursor: pointer;
}

.canopy-action-buttons img {
    pointer-events: none;
}

.canopy-details-content {
    display: block;
}

.canopy-details-address-list {
    display: flex;
}

.canopy-details-address-list div {
    margin-right: 10px;
}


.canopy-details-content ul {
    list-style-type: none;
    margin: 0;
    padding: 5px;
}

.canopy-details-contact-list {
    display: flex;
}

.canopy-details-contact-list span {
    font-weight: 800;
}

.canopy-details-contact-list div {
    margin-right: 10px;
}

.canopy-policy-item {
    padding: 10px;
    margin-bottom: 10px;
}

.canopy-policy-item span {
    margin-right: 20px;
}

.canopy-policy-name {
    border-radius: 6px;
    padding: 5px;
    background-color: #eee6f6;
}

.canopy-border {
    border: 1px solid #bbb;
}

.canopy-padding {
    padding: 5px;
    font-size: 12px;
}

.canopy-details-content li a {
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
}

.canopy-details-content li:hover a:hover {
    color: white;
}

.canopy-document-item {
    margin-bottom: 5px;
    border-radius: 6px;
    margin-left: 10px;
    background-color: #eee6f6;
    width: fit-content;
}

.canopy-document-item:hover {
    background-color: #dbcaed;
    transition: all 0.1s ease;
}

.canopy-details-row {
    margin-bottom: 30px;
}

.canopy-activities-content {
    max-height: 500px;
    height: 500px;
    min-height: 500px;
    padding: 5px;
    overflow: auto;
}

.canopy-loading-activities-height {
    margin-top: 200px;
    height: 500px;
    min-height: 500px;
    max-height: 500px;
}

.canopy-details-policies-list tr {
    font-size: 12px;
}

.canopy-details-documents-list tr {
    font-size: 12px;
    margin: 0;
    padding: 0;
}

.canopy-details-sendbutton {
    display: flex;
    justify-content: flex-end;
}

.canopy-hawksoft-btn {
    width: 300px;
}

.canopy-client-info {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
}

.canopy-client-info>div {
    display: flex;
    font-weight: 600;
    font-size: 14px;
}

.canopy-client-info>div>div {
    width: 150px;
    font-size: 12px;
}

.canopy-client-info>div>span {
    font-weight: 400;
}

.canopyconnect-settings-progressbar {
    width: 250px;
}

.canopyconnect-settings-progressbar .p-progressbar .p-progressbar-label {
    color: #fff;
    line-height: 11px;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
}



/* =- END OF CANOPYCONNECT -= */

.new-registration-container {
    position: relative;
    bottom: 194px;
}

.new-registration-container .mid-sec {
    box-shadow: 0 0 20px rgb(51 51 51 / 10%);
}

.new-registration {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-reg-position {
    position: relative;
    bottom: 40px;
}

.new-reg-position .card {
    border: none;
    box-shadow: 0 0 20px rgb(51 51 51 / 10%);
    border-radius: 8px;
}

.BackNineCard {

    margin-right: 5px;
    margin-left: 5px;
}

.gig-services .service-title .icon.backnine-icon .icon-img {
    margin-left: 10px;
    height: 70px;
    width: 70px;
}


.carriersList .p-dialog-header .p-dialog-title {
    font-size: 20px !important;
    font-weight: 400 !important;
}

.carriersList .p-dialog-header {

    padding-bottom: 10px !important;
}

.img-cert img {

    filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(18%) hue-rotate(147deg) brightness(104%) contrast(100%);
    height: 16px;
}

.certtb .p-datatable-table .p-datatable-tbody td:nth-child(3),
.certtb .p-datatable-table .p-datatable-tbody td:nth-child(4),
.certtb .p-datatable-table .p-datatable-tbody td:nth-child(5) {
    /* text-align: center !important; */
}

.warning-width {
    width: 140px !important;
}

.lossruns-supported-carriers-container {
    height: 500px;
    max-height: 500px;
    margin: 20px 0 0 0;
    padding: 0;
}

.lossruns-supported-carriers-container>ul {
    display: flex;
    flex-flow: column wrap;
    height: inherit;
    margin: 0;
    width: inherit;
}

.lossruns-supported-carriers-container>ul>li {
    flex: 0 0 auto;
}

.lossrun-icon:hover i {
    color: #47127D !important;
}

.lossrun-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

.lossrun-topbar-searchbar {
    width: 80%;
    max-width: 600px;
    margin: 0 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lossrun-topbar-actionbuttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lossrun-searchbar {
    width: 80%;
    max-width: 300px;
}

@media (max-width:1500px) {
    .lossrun-topbar {
        /*height: 75px;
        flex-direction: column-reverse;
        justify-content: space-between;*/
    }
}

.autofillbtn.supp-outline-primary-btn:hover img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(323deg) brightness(102%) contrast(101%);
}

.badge-white-ico {
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7451%) hue-rotate(289deg) brightness(120%) contrast(86%);
    height: 16px;
}

.datatable-leads.link-generator-table .semseetable.talage-table.p-datatable .p-datatable-tbody>tr>td:first-child {
    padding-left: 15px !important;
}

.cstm-btn.link-generator-btn {
    padding: 2px 16px !important;
    font-size: 12px !important;

}

html,
body {
    font-family: 'ReadexPro-Regular', sans-serif;
    color: #2A2A2A;
    line-height: 20px;
    /* height: 100%; */
}

p {
    line-height: 20px;
    font-weight: 400;
}

.text-16 {
    font-size: 16px !important;
}

.text-12 {
    font-size: 12px !important;
}

.text-24 {
    font-size: 24px !important;
}

.font-weight-600 {
    font-weight: 600;
}

.mid-container-semsee {
    max-width: 85%;
    margin: 0 auto;
}

.text-primary {
    color: #47127D !important;
}

.text-black {
    color: #2A2A2A !important;
}

.text-end {
    text-align: end !important;
}

.btn-back-transperent {
    height: 64px;
    width: 64px;
    position: absolute;
    border-bottom-right-radius: 100%;
    border-top-left-radius: 0px;
    left: 0px;
    top: 64px;
    z-index: 2;
    background-color: #EEE6F6;
    opacity: .8;
}

.btn-back-transperent.btn:focus {
    box-shadow: none;
}

.btn-primary-trans {

    padding: 3px;
}

.img-secondary {
    filter: brightness(0) saturate(100%) invert(7%) sepia(68%) saturate(5262%) hue-rotate(268deg) brightness(112%) contrast(100%);
    height: 17px;
    padding-bottom: 2px;
}

.text-24 {
    font-size: 24px !important;
}

.primary-btn-transperent {
    background-color: #EEE6F6;
    font-size: 16px;
    color: #47127D;
    border-radius: 8px;
    font-weight: 400;
}

.primary-btn-transperent:hover {
    border: 1px solid #47127D;
    color: #47127D;
    background-color: transparent;
}

.primary-btn-transperent:focus {
    box-shadow: none;
}

.btn-primary-outline {
    border: 1px solid #47127D;
    background-color: transparent;
    color: #47127D;
    font-size: 16px;
    padding: 0px 32px;
    height: 33px;
}

.svg-primary {
    height: 18px;
    /* filter: brightness(0) saturate(100%) invert(17%) sepia(47%) saturate(3247%) hue-rotate(255deg) brightness(83%) contrast(114%); */
}

.svg-white {
    height: 18px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(25%) hue-rotate(70deg) brightness(108%) contrast(108%);
}

.btn-success-new {
    color: #fff !important;
    background-color: #2FAD87 !important;
    border: 1px solid #2FAD87 !important;
    font-size: 16px !important;
    padding: 6px 60px !important;
}

.btn-success-new:hover {
    color: #2FAD87 !important;
    border: 1px solid #2FAD87 !important;
    background-color: transparent !important;
}

/***********Semsee************/
.semsee-top-margin {
    margin-top: 100px;
}

.card-semsee {
    margin-top: 20px;
}

.main-header {
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 5%);
    position: fixed;
    z-index: 2;
}

.user-image {
    height: 32px;
    width: 32px;
    border-radius: 8px;
}

.card-semsee {
    border: none;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
    border-radius: 8px;
}

.v3-logo {
    height: 48px;
    width: 48px;
    /* background-color: #EEE6F6; */
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.v3-logo img {
    height: 29px;
}

.semsee-title {
    padding-left: 15px;
}

.semsee-new-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #47127D;
    width: 177px;
    border-radius: 8px;
    text-align: center;
    border-bottom: none;
}

.semsee-new-tabs .nav-pills .nav-link {
    border-radius: 0px;
    color: #000;
    width: 177px;
    text-align: center;
    border-bottom: 3px solid #c5b0db;
    margin-right: 3px;
    font-size: 16px;
}

.semsee-new-tabs .nav-pills .nav-link:hover {
    background-color: #C5B0DB;
    color: #47127D;
    border-radius: 8px;
}

.semsee-v3-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.wizard-steps {
    padding: 0px;
}

.wizard-steps .steps {
    display: flex;
    column-count: 3;
    justify-content: center;
    align-items: center;
    background: rgba(238, 230, 247, 0.4);
    padding: 8px 12px;
    border-radius: 8px;
}

.badge-trans-primary.badge-rounded {
    border-radius: 40px;
    font-size: 12px;
    padding: 3px 8px;
    margin-bottom: 4px;
}


.wizard-steps .steps .step {
    /* width: 100%; */
    /* margin: 10px 0 10px; */
}

.wizard-steps .steps .step:last-child:after {
    content: '';
    display: none;
}

.wizard-steps .steps .step .semsee-wizard-count {
    padding: 4px 9px;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #47127D;
    color: #47127D;
}

.wizard-steps .steps .step.active .semsee-wizard-count {
    background-color: #47127D;
    color: white;
    border: 1px solid #47127D;
}

.wizard-steps .tabs {
    width: 100%;
    height: 100%;
}

.wizard-steps .tabs .tab {
    display: none;
}

.wizard-steps .tabs .tab.active {
    display: block !important;
}

.semsee-wizard-btns #nextBtn {
    border-radius: 4px !important;
    padding: 7px 0px;
    width: 162px;
    border: none;
    border-radius: 0.085rem;
    font-weight: 500;
    font-size: 16px;
    color: white;
    background: #2FAD87;
}

.semsee-wizard-btns #prevBtn {
    border-radius: 4px !important;
    padding: 7px 0px;
    width: 162px;
    border: none;
    border-radius: 0.085rem;
    font-weight: 500;
    font-size: 16px;
    /* color: white;
    background: #47127D; */
}


.step-title {
    font-size: 15px;
    color: #47127D;
}

form label {
    color: #2A2A2A;
    margin-bottom: 4px;
    font-size: 12px;
}

.form-control::placeholder {
    font-size: 12px;
    color: #BCBCBC;
}

@media only screen and (max-width: 420px) {
    .semsee-wizard-btns button {
        width: 100%;
    }
}

.pre-footer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.footer {
    height: 64px;
    /* background: linear-gradient(180deg, #443B5C 0%, #47127D 100%); */
    font-size: 16px;
    color: #fff;
}

.search-bar-new {
    border: 1px solid #BCBCBC;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    z-index: 1;
}

.search-bar-new .fa-search {
    position: absolute;
    top: 9px;
    left: 27px;
}

.search-bar-new input {
    height: 32px;
    text-indent: 25px;
    border: none;
    border-radius: 8px !important;
    padding: 10px 10px 10px 15px;
}

.ref-button {
    padding: 5px !important;
}

.ref-button img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(37%) saturate(5591%) hue-rotate(261deg) brightness(90%) contrast(108%);
    height: 18px;
}

.table-style {
    border-collapse: separate;
    border-spacing: 0px 7px;
}

.table-style thead th {
    border-bottom: none;
    border-top: none;
    padding: 13px 0.75rem;
    border-right: 1px solid #C5B0DB;
}

.table-style thead th:last-child {
    border-right: none;
}

.table-style thead tr {
    background: #EEE6F6;
    border: none;
    color: #47127D;
    font-size: 12px;
    font-weight: 600;
}

.table-style thead th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.table-style thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.table-style tbody tr td {
    background-color: #F6F6F6;
    border-top: none;
    padding: 13px 0.75rem;
    border-right: 1px solid #C5B0DB;
    font-size: 12px;
    line-height: 21px;
}

.table-style tbody tr td:last-child {
    border-right: none;
}

.form-check-input.form-check-new {
    position: inherit;
    margin-top: 0px;
    margin-left: 0px;
}

.form-check-input.form-check-new[type=checkbox] {
    border-radius: 0.25em;
}

.form-check-input.form-check-new {
    width: 16px;
    height: 16px;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #47127D;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked {
    background-color: #2FAD87;
    border-color: #2FAD87;
}

.table-style tbody tr td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.table-style tbody tr td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

/***********Relativity6*************/
.relativity-top-margin {
    margin-top: 100px;
    min-height: 57vh;
}

.naics-part {
    background-color: #F8F7F7;
    padding: 16px;
    border: 1px solid #47127D;
    border-radius: 4px;
}

.copy-svg {
    height: 14px;
}

.source-urls {
    background-color: #EEE6F6;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.source-urls a {
    color: #2A2A2A;
    text-decoration: none;
}

.scr_url {
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
}

.naics_code_copy {
    margin-left: 10px;
}

.algnBtn {
    text-align-last: end;
}

.dot-spin {
    margin: 0 auto;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
        18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
        0 18px 0 0 rgba(152, 128, 255, 0),
        -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
        -18px 0 0 0 rgba(152, 128, 255, 0),
        -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
    animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {

    0%,
    100% {
        box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
            18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
            12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
            12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
            0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
            -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
            -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
            12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
            -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
    }

    75% {
        box-shadow: 0 -18px 0 0 #9880ff,
            12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
            -12.72984px -12.72984px 0 0 #9880ff;
    }

    87.5% {
        box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
            18px 0 0 -5px rgba(152, 128, 255, 0),
            12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
    }
}


.loader_R6 {
    margin: 100px auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes load5 {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
}

@keyframes load5 {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
}

.position_fixes {

    position: relative;
}

.position_fixes:after {
    top: 0px !important;

}

.p-dialog {
    box-shadow: none !important;
}

.url_Heading {
    margin-bottom: 10px;
    margin-top: -8px;
}

.company-icon {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn.btn-yellow-outline {
    border: 1px solid #FFE501;
    color: #FFE501;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
}

.btn.btn-yellow-outline:hover {
    background-color: #FFE500;
    border: 1px solid #FFE500;
    color: #000;
}

.text-yellow {
    color: #FFE500;
    font-size: 14px;
}

.gig-banner {
    background-color: rgba(71, 18, 125, 1);
    height: 223px;
    background-image: url(../images/new-images/banner-bg.png);
    width: 100%;
    background-position: top right;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.03), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0117778);
}

.gig-banner:after {
    content: "";
    top: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 223px;
    background: linear-gradient(180deg, #443B5C 0%, #47127D 100%);
    background-color: #333;
    opacity: .7;
    border-radius: 8px;

}

.text-primary-500 {
    color: rgba(255, 255, 255, 0.5);
}

.main-banner-height {
    height: 223px;
    display: flex;
    align-items: center;
}


.Loader_color svg {
    fill: #862CB1;
}

/* .login-Loader{
    color: #862CB1 ;
} */

.main-banner-height .padding-left-30 {
    padding-left: 30px;
}

.btn-primary.btn-start-padd {
    padding: 1px 10px !important;
}

.backnine-table tbody tr td:last-child {
    text-align: center;
}

.backNine-Table.leads-table .p-datatable .p-datatable-tbody>tr {
    cursor: inherit;
}

.p-column-filter-overlay.p-fluid.p-column-filter-overlay-menu.p-ripple-disabled.p-connected-overlay-enter-done .p-button-sm {
    background-color: #47127d !important;
    color: #fff;
}

.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    color: #fff !important;
}

.btn-semsee-search.ref-button {
    margin-left: 15px;
}

.p-button.p-button-outlined {
    color: #fff !important;
}

.search-bar-new .p-inputtext {
    padding: 10px 10px 10px 15px !important;
}

.backnine-table .p-paginator .p-dropdown {
    align-items: center;
    padding-left: 10px;
}

.service-usage {
    color: red;
    float: right;
}

.backNine_checkBox {
    padding-left: 25px !important;
}

.consent-form {
    overflow-y: scroll;
    /* min-height: 100px !important; */
    height: 20px;
}

.subscibed-service-details-main .main-header-new .p-avatar {
    background-color: #6f2d80;
    color: #fff;
}

.main-header-new .user-image .avatar-new-header.p-avatar {
    background-color: #6f2d80;
    color: #fff;
}

.avatar-new-header.p-avatar {
    background-color: #6f2d80 !important;
    color: #fff;
}

.company-profile-tab-bg {
    padding: 0 0px !important;
}

.btn-cmn6 {
    background-color: #172B4D !important;
    color: #fff !important;
    border-color: #172B4D !important;
    width: 157px !important;
}

.error-usage-report {
    margin-bottom: 6px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.partner-background-theme .login-box .Loader_color svg {
    fill: #172b4d;
}

.hidetextbox {
    color: white !important;
    border: none white !important;
    width: 1px;
}

.hidetextbox:focus {
    border: none white !important;
    border-style: none;
    border-width: 0ch;
    box-shadow: none !important;

}

.supportedCarriers-text {
    color: purple !important;
    border-bottom: 1px dashed purple;
}

.loss-dwnl-btn {
    cursor: pointer;
    border: 1px solid rgb(71, 18, 125);
    color: rgb(71, 18, 125);
    padding: 7px;
    border-radius: 5px;
}

.meet-again .footer {
    /* height: 64px; */
    background: linear-gradient(180deg, #443B5C 0%, #47127D 100%);
    font-size: 16px;
    color: #fff;
}

/* about */

.about-primary-btn-purple:hover {
    border: 1px solid #47127D !important;
    color: #47127D !important;
    background-color: #fff !important;
}

.about-primary-btn-purple {
    background-color: #47127D !important;
    border-radius: 4px !important;
    color: #fff !important;
    padding: 5px 66px !important;
    border: 1px solid #47127D !important;
}

.meet-again p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2A2A2A !important;
    font-family: 'Readex Pro';
}
.faq-col p {
    color: #575757 !important;
}

.meet-again h2 {
    font-weight: 700;
    font-size: 32px;
}

.meet-again span {
    line-height: 24px;
}

.talage-table .p-datatable .actionheader {
    padding-left: 15px;
}


.mrgnLeft {
    margin-left: 5rem !important;
}

.instr_Img {
    height: 370px ;
    width: 599px ;
}

@media(max-width:1920px) and (min-width:1080px) {
    .mrgnLeft {
        margin-left: 12rem !important;
    }

   

}

@media(max-width:1536px) and (min-width:864px) {

    .mrgnLeft {
        margin-left: 5rem !important;
    }
}

.p-progressbar .p-progressbar-label {
    color: #c5b0db !important;
    line-height: 1.5rem;
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #46127c;
}

.mob-bottom-create {
    margin-left: 0px !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: rgba(188, 188, 188, 1) !important;
    font-family: inherit;

}

.company-panel-div .p-panel .p-panel-header {
    background-color: #f8f9fa;
}

.btn-secondary-updateprefernce {
    background-color: #fff !important;
    color: rgba(71, 18, 125, 1) !important;
    border: 1px solid rgba(71, 18, 125, 1);
    border-radius: 5px;
    font-size: 13px;
}

/* .councilterms-condition-text{
text-align: center;
margin-bottom: 60px;

} */
.terms-conditon-innovation {
    padding: 0 170px;

}

.innovation-council-main .policy-p {
    height: 530px;
}

.premiumpolicy-height {
    height: 530px !important;
}

.innovation-council-main .middleheader-para {
    color: #fff;
    margin-top: 11px;
    font-size: 18px;
}

.premiumpolicy-width {
    padding: 0 210px;
}

.PremiumFinancePrivacyPolicy-main .middleheader-para {
    color: #fff;
    margin-top: 5px;
    font-size: 16px;
}

.creditcard-main .p-accordion .p-accordion-toggle-icon {
    position: absolute !important;
    right: 22px !important;
    justify-content: flex-end !important;
    display: flex !important;
}

.creditcard-main .p-accordion .p-accordion-tab {
    margin-bottom: 30px;
}

.creditcard-main .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border: 1px solid #dee2e6;
}

.creditcard-main .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link:hover {
    border: 1px solid #dee2e6 !important;
}

.creditcard-main .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {

    box-shadow: none;
}

.company-panel-div .p-panel .p-panel-header:hover {
    background: #f8f9fa !important;
}

.creditcard-main .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
}

.creditcard-main .pi-chevron-right:before {
    content: "\e901";
    transform: rotate(90deg);
}

.creditcard-main .pi-chevron-down:before {
    content: "\e902";
    transform: rotate(180deg);
}
/* .img-fluid.app-icons.premficon{
    margin-right: 40px;
} */
.img-fluid.app-icons.premficon.financepartnerts{
    margin-right: 0px;
}

/* History table css */

.historytable td{
    padding: 0 0 0 5px !important;
}

.HistoryTableCard{
    border: 50px;
}

.pfhistorydialog .p-dialog-title{
    font-size: 14px !important;

}

.pfhistorydialog .p-dialog-header {
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important; 
   
}

.pfhistorydialog .p-dialog-content{
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important; 
}

.targetpartnername{
    padding-top: 6px !important;
}

.response-btn-div{
    padding-top: 20px;
}

.PfSaveBtn .p-button-label{
    padding-right: 28px !important;

}

.pfsavebutton{
    padding-right: 0px;
}

.pfactionbtn{
    padding-right: 20px;
}


/* PFNewQuote CSS */

.input-group-text{
    padding-bottom: 0  ;
    padding-top: 0  ;
}

.pfclientname{
    padding-left: 20px;
}

.pfdetailsinput{
    padding-bottom: 20px;
}

/* .carrierheader{
    margin-bottom : -21px;
} */

.businessheader{
    padding-bottom: 14px;
}

.service-card-height-newquote-web{
    min-height: 159px; 
}

.btn-save-response-div{
    display: flex;
    padding: 2px;
    align-items: center;
    justify-content: center;
}
.span-text{
    font-size: 14px !important;
    text-align: center;
    padding-top: 15px;
}
.history-table{
    height: 430px;
}
.btn-responsive{
    padding-top: 25px;
}
.inputbox{
    border: 0.5px solid lightgray;
    border-radius: 5px;
}

.pfHistoryCalender .p-inputtext{
    border: 0px !important;
    background-color: transparent !important;
}
.pfHistoryCalender .p-inputtext:enabled:focus{
    outline: 0  !important;
    outline-offset: 0 !important;
    box-shadow:none !important;
    border-color: transparent !important;
}

.btn.PfhistorySearch{
    border: 1px solid #47127D;
    background-color: transparent;
    color: #47127D;
    font-size: 16px;
    padding: 0px 19px;
    height: 33px;
}

#selectedClient .p-inputtext {
    width: 100%;
}
#selectedCoverageCode .p-inputtext {
    width: 100%;
}
.loadingtext{
    width: 50%;
    padding: 20px;
}
.response-table {
    font-size: 12px;
    width: 100%;
    line-height: 12px;
}
.ml-auto, .mx-auto {
    margin-left: auto!important;
}

.mr-auto, .mx-auto {
    margin-right: auto!important;
}
.mt-2, .my-2 {
    margin-top: 0.5rem!important;
}
.response-table td {
    border: 1px solid #ced4da;
    padding: 5px !important;
}
.all-label-policy-details{
    font-size: 12px;
    color: rgba(87, 87, 87, 1);
    font-weight: 600;
    margin-bottom: 4px;
}

/* PF Settings */

.igCredentialMessage{
    padding-left: 57px;
}

.client-search{
    position: absolute;
    width: 96%;
    height: fit-content;
    max-height: 200px;
    margin-top: -2px;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    z-index: 999;
}

.autocomplete-pfpolicydetails{
    max-height: 200px;
    z-index: 1001;
    transform-origin: center top;
    top: 619.4px;
    left: 191px;
    width: 334px;
}

.policy-tab.active-tab{
    background-color: #505f79 !important;
    color: #fff !important;
}
.policy-tab{
    background-color: #f4f5f7; 
    color: #000;
    padding: 5px;
    border-radius: 5px;
    margin-right: 7px;
    margin-bottom: 5px;
    cursor: pointer;
}

.duplicate-btn{
    background-color: rgba(71, 18, 125, 1) !important;
    border-color: rgba(71, 18, 125, 1) !important;
    color: #fff !important;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 10px;
}
.update-client{
    color: #47127D !important;
    text-decoration: underline;
}
